import { faAdd, faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useNavigate } from "react-router-dom";
const Heading = ({ title, children, filter,text,link }) => {
  const router = useNavigate();

     
       
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "10px 20px",
      }}
    >
      <div style={{ fontSize: "22px", fontWeight: "500" }}>{title}</div>
      <div className="d-flex gap-2">
        {filter && children}
        <div
          onClick={() => {
            router(link);
          }}
          style={{
            backgroundColor: "#63636363",
            border: "1px solid #636363",
            borderRadius: "20px",
            padding: "5px 20px",
            display: "flex",
            gap: "10px",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
      {text}
          <FontAwesomeIcon icon={faAdd} />
        </div>
      </div>
    </div>
  );
};

export default Heading;
