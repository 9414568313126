import React from 'react'
import style from '../SattvikStaysAddForm.module.css'
import { useState, useEffect } from 'react'
import {
  sattvik_get_all_city_api,
  sattvik_get_city_by_state
} from '../../../../../store/sattvik-stays'
import { faAdd } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom'

const CityForm = props => {
  const router = useNavigate()
  const { heading, handleChange, handleSubmit, formData, formik, state } = props
  const [cities, setCities] = useState([])
  const [selectedState, setSelectedState] = useState('')
  const Category = [
    {
      id: 'hotel',
      name: 'hotel',
      label: 'Hotel'
    },
    {
      id: 'guest house',
      name: 'guest house',
      label: 'Guest House'
    },
    {
      id: 'dharamshala',
      name: 'dharamshala',
      label: 'Dharamshala'
    },
    {
      id: 'home stay',
      name: 'home  stay',
      label: 'Home  Stay'
    }
  ]

  const handleChangeState = async e => {
    const { value } = e.target

    let selectedState = state.filter(e => e.stateId == value)
    setSelectedState(selectedState[0].name)
    let params = {
      stateId: value
    }
    const response = await sattvik_get_city_by_state(params)
    if (response.data.statusCode == 200) setCities(response.data.data)
  }

  return (
    <form className={style.formBox} onSubmit={handleSubmit}>
      <h2>{heading}</h2>
      <div className={style.inputBoxParent}>
        <div className={style.inputBox}>
          <div>
            <p>Hotel Name</p>
            <input
              value={formik.values.hotelName}
              name='hotelName'
              placeholder='Enter your hotel name'
              required
              type='text'
              onChange={handleChange}
            ></input>
          </div>
          <div>
            <p>Slug</p>
            <input
              value={formik.values.slug}
              name='slug'
              placeholder='Enter your hotel unique slug'
              required
              type='text'
              onChange={handleChange}
            ></input>
          </div>
        </div>
        <div className={style.inputBox}>
          <div>
            <p>Address</p>
            <input
              value={formik.values.address}
              name='address'
              placeholder='Enter your hotel address'
              required
              type='text'
              onChange={handleChange}
            ></input>
          </div>
          <div>
            <p>Rating</p>
            <input
              value={formik.values.userRating}
              name='userRating'
              placeholder='Enter hotel rating'
              required
              min={1}
              max={5}
              type='number'
              onChange={handleChange}
            ></input>
          </div>
        </div>
        <div className={`${style.inputBox} pt-3`}>
          <div>
            <p>Price</p>
            <input
              value={formik.values.price}
              name='price'
              placeholder='Enter hotel price'
              required
              min={1}
              type='number'
              onChange={handleChange}
            ></input>
          </div>
          <div>
            <p>Discount Price</p>
            <input
              value={formik.values.discountPrice}
              name='discountPrice'
              placeholder='Enter hotel disscounted price'
              required
              type='number'
              min={1}
              max={formik.values.price}
              onChange={handleChange}
            ></input>
          </div>
        </div>
        <div className={`${style.inputBox} pt-3`}>
          <div>
            <p>State Name</p>
            <select
              name='stateId'
              // value={formik.values.stateName}
              onChange={handleChangeState}
              style={{
                width: '100%',
                paddingTop: '6px',
                paddingBottom: '6px',
                marginTop: '2px',
                border: '1px solid gray',
                borderRadius: '3px',
                color: 'gray'
              }}
            >
              <option value={''}>Select State</option>
              {state.length > 0 &&
                state.map(e => <option value={e.stateId}>{e.name}</option>)}
            </select>
          </div>
          {selectedState != '' && (
            <div>
              <p className='d-flex justify-content-between align-items-center'>
                City{' '}
                <span
                  style={{
                    cursor: 'pointer',
                    border: '1px solid gray',
                    fontSize: '12px',
                    borderRadius: '3px',
                    padding: '0 5px',
                    color: 'gray'
                  }}
                  onClick={() => router('/sattvik-stays/add-city')}
                >
                  add{' '}
                  <FontAwesomeIcon style={{ fontSize: '12px' }} icon={faAdd} />
                </span>
              </p>
              <select
                name='cityId'
                required
                value={formik.values.cityId}
                onChange={handleChange}
                className={style.inputBoxSelect}
              >
                <option value=''>Select City</option>
                {cities.length == 0 && <option value=''>No city found</option>}
                {cities &&
                  cities.map(e => (
                    <option key={e.id} value={e.cityId}>
                      {e.city}
                    </option>
                  ))}
              </select>
            </div>
          )}

          <div>
            <p>Category</p>
            <select
              name='category'
              required
              value={formik.values.category}
              onChange={handleChange}
              className={style.inputBoxSelect}
            >
              <option value=''>Select Category</option>
              {Category &&
                Category.map(e => (
                  <option key={e.id} value={e.name}>
                    {e.label}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <div className={`${style.inputBox} pt-3`}>
          <div className='w-100'>
            <p>Image</p>
            <input
              type={'file'}
              name='image'
              required
              value={formik.values.image}
              onChange={e => {
                formik.setFieldValue('city-image', e.currentTarget.files[0])
              }}
            />
          </div>
        </div>
        <div className={style.submitBtn}>
          <button type='submit'>Create</button>
        </div>
      </div>
    </form>
  )
}

export default CityForm
