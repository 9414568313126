import React from "react";
import AartiCategory from "./AartiCategory";
const index = () => {
  const token = localStorage.getItem("token");

  if (token) {
    return (
      <>
        <AartiCategory />
      </>
    );
  } else {
    window.location.href = "/";
  }
};

export default index;
