import React, { useState } from 'react'
import CityForm from './CityForm'
import HotelForm from './HotelForm'
import EditCity from "./EditCityForm"
import EditHotel from "./EditHotelForm"
const Forms = props => {
  const { active } = props
  return (
    <div>
      {active == 1 && <CityForm {...props} />}
      {active == 2 && <HotelForm {...props} />}
      {active == 3 && <EditCity {...props} />}
      {active == 4 && <EditHotel {...props} />}
    </div>
  )
}

export default Forms

