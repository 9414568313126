import React from "react";
import { useLocation } from "react-router-dom";
import SingleDetails from "./SingleDetails";

const Index = (props) => {
  let location = useLocation();
  const data = location.state;
  const token = localStorage.getItem("token")
  if (token) {
    return (
      <>
        <SingleDetails data={data} />
      </>
    );
  } else {
    window.location.href = "/";
  }
};

export default Index;
