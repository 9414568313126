import React from "react";
import HomePage from "../HomePage";
const Admin = () => {
  // token
  const token = localStorage.getItem("token");
  if (token && token != "undefined") {
    return <HomePage />;
  } else {
    window.location.href = "/";
  }
};

export default Admin;
