import React from "react";
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Alert, Button } from "reactstrap";
const SeoForm = ({ createdSeoData, setCreatedSeoData, language }) => {
  const URL = process.env.REACT_APP_BASE_URL;
  const history = useNavigate();
  const [isSuccess, setIsSuccess] = useState(false);
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState("");
  const handleUpdate = (e) => {
    e.preventDefault();
    setSubmitInProgress(true);
    if (language == "en") {
      axios
        .put(
          `${URL}/admin/update-en-blog-post-seo`,
          {
            id: createdSeoData.id,
            postId: createdSeoData.post_id,
            title: createdSeoData.title,
            description: createdSeoData.description,
            keyword: createdSeoData.keyword,
            image: createdSeoData.image,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              Accept: "application/json",
              "Content-Type": "application/json",
              "ngrok-skip-browser-warning": "any",
            },
          }
        )
        .then((res) => {
          if (res) {
            setIsSuccess(true);
            setMessage("Seo Content Updated Successfully Please Wait");
            setTimeout(() => {
              setIsSuccess(false);
              setMessage("");
              setIsSuccess(true);
              setSubmitInProgress(false);
              history("/update-blog-content", {
                state: {
                  content: `<p></p>`,
                  type: 1,
                  language: "en",
                  id: createdSeoData.post_id,
                  categoryId: createdSeoData.id,
                },
              });
            }, 3000);
          }
        })
        .catch((err) => {
          setSubmitInProgress(false);
          console.log("Error::::::::", err.response.data);
          setIsError(true);
          setError(err.response.data.msg);
          setTimeout(() => {
            setIsError(false);
            setError("");
          }, 3000);
        });
    }

    if (language == "hi") {
      axios
        .put(
          `${URL}/admin/update-hi-blog-post-seo`,
          {
            id: createdSeoData.id,
            postId: createdSeoData.post_id,
            title: createdSeoData.title,
            description: createdSeoData.description,
            keyword: createdSeoData.keyword,
            image: createdSeoData.image,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              Accept: "application/json",
              "Content-Type": "application/json",
              "ngrok-skip-browser-warning": "any",
            },
          }
        )
        .then((res) => {
          if (res) {
            setIsSuccess(true);
            setMessage("Seo Content Updated Successfully Please Wait");
            setTimeout(() => {
              setIsSuccess(false);
              setMessage("");
              setIsSuccess(true);
              setSubmitInProgress(false);
              history("/update-blog-content", {
                state: {
                  content: `<p></p>`,
                  type: 2,
                  language: "hi",
                  id: createdSeoData.post_id,
                  categoryId: createdSeoData.id,
                },
              });
            }, 3000);
          }
        })
        .catch((err) => {
          setSubmitInProgress(false);
          console.log("Error::::::::", err.response.data);
          setIsError(true);
          setError(err.response.data.msg);
          setTimeout(() => {
            setIsError(false);
            setError("");
          }, 3000);
        });
    }
  };
  return (
    <div>
      <section className="postCountIV ">
        {" "}
        <div
          style={{
            color: "black",
            marginBottom: "30px",
            textAlign: "center",
            width: "100%",
            borderRadius: "10px",
            justifyContent: "center",
            marginLeft: "10px",
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <h3>Update Blog Seo</h3>
        </div>
        <div className="row">
          <div className="col-lg-6">
            {isSuccess && <Alert color="info">{message}</Alert>}
          </div>

          <div className="row">
            <div className="col-lg-6">
              {isError && (
                <Alert
                  style={{
                    background: "rgb(251, 99, 71)",
                    color: "white",
                  }}
                >
                  Error::
                  {error}
                </Alert>
              )}
            </div>
          </div>
        </div>
        <form onSubmit={handleUpdate}>
          <div className="form">
            <div className="each-input">
              <label>Title</label>
              <input
                type="text"
                value={createdSeoData.title}
                onChange={(e) => {
                  setCreatedSeoData((prev) => ({
                    ...prev,
                    ["title"]: e.target.value,
                  }));
                }}
                placeholder="Add Blog Seo Title"
              ></input>
            </div>

            <div className="each-input">
              <label>Keywords</label>
              <input
                value={createdSeoData.keyword}
                onChange={(e) => {
                  setCreatedSeoData((prev) => ({
                    ...prev,
                    ["keyword"]: e.target.value,
                  }));
                }}
                type="text"
                placeholder="Add Blog Seo Keywords"
              ></input>
            </div>

            <div className="each-input">
              <label>Description</label>
              <input
                name="descriptionEn"
                placeholder="Add Blog Seo Description"
                type="text"
                value={createdSeoData.description}
                onChange={(e) => {
                  setCreatedSeoData((prev) => ({
                    ...prev,
                    ["description"]: e.target.value,
                  }));
                }}
              ></input>
            </div>

            <div className="each-input">
              <label>Image</label>
              <input
                value={createdSeoData.image}
                onChange={(e) => {
                  setCreatedSeoData((prev) => ({
                    ...prev,
                    ["image"]: e.target.value,
                  }));
                }}
                placeholder="add your image url here"
                type="text"
              ></input>
            </div>
          </div>
          <div className="add-f-parent2">
            <Button
              onClick={() => {
                history("/update-blog-content", {
                  state: {
                    content: `<p></p>`,
                    type: 1,
                    language: "en",
                    id: createdSeoData.post_id,
                    categoryId: createdSeoData.id,
                  },
                });
              }}
              disabled={submitInProgress}
              style={{
                marginRight: "12px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "200px",
                float: "right",
                marginTop: "15px",
                background: "linear-gradient(180deg, #DD2A12 0%, #FFA214 100%)",
              }}
            >
              <strong>Skip</strong>
            </Button>

            <Button
              disabled={submitInProgress}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "200px",
                float: "right",
                marginTop: "15px",
                background: "linear-gradient(180deg, #DD2A12 0%, #FFA214 100%)",
              }}
              type="submit"
            >
              {!submitInProgress ? (
                <strong>Update SEO</strong>
              ) : (
                <span className="special-loader"></span>
              )}
            </Button>
          </div>
        </form>
      </section>
    </div>
  );
};

export default SeoForm;
