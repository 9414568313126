import React, { useState } from "react";
import { useFormik } from "formik";
import { useLocation } from "react-router-dom";
import { Label, Input, Button, Form, Row, Col, Alert } from "reactstrap";
import * as Yup from "yup";

function Index() {
  // useLocation
  const history = useLocation();

  // token
  const token = localStorage.getItem("token");

  // states
  const [visible, setVisible] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState("");
  const [data, setData] = useState(history.state);

  // functions
  const onDismiss = () => setVisible(false);
  const Data = [
    { name: "Hindu Festivals", id: 143 },
    { name: "Temples", id: 144 },
    { name: "Vrat Katha", id: 145 },
    { name: "Prayers & Mantras", id: 146 },
    { name: "Popular Video", id: 147 },
    { name: "More", id: 148 },
    { name: "Mythology", id: 680 },
    { name: "Health & Beauty", id: 681 },
    { name: "Meditation", id: 682 },
    { name: "Superstitions", id: 683 },
    { name: "Shiva Songs", id: 684 },
    { name: "Astrology", id: 685 },
    { name: "Marriage", id: 686 },
    { name: "Navratri", id: 687 },
  ];
  const formik = useFormik({
    initialValues: {
      categoryId: "",
      titleEn: "",
      titleHi: "",
      descriptionEn: "",
      descriptionHi: "",
      videoId: "",
      status: false,
    },
    onSubmit: (values) => {},
    validationSchema: () =>
      Yup.object().shape({
        categoryId: Yup.string().required("Category id is required"),
      }),
  });

  if (token && token != "undefined") {
    return (
      <>
        <section className="postCount" style={{ overflow: "scroll" }}>
          <div
            style={{
              color: "black",
              marginBottom: "30px",
              textAlign: "center",
              width: "100%",
              borderRadius: "10px",
              justifyContent: "center",
              marginLeft: "10px",
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <h3>Add {data.componentName} Video</h3>
          </div>

          <Form
            onSubmit={formik.handleSubmit}
            style={{ padding: "10px 20px 0px 20px" }}
          >
            <div className="row">
              <div className="col-lg-6">
                {isSuccess && (
                  <Alert color="info" isOpen={visible} toggle={onDismiss}>
                    {message}
                  </Alert>
                )}
              </div>
              <div className="row">
                <div className="col-lg-6">
                  {isError && (
                    <Alert
                      style={{
                        background: "rgb(251, 99, 71)",
                        color: "white",
                      }}
                      isOpen={visible}
                      toggle={onDismiss}
                    >
                      Error::
                      {message}
                    </Alert>
                  )}
                </div>
              </div>
            </div>
            <div></div>
            <div className="col-lg-12">
              <div className="row">
                <Row form>
                  <Col>
                    <Label htmlFor="categoryId" className="lableCategory">
                      Category name:
                    </Label>
                    <select
                      name="categoryId"
                      onChange={formik.handleChange}
                      placeholder={`Enter Category Name`}
                      style={{ width: "100%" }}
                    >
                      {Data &&
                        Data.length > 0 &&
                        Data.map((item, i) => {
                          return (
                            <option value={item.id} key={i}>
                              {item.name}
                            </option>
                          );
                        })}
                    </select>
                  </Col>
                  <Col>
                    <Label htmlFor="videoId" className="lableCategory">
                      Video Id:
                    </Label>
                    <Input
                      type="text"
                      id="videoId"
                      name="videoId"
                      values={formik.values.videoId}
                      placeholder={`Enter video id`}
                      onChange={formik.handleChange}
                    />
                  </Col>
                </Row>
                <Row form>
                  <Col>
                    <Label>Title English:</Label>
                    <Input
                      type="text"
                      name="titleEn"
                      placeholder={`Enter Title English`}
                      values={formik.values.titleEn}
                      onChange={formik.handleChange}
                    />
                  </Col>
                  <Col>
                    <Label for="name" className="lableCategory">
                      Title Hindi :
                    </Label>
                    <Input
                      type="text"
                      name="titleHi"
                      placeholder={`Enter Title Hindi`}
                      values={formik.values.titleHi}
                      onChange={formik.handleChange}
                    />
                  </Col>
                </Row>
                <Row form>
                  <Col>
                    <Label for="name" className="lableCategory">
                      Meta Description English:
                    </Label>
                    <Input
                      type="name"
                      name="descriptionEn"
                      placeholder={`Enter Description Hindi`}
                      value={formik.values.descriptionEn}
                      onChange={formik.handleChange}
                    />
                  </Col>
                  <Col>
                    <Label for="name" className="lableCategory">
                      Meta Description Hindi:
                    </Label>
                    <Input
                      type="text"
                      name="descriptionHi"
                      placeholder={`Enter Description Hindi `}
                      values={formik.values.descriptionHi}
                      onChange={formik.handleChange}
                    />
                  </Col>
                </Row>
                <div className="col-lg-6">
                  <Row form>
                    <Col>
                      <Label for="name" className="lableCategory">
                        Status:
                      </Label>
                      <Input
                        type="text"
                        name="status"
                        placeholder={formik.values.status}
                        value={formik.values.status}
                        onChange={formik.handleChange}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
            <Button
              style={{
                display: "inline-block",
                width: "200px",
                float: "right",
                marginTop: "15px",
                background: "linear-gradient(180deg, #DD2A12 0%, #FFA214 100%)",
              }}
              type="submit"
            >
              <strong>Add Live Category</strong>
            </Button>
          </Form>
        </section>
      </>
    );
  } else {
    window.location.href = "/";
  }
}

export default Index;
