import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Link } from "react-router-dom";
import Navigation from '../Navigation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleRight, faAngleDown, faAngleUp, faArrowDown, faTurnDown } from "@fortawesome/free-solid-svg-icons";
import { UncontrolledCollapse, Button, CardBody, Card } from 'reactstrap';
import styles from '../../../assets/css/styles.css'
import Sidebar from '../Side';

const url = process.env.REACT_APP_BASE_URL

const Index = () => {
    const [darshan, setDarshan] = useState([])
    const navigate = useNavigate()
    const [up, setUp] = useState(false);
    const token = localStorage.getItem('token');
    const name = localStorage.getItem("user")
    const pageTitle = 'Live Darshan';
    const active = 'active'
    if (token && token != 'undefined') {
        const HandleRedirect = (value) => {
            navigate('/edit-live-darshan',
                {
                    state: value
                })
        }


        const Live = async () => {
            await axios.get(url + "/admin/live-darshan-data",
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'client': 3,
                        "ngrok-skip-browser-warning": "any"
                    }
                }
            ).then((res) => {
                setDarshan(res.data.data)
            }).catch((err) => {
                console.log("Error::::::::", err)
            }
            )
        }
        const Down = (index) => {
            const down = document.getElementById(`${index}down`)
            if (down.style.transform == 'rotate(180deg)') {
                down.style.transition = 'all 0.3s ease-out'
                down.style.transform = 'rotate(0deg)'
            } else if (down !== null) {
                down.style.transition = 'all 0.3s ease-out'
                down.style.transform = 'rotate(180deg)'
            }
        }

        useEffect(() => {
            Live()
        }, [])

        return (
            <>

                <div className="main-parentcontainer">
                    <div className="grid-parent"> <Sidebar data={name} pgc={active} />
                        <div className='grid-child'>  <Navigation data={pageTitle} />
                            <section className='postCount' style={{ position: 'relative', overflow: 'scroll', height: "99%" }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px', background: "white", position: "sticky", top: 0, zIndex: "9" }}>
                                    {/* <div>
                            <span className='lableCategory'>Live Darshan</span>
                        </div> */}
                                    <div className=' width-full d-flex align-items-center justify-content-end p-2 position-sticky top-0'>
                                        <Link to='/add-new-live-darshan-category'>
                                            <button className='addCategoryButton'>ADD New Live Darshan <FontAwesomeIcon icon={faAngleDoubleRight} /></button>
                                        </Link>
                                    </div>
                                </div>
                                <table
                                    class="table table-striped table-bordered" l
                                    style={{
                                        borderRadius: '3px',
                                        padding: "5px",
                                        backgroundColor: 'white',
                                    }}
                                >
                                    {/* <thead>
                            <tr colsapan="2">
                                <th colspan="2">
                                    <th> Category Name</th>
                                </th>

                            </tr>
                        </thead> */}
                                    <tbody>
                                        {darshan && darshan.length > 0 && darshan.map((item, index) => {
                                            return <>
                                                {!index && <thead style={{ width: '100%', display: 'inline-block' }}>
                                                    <th style={{ float: 'left' }}>S No</th>
                                                    <th style={{ float: 'left' }}>Video Name</th>
                                                    <th style={{ float: 'right' }}>Edit</th>
                                                </thead>}
                                                <tr className={styles.upButton} key={index + 1} style={{ borderWidth: '0px' }}>
                                                    <Button id={item.id} style={{ display: 'inline-block', border: 'none', borderWidth: '0px', color: "black", background: 'none', width: "100%", textalign: "center", padding: '0px' }}>
                                                        <td style={{ padding: '10px', width: '100%', display: 'inline-block', textAlign: 'left', fontWeight: '700' }}>
                                                            {item.categoryName}
                                                            {/* <FontAwesomeIcon id={index + 'down'} icon={faAngleDown} style={{ float: 'right', fontSize: "15px" }} /> */}
                                                        </td>
                                                    </Button>
                                                </tr>
                                                {/* <Button onClick={{ width: "100%" }}></Button> */}
                                                {/* <UncontrolledCollapse toggler={`#${item.id}`}> */}
                                                <Card>
                                                    <CardBody>
                                                        <table
                                                            class="table table-striped table-bordered"
                                                            style={{
                                                                borderRadius: '3px',
                                                                padding: "5px",
                                                                backgroundColor: 'white',
                                                            }}
                                                        >
                                                            <tbody>
                                                                <option selected> {item.video_name}</option>{item.video.map((ele, i) => {
                                                                    return <tr key={i + 1}>
                                                                        <td>{i + 1}</td>
                                                                        <td>{ele.video_name}</td>
                                                                        <td style={{ cursor: 'pointer' }} onClick={() => { HandleRedirect(ele) }}> Edit </td>
                                                                    </tr>
                                                                }
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </CardBody>
                                                </Card>
                                                {/* </UncontrolledCollapse> */}
                                            </>
                                        })}
                                    </tbody>
                                </table>
                            </section></div>
                    </div></div>


            </>
        )
    } else {
        window.location.href = '/';
    }
}

export default Index