import AxiosFunction from "../../lib/AxiosFunction";

export const pilgrim_dashboard_api = async (params) => {
    return await AxiosFunction(
        "get",
        "/api/pilgrim/admin/user-list-by-days?days=10&limit=1000000&skip=0",
        {},
        params
    );
};
export const pilgrim_Search_api = async (query) => {
    return await AxiosFunction(
        "get",
        `/api/pilgrim/admin/user-search?limit=100&skip=0&keyword=${query}`
    );
};

export const pilgrim_user_accounts_api = async (filter) => {
    return await AxiosFunction(
        "get",
        filter
            ? `/api/pilgrim/admin/user-list?accountType=${filter}`
            : "/api/pilgrim/admin/user-list"
    );
};

export const pilgrim_tours_user_enquiries = async () => {
    return await AxiosFunction(
        "get", "/api/pilgrim/admin/user-enquiries"
    );
};

export const pilgrim_tours_dashboard = async () => {
    return await AxiosFunction(
        "get", "/api/pilgrim/admin/dashboard"
    );
};

export const pilgrim_tours_categories = async () => {
    return await AxiosFunction(
        "get", "/api/pilgrim/admin/categories-with-sub-categories"
    );
};

export const pilgrim_tours_tours_by_sub_category = async (subCategoryId) => {
    return await AxiosFunction(
        "get", "/api/pilgrim/admin/packages-by-sub-category",
        null,
        { subCategoryId: subCategoryId }
    );
};

export const pilgrim_tour_details_By_tour_id = async (tourId) => {
    return await AxiosFunction(
        "get", "/api/pilgrim/admin/pacakge-by-id",
        null,
        { packageId: tourId }
    );
};

export const updated_package_details = async (tour, tourId) => {
    return await AxiosFunction(
        "get", "/api/pilgrim/admin/pacakge-by-id",
        tour,
        { packageId: tourId }
    );
};
export const get_all_package_details = async (tour, tourId) => {
    return await AxiosFunction(
        "get", "/api/pilgrim/admin/package-list",
        tour,
        { packageId: tourId }
    );
};
export const add_new_package = async (data,) => {
    return await AxiosFunction(
        "post", "/api/pilgrim/admin/add-new-package",
        data,
       
    );
};
export const add_new_category = async (data,) => {
    return await AxiosFunction(
        "post", "/api/pilgrim/admin/add-new-category",
        data,
       
    );
};
