import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import Navigation from "../../Admin/Navigation";
import Sidebar from "../../Admin/Side";
import Select from "react-select";
import {
  FormGroup,
  Label,
  Input,
  Button,
  Form,
  Row,
  Col,
  Alert,
  ButtonToggle,
} from "reactstrap";

const UpdateForm = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const history = useLocation();
  const [data, setData] = useState(history.state.data);
  const [type, setType] = useState(history.state.type);
  const onDismiss = () => setVisible(false);
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const [visible, setVisible] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState("");
  const [postDisable, setdisable] = useState(
    history.state.type !== 3
      ? history.state.data.is_disable
      : history.state.data.postStatus
  );
  const [trendin, setTrend] = useState(
    history.state.type !== 3
      ? history.state.data.is_trending
      : history.state.data.is_popular || false
  );
  const URL = process.env.REACT_APP_BASE_URL;

  const formik = useFormik({
    initialValues:
      history.state.type === 3
        ? {
            descriptionEn: history.state.data.description_en,
            descriptionHi: history.state.data.description_hi,
            festivalDate: history.state.data.festival_date,
            id: history.state.data.id,
            imageUrl: history.state.data.image,
            month: history.state.data.month,
            nameEn: history.state.data.name_en,
            nameHi: history.state.data.name_hi,
            parent_id: history.state.data.parent_id,
            slug: history.state.data.slug,
          }
        : {
            id: data.id,
            slug: data.slug,
            title: data.title,
            images: undefined,
            isDisable: data.is_disable,
            categoryId: data.categoryId,
            isTrending: data.is_trending,
          },

    onSubmit: (values) => {
      if (type == 1) {
        EditEnglishBlog(values);
      }
      if (type == 2) {
        EditHindiBlog(values);
      }

      if (type === 3) {
        EditFestivalBasicDetails(values);
      }
      // if (history.state.type == 2) {
      //     EditVratKathaDetails(values);
      // }
      // if (history.state.type == 3) {
      //     EditRitualDetails(values);
      // }
      // if (history.state.type == 4) {
      //     EditEnglishBlogDetails(values);
      // }
      // if (history.state.type == 5) {
      //     EditHindiBlogDetails(values);
      // }
      // if (history.state.type == 6) {
      //     EditWellnessDetails(values);
      // }
      // if (history.state.type == 7) {
      //     CreateNewHindiBlog(values);
      // }
      // if (history.state.type == 8) {
      //     CreateNewEnglishBlog(values);
      // }
    },
  });

  const EditHindiBlog = (values) => {
    values["isDisable"] = postDisable;
    values["isTrending"] = trendin;
    const formdata = new FormData();
    for (let key in values) {
      formdata.append(key, values[key]);
    }

    axios
      .post(`${URL}/admin/update-hi-blog-post?blogId=${data.id}`, formdata, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "any",
        },
      })
      // .then((res) => res.json())
      .then((result) => {
        if (result.data.status == "success") {
          setIsSuccess(true);
          setMessage("Content Updated Successfully");
          setTimeout(() => {
            setIsSuccess(false);
            setMessage("");
            navigate(-1);
          }, 3000);
        }
      })
      .catch((err) => {
        console.log("Error::::::::", err);
        setIsError(true);
        setError("Something went wrong. Try again");
      });
  };

  const EditEnglishBlog = (values) => {
    values["isDisable"] = postDisable;
    values["isTrending"] = trendin;

    const formdata = new FormData();
    for (let key in values) {
      formdata.append(key, values[key]);
    }

    setSubmitInProgress(true);

    axios
      .post(`${URL}/admin/update-en-blog-post?blogId=${data.id}`, formdata, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "any",
        },
      })
      // .then((res) => res.json())
      .then((result) => {
        setSubmitInProgress(false);
        if (result.data.status == "success") {
          setIsSuccess(true);
          setMessage("Content Updated Successfully");
          setTimeout(() => {
            setIsSuccess(false);
            setMessage("");
            navigate(-1);
          }, 3000);
        }
      })
      .catch((err) => {
        setSubmitInProgress(false);
        console.log("Error::::::::", err);
        setIsError(true);
        setTimeout(() => {
          setIsError(false);
        }, 3000);
        setError("Something went wrong. Try again");
      });
  };

  const EditFestivalBasicDetails = (values) => {
    values["isDisable"] = postDisable;
    values["isPopular"] = trendin;
    values["month"] = Number(values.month);
    console.log(values, "--sending values");
    setSubmitInProgress(true);
    axios
      .post(`${URL}/admin/festival-category-update`, values, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "any",
        },
      })
      .then((result) => {
        setSubmitInProgress(false);
        if (result) {
          setIsSuccess(true);
          setMessage("Content Updated Successfully");
          setTimeout(() => {
            setIsSuccess(false);
            setMessage("");
            navigate(-1);
          }, 3000);
        }
      })
      .catch((err) => {
        setSubmitInProgress(false);
        console.log("Error::::::::", err);
        setIsError(true);
        setTimeout(() => {
          setIsError(false);
        }, 3000);
        setError("Something went wrong. Try again");
      });
  };
  const EditRitualDetails = (values) => {
    fetch(`${URL}/admin/update-ritual-post?id=${data.id}&slug=${data.slug}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        Accept: "application/json",
        "Content-Type": "application/json",
        "ngrok-skip-browser-warning": "any",
      },
      body: JSON.stringify(values),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.status == "success") {
          setIsSuccess(true);
          setMessage("Content Updated Successfully");
          setTimeout(() => {
            setIsSuccess(false);
            setMessage("");
            navigate(-1);
          }, 3000);
        }
      })
      .catch((err) => {
        console.log("Error::::::::", err);
        setIsError(true);
        setError("Something went wrong. Try again");
      });
  };

  const EditHindiBlogDetails = (values) => {
    fetch(`${URL}/admin/update-hi-blog-post?blogId=${data.id}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        Accept: "application/json",
        "Content-Type": "application/json",
        "ngrok-skip-browser-warning": "any",
      },
      body: JSON.stringify(values),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.status == "success") {
          setIsSuccess(true);
          setMessage("Content Updated Successfully");
          setTimeout(() => {
            setIsSuccess(false);
            setMessage("");
            navigate(-1);
          }, 3000);
        }
      })
      .catch((err) => {
        console.log("Error::::::::", err);
        setIsError(true);
        setError("Something went wrong. Try again");
      });
  };
  const EditWellnessDetails = (values) => {
    values["isDisable"] = postDisable;
    values["isTrending"] = trendin;
    fetch(`${URL}/admin/update-wellness-post?postId=${data.id}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        Accept: "application/json",
        "Content-Type": "application/json",
        "ngrok-skip-browser-warning": "any",
      },
      // body: JSON.stringify(values),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.status == "success") {
          setIsSuccess(true);
          setMessage("Content Updated Successfully");
          setTimeout(() => {
            setIsSuccess(false);
            setMessage("");
            navigate(-1);
          }, 3000);
        } else if (result.status == "failed") {
          console.log("Error::::::::", result);
          setIsError(true);
          setError(result.msg);
        }
      });
  };
  const EditVratKathaDetails = (values) => {
    fetch(
      `${URL}/admin/update-vrat-katha-post?id=${data.id}&slug=${data.slug}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "any",
        },
        body: JSON.stringify(values),
      }
    )
      .then((res) => res.json())
      .then((result) => {
        if (result.status == "success") {
          setIsSuccess(true);
          setMessage("Content Updated Successfully");
          setTimeout(() => {
            setIsSuccess(false);
            setMessage("");
            navigate(-1);
          }, 3000);
        }
      })
      .catch((err) => {
        console.log("Error::::::::", err);
        setIsError(true);
        setError("Something went wrong. Try again");
      });
  };
  const CreateNewHindiBlog = (values) => {
    const formdata = new FormData();
    for (let key in values) {
      formdata.append(key, values[key]);
    }
    axios
      .post(`${URL}/admin/create-new-hi-blog`, formdata, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "any",
        },
      })
      .then((result) => {
        if (result.status == "200") {
          setIsSuccess(true);
          setMessage("Content Updated Successfully");
          setTimeout(() => {
            setIsSuccess(false);
            setMessage("");
            //     navigate(-1);
            navigate("/text-editor", {
              state: {
                data: result.data.data,
                language: "language",
                content: result.data.data.content,
                type: 5,
              },
            });
          }, 3000);
        }
      })
      .catch((err) => {
        console.log("Error::::::::", err);
        setIsError(true);
        setError("Something went wrong. Try again");
      });
  };
  const CreateNewEnglishBlog = (values) => {
    const formdata = new FormData();
    for (let key in values) {
      formdata.append(key, values[key]);
    }
    axios
      .post(`${URL}/admin/create-new-en-blog`, formdata, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "any",
        },
      })
      .then((result) => {
        if (result.status == "200") {
          setIsSuccess(true);
          setMessage("Content Updated Successfully");
          setTimeout(() => {
            setIsSuccess(false);
            setMessage("");
            //     navigate(-1);
            navigate("/text-editor", {
              state: {
                data: result.data.data,
                language: "language",
                content: result.data.data.content,
                type: 5,
              },
            });
          }, 3000);
        }
      })
      .catch((err) => {
        console.log("Error::::::::", err);
        setIsError(true);
        setError("Something went wrong. Try again");
      });
  };

  useEffect(() => {
    setType(history.state.type);
    setData(history.state.data);
  }, [history.state]);

  const name = localStorage.getItem("user");
  const pageTitle = "Edit basic Details";
  const active = "active";
  let months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const selectOption = [
    { value: "1", label: "January" },
    { value: "2", label: "February" },
    { value: "3", label: "March" },
    { value: "4", label: "April" },
    { value: "5", label: "May" },
    { value: "6", label: "June" },
    { value: "7", label: "July" },
    { value: "8", label: "August" },
    { value: "9", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];
  const days = {
    1: 31,
    2: 29,
    3: 31,
    4: 30,
    5: 31,
    6: 30,
    7: 31,
    8: 31,
    9: 30,
    10: 31,
    11: 30,
    12: 31,
  };
  function createArray(N) {
    const newArr = Array.from({ length: N }, (_, index) => index + 1);
    const newoptions = newArr.map((each) => {
      const result = {
        value: each,
        label: each,
      };
      return result;
    });
    return newoptions;
  }

  const defaultvalue = {
    value: Number(history.state.data.month),
    label: months[history.state.data.month - 1],
  };
  const [dates, setDates] = useState(createArray(days[defaultvalue.value]));

  // useEffect(() => {
  //   console.log(createArray(days[defaultvalue.value]));
  //   setDates(createArray(days[defaultvalue.value]));
  // }, [formik.values.month]);

  const defaultvalueDate = {
    value: history.state.data.festival_date,
    label: history.state.data.festival_date,
  };
  if (token && token != "undefined") {
    return (
      <>
        <div className="main-parentcontainer">
          <div className="grid-parent">
            <Sidebar data={name} pgc={active} />
            <div className="grid-child">
              <Navigation data={pageTitle} />
              <section className="postCountIV ">
                <div
                  style={{
                    color: "black",
                    marginBottom: "30px",
                    textAlign: "center",
                    width: "100%",
                    borderRadius: "10px",
                    justifyContent: "center",
                    marginLeft: "10px",
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <h3>{history.state.pageTitle}</h3>
                </div>
                {history.state.type !== 3 && (
                  <Form
                    onSubmit={formik.handleSubmit}
                    style={{ padding: "10px 20px 0px 20px" }}
                  >
                    <div className="row">
                      <div className="col-lg-6">
                        {isSuccess && (
                          <Alert
                            color="info"
                            isOpen={visible}
                            toggle={onDismiss}
                          >
                            {message}
                          </Alert>
                        )}
                      </div>

                      <div className="row">
                        <div className="col-lg-6">
                          {isError && (
                            <Alert
                              style={{
                                background: "rgb(251, 99, 71)",
                                color: "white",
                              }}
                              isOpen={visible}
                              toggle={onDismiss}
                            >
                              Error::
                              {error}
                            </Alert>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="row update-basic-details-form">
                        <Row form>
                          <Col>
                            <Label for="categoryId" className="lableCategory">
                              Category Id:
                            </Label>
                            <Input
                              type="text"
                              name={"categoryId"}
                              placeholder={formik.values.categoryId}
                              values={formik.values.categoryId}
                              disabled={true}
                              // onChange={formik.handleChange}
                            />
                          </Col>
                          <Col>
                            <Label for="id" className="lableCategory">
                              Blog Id:
                            </Label>
                            <Input
                              type="text"
                              name={"id"}
                              placeholder={formik.values.id}
                              values={formik.values.id}
                              disabled={true}
                              onChange={formik.handleChange}
                            />
                          </Col>
                        </Row>
                        <Row form>
                          <Col>
                            <Label for="slug" className="lableCategory">
                              Slug:
                            </Label>
                            <Input
                              type="text"
                              name="slug"
                              placeholder={formik.values.slug}
                              values={formik.values.slug}
                              onChange={formik.handleChange}
                              disabled={true}
                            />
                          </Col>
                        </Row>
                        <Row form>
                          <Col>
                            <Label for="name" className="lableCategory">
                              Title:
                            </Label>
                            <Input
                              type="text"
                              name={"title"}
                              // required
                              // placeholder={`Enter Title `}

                              values={formik.values.title}
                              placeholder={formik.values.title}
                              onChange={formik.handleChange}
                            />
                          </Col>
                        </Row>
                        <Row form>
                          <Col>
                            <Label for="Image" className="lableCategory">
                              Image:
                            </Label>
                            <Input
                              type={"file"}
                              name="Image"
                              placeholder={
                                history.state.data
                                  ? history.state.data.image
                                  : `Enter image Url`
                              }
                              value={formik.values.images}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "image",
                                  e.currentTarget.files[0]
                                );
                              }}
                            />
                          </Col>
                        </Row>
                        <Row form>
                          <Col>
                            <label className="lableCategory">
                              {"Disable: "}
                              <span
                                style={
                                  formik.values.isDisable
                                    ? { backgroundColor: "green" }
                                    : { backgroundColor: "red" }
                                }
                                className="select-option"
                              ></span>
                            </label>
                            <div className="col-md-8 mb-2">
                              <div className="toggle-btn">
                                <div
                                  className={postDisable && "yes"}
                                  onClick={() => {
                                    if (postDisable) {
                                      setdisable(false);
                                    } else {
                                      setdisable(true);
                                    }
                                  }}
                                >
                                  Yes
                                </div>
                                <div
                                  onClick={() => {
                                    if (postDisable) {
                                      setdisable(false);
                                    } else {
                                      setdisable(true);
                                    }
                                  }}
                                  className={!postDisable && "no"}
                                >
                                  No
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col>
                            <label className="lableCategory">
                              {"Trending: "}
                              <span
                                style={
                                  formik.values.isTrending
                                    ? { backgroundColor: "green" }
                                    : { backgroundColor: "red" }
                                }
                                className="select-option"
                              ></span>
                            </label>
                            <div className="col-md-8 mb-2">
                              <div className="toggle-btn">
                                <div
                                  className={trendin && "yes"}
                                  onClick={() => {
                                    if (trendin) {
                                      setTrend(false);
                                    } else {
                                      setTrend(true);
                                    }
                                  }}
                                >
                                  Yes
                                </div>
                                <div
                                  onClick={() => {
                                    if (!trendin) {
                                      setTrend(true);
                                    } else {
                                      setTrend(false);
                                    }
                                  }}
                                  className={!trendin && "no"}
                                >
                                  No
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>

                    <Button
                      disabled={submitInProgress}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "200px",
                        float: "right",
                        marginTop: "15px",
                        background:
                          "linear-gradient(180deg, #DD2A12 0%, #FFA214 100%)",
                      }}
                      type="submit"
                    >
                      {!submitInProgress ? (
                        <strong>Update Basic Details</strong>
                      ) : (
                        <span className="special-loader"></span>
                      )}
                    </Button>
                  </Form>
                )}

                {history.state.type === 3 && (
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row">
                      <div className="col-lg-6">
                        {isSuccess && (
                          <Alert
                            color="info"
                            isOpen={visible}
                            toggle={onDismiss}
                          >
                            {message}
                          </Alert>
                        )}
                      </div>

                      <div className="row">
                        <div className="col-lg-6">
                          {isError && (
                            <Alert
                              style={{
                                background: "rgb(251, 99, 71)",
                                color: "white",
                              }}
                              isOpen={visible}
                              toggle={onDismiss}
                            >
                              Error::
                              {error}
                            </Alert>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="form">
                      <div className="each-input">
                        <label>Name English*</label>
                        <input
                          required
                          type="text"
                          value={formik.values.nameEn}
                          name="nameEn"
                          onChange={formik.handleChange}
                          placeholder="add festival name in English"
                        ></input>
                      </div>

                      <div className="each-input">
                        <label>Name Hindi*</label>
                        <input
                          required
                          value={formik.values.nameHi}
                          name="nameHi"
                          onChange={formik.handleChange}
                          type="text"
                          placeholder="add festival name in Hindi"
                        ></input>
                      </div>

                      <div className="each-input">
                        <label>Slug*</label>
                        <input
                          required
                          disabled={true}
                          value={formik.values.slug}
                          name="slug"
                          onChange={formik.handleChange}
                          type="text"
                          placeholder="Cretae a unique slug"
                        ></input>
                      </div>

                      <div className="each-input">
                        <label>Description English</label>
                        <input
                          //                         required
                          value={formik.values.descriptionEn}
                          name="descriptionEn"
                          onChange={formik.handleChange}
                          placeholder="add description in English"
                          type="text"
                        ></input>
                      </div>

                      <div className="each-input">
                        <label>Description Hindi</label>
                        <input
                          value={formik.values.descriptionHi}
                          name="descriptionHi"
                          onChange={formik.handleChange}
                          placeholder="add description in Hindi"
                          type="text"
                        ></input>
                      </div>

                      <div className="each-input">
                        <label>Month*</label>
                        <Select
                          id="slect-month"
                          defaultValue={defaultvalue}
                          options={selectOption}
                          onChange={(e) => {
                            if (e) {
                              formik.values.month = e.value;
                              setDates(createArray(days[e.value]));
                            }
                          }}
                        />
                      </div>

                      <div className="each-input">
                        <label>Festival Date*</label>

                        <Select
                          id="slect-month"
                          required
                          defaultValue={defaultvalueDate}
                          options={dates}
                          name="festivalDate"
                          onChange={(e) => {
                            if (e) {
                              formik.values.festivalDate = e.value;
                            }
                          }}
                        />
                      </div>

                      {/* <div className="each-input">
                        <label>Image url*</label>
                        <Input
                          type={"file"}
                          name="image"
                          // onChange={handleupdate}
                        />
                      </div> */}
                      <Row form>
                        <Col>
                          <label className="lableCategory">
                            {"Disable: "}{" "}
                            <span
                              style={
                                postDisable == 1
                                  ? { backgroundColor: "green" }
                                  : { backgroundColor: "red" }
                              }
                              className="select-option"
                            ></span>
                          </label>
                          <div className="col-md-8 mb-2">
                            <div className="toggle-btn">
                              <div
                                className={postDisable && "yes"}
                                onClick={() => {
                                  if (postDisable) {
                                    setdisable(false);
                                  } else {
                                    setdisable(true);
                                  }
                                }}
                              >
                                Yes
                              </div>
                              <div
                                onClick={() => {
                                  if (postDisable) {
                                    setdisable(false);
                                  } else {
                                    setdisable(true);
                                  }
                                }}
                                className={!postDisable && "no"}
                              >
                                No
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col>
                          <label className="lableCategory">
                            {"Trending: "}
                            <span
                              style={
                                formik.values.isTrending
                                  ? { backgroundColor: "green" }
                                  : { backgroundColor: "red" }
                              }
                              className="select-option"
                            ></span>
                          </label>
                          <div className="col-md-8 mb-2">
                            <div className="toggle-btn">
                              <div
                                className={trendin && "yes"}
                                onClick={() => {
                                  if (trendin) {
                                    setTrend(false);
                                  } else {
                                    setTrend(true);
                                  }
                                }}
                              >
                                Yes
                              </div>
                              <div
                                onClick={() => {
                                  if (!trendin) {
                                    setTrend(true);
                                  } else {
                                    setTrend(false);
                                  }
                                }}
                                className={!trendin && "no"}
                              >
                                No
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>

                    <div className="add-f-parent2">
                      {/* <button type="submit" className="add-festivel">
                        Update
                      </button> */}
                      <Button
                        disabled={submitInProgress}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "200px",
                          float: "right",
                          marginTop: "15px",
                          background:
                            "linear-gradient(180deg, #DD2A12 0%, #FFA214 100%)",
                        }}
                        type="submit"
                      >
                        {!submitInProgress ? (
                          <strong>Update Basic Details</strong>
                        ) : (
                          <span className="special-loader"></span>
                        )}
                      </Button>
                    </div>
                  </form>
                )}
              </section>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    window.location.href = "/";
  }
};

export default UpdateForm;
