import React, { useState } from "react";
import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { Alert } from "reactstrap";
import Navigation from "../../Navigation";
import Sidebar from "../../Side";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

// import axios from 'axios';

const url = process.env.REACT_APP_BASE_URL;

function InputField({
  name,
  disabled,
  onChange,
  type,
  placeholder,
  value,
  label,
  ...props
}) {
  return (
    <div className="col-lg-6">
      <div className="form-group">
        <div className="row">
          {label ? (
            <label className="col-lg-4 col-form-label">{label}</label>
          ) : (
            ""
          )}
          <div className="col-lg-8 mb-2">
            <input
              type={type}
              name={name}
              placeholder={placeholder ? placeholder : label}
              className="form-control"
              {...props}
              value={value}
              onChange={onChange}
              disabled={disabled}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function Form(props) {
  const history = useLocation();
  const router = useNavigate();
  const [visible, setVisible] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const onDismiss = () => setVisible(false);
  const [data, setData] = useState(history.state);
  const navigate = useNavigate();

  const EddSeoFestival = (values) => {
    fetch(`${url}/admin/update-all-seo-data?type=${data.type}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.status == "success") {
          setIsSuccess(true);
          setMessage(result.msg);
          setData(result.data[0]);
          setTimeout(() => {
            setIsSuccess(false);
            setMessage("");
          }, 3000);
        } else if (result.status == "failed") {
          console.log("Error::::::::", result);
          setIsError(true);
          setError(result.msg);
        }
      })
      .catch((err) => {
        console.log("Error::::::::", err);
        setIsError(true);
        setError("Something went wrong. Try again");
      });
  };

  const formik = useFormik({
    initialValues: {
      category_id: data.category_id,
      title: data.title,
      meta_description: data.meta_description,
      meta_keyword: data.meta_keyword,
      meta_og_image: data.meta_og_image,
      title_hi: data.title_hi,
      meta_description_hi: data.meta_description_hi,
      meta_keyword_hi: data.meta_keyword_hi,
      meta_og_url_hi: data.meta_og_url_hi,
      meta_og_url: data.meta_og_url,
    },
    onSubmit: (values) => {
      EddSeoFestival(values);
    },
  });
  const token = localStorage.getItem("token");
  const name = localStorage.getItem("user");
  const pageTitle = " Edit SEO";
  const active = "active";
  if (token && token != "undefined") {
    return (
      <>
        <div className="main-parentcontainer">
          <div className="grid-parent">
            {" "}
            <Sidebar data={name} pgc={active} />
            <div className="grid-child">
              {" "}
              <Navigation data={pageTitle} />
              <section className="postCount" style={{ overflow: "scroll" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      color: "black",
                      marginBottom: "30px",
                      textAlign: "center",
                      borderRadius: "10px",
                      margin: "auto",
                    }}
                  >
                    <h3>Edit {data.componentName} SEO</h3>
                  </div>
                  {/* <button style={{ cursor: 'pointer', borderRadius: "10px", justifyContent: "flex-end",height:"40px" }} onClick={() => { HandleRedirect(data.type, data.name) }}> ADD New SEO<FontAwesomeIcon icon={faAngleDoubleRight} /> </button> */}
                </div>
                <form
                  onSubmit={formik.handleSubmit}
                  style={{ width: "90%", margin: "auto" }}
                >
                  <div className="row">
                    <div className="col-lg-6">
                      {isSuccess && (
                        <Alert color="info" isOpen={visible} toggle={onDismiss}>
                          {message}
                        </Alert>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      {isError && (
                        <Alert
                          style={{
                            background: "rgb(251, 99, 71)",
                            color: "white",
                          }}
                          isOpen={visible}
                          toggle={onDismiss}
                        >
                          Error::
                          {error}
                        </Alert>
                      )}
                    </div>
                  </div>
                  <div
                    className="row row-bg-2"
                    style={{ padding: "7px", marginTop: "30px" }}
                  >
                    <div className="col-lg-6">
                      <div className="form-group">
                        <div className="row">
                          <label className="col-md-4 col-form-label">
                            {"Category ID: "}
                          </label>
                          <div className="col-md-8 mb-6">
                            <input
                              type="text"
                              name="category_id"
                              onChange={formik.handleChange}
                              value={formik.values.category_id}
                              disabled={true}
                              style={{ width: "100%" }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <div className="row">
                          <label className="col-md-4 col-form-label">
                            {"Title: "}
                          </label>
                          <div className="col-md-8 mb-4">
                            <input
                              type="text"
                              name="title"
                              onChange={formik.handleChange}
                              value={formik.values.title}
                              style={{ width: "100%" }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <div className="row">
                          <label className="col-md-4 col-form-label">
                            {"Meta Description: "}
                          </label>
                          <div className="col-md-8 mb-4">
                            <input
                              type="text"
                              name="meta_description"
                              // onClick={formik.values.meta_description}
                              onChange={formik.handleChange}
                              value={formik.values.meta_description}
                              style={{ width: "100%" }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <div className="row">
                          <label className="col-md-4 col-form-label">
                            {"Meta Keyword: "}
                          </label>
                          <div className="col-md-8 mb-4">
                            <input
                              type="text"
                              name="meta_keyword"
                              onChange={formik.handleChange}
                              value={formik.values.meta_keyword}
                              style={{ width: "100%" }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <div className="row">
                          <label className="col-md-4 col-form-label">
                            {"Meta og Image: "}
                          </label>
                          <div className="col-md-8 mb-4">
                            <input
                              type="text"
                              name="meta_og_image"
                              onChange={formik.handleChange}
                              value={formik.values.meta_og_image}
                              style={{ width: "100%" }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <div className="row">
                          <label className="col-md-4 col-form-label">
                            {"Title Hindi : "}
                          </label>
                          <div className="col-md-8 mb-4">
                            <input
                              type="text"
                              name="title_hi"
                              onChange={formik.handleChange}
                              value={formik.values.title_hi}
                              style={{ width: "100%" }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <div className="row">
                          <label className="col-md-4 col-form-label">
                            {"Meta Description Hindi : "}
                          </label>
                          <div className="col-md-8 mb-4">
                            <input
                              type="text"
                              name="meta_description_hi"
                              onChange={formik.handleChange}
                              value={formik.values.meta_description_hi}
                              style={{ width: "100%" }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <div className="row">
                          <label className="col-md-4 col-form-label">
                            {"Meta Keyword Hindi: "}
                          </label>
                          <div className="col-md-8 mb-4">
                            <input
                              type="text"
                              name="meta_keyword_hi"
                              onChange={formik.handleChange}
                              value={formik.values.meta_keyword_hi}
                              style={{ width: "100%" }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <div className="row">
                          <label className="col-md-4 col-form-label">
                            {"Meta Og Url English: "}
                          </label>
                          <div className="col-md-8 mb-4">
                            <input
                              type="text"
                              name="meta_og_url"
                              onChange={formik.handleChange}
                              value={formik.values.meta_og_url}
                              style={{ width: "100%" }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <div className="row">
                          <label className="col-md-4 col-form-label">
                            {"Meta og Url Hindi: "}
                          </label>
                          <div className="col-md-8 mb-4">
                            <input
                              type="text"
                              name="meta_og_url_hi"
                              onChange={formik.handleChange}
                              value={formik.values.meta_og_url_hi}
                              style={{ width: "100%" }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="submit-btn" style={{ textAlign: "center" }}>
                    <button
                      type="submit"
                      className="btn mb-2"
                      style={{
                        background:
                          "linear-gradient(180deg, #DD2A12 0%, #FFA214 100%)",
                        color: "white",
                        padding: "10px",
                        width: "100px",
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </section>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    window.location.href = "/";
  }
}

export default Form;
