import React, { useState } from "react";
import { useFormik } from "formik";
import { useLocation } from "react-router-dom";
import { Alert } from "reactstrap";
const url = process.env.REACT_APP_BASE_URL;

function Form() {
  // useLocation
  const history = useLocation();

  // token
  const token = localStorage.getItem("token");

  // states
  const [visible, setVisible] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const onDismiss = () => setVisible(false);
  const [data, setData] = useState(history.state);

  // functions 
  const addLiveDarshan = (values) => {
    fetch(`${url}/admin/update-live-darshan`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        Accept: "application/json",
        "Content-Type": "application/json",
        "ngrok-skip-browser-warning": "any",
      },
      // method: "POST",
      body: JSON.stringify(values),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.code == "200") {
          setIsSuccess(true);
          setMessage(result.msg);
          setData(result.data[0]);
          setTimeout(() => {
            setIsSuccess(false);
            setMessage("");
          }, 3000);
        }
      })
      .catch((err) => {
        console.log("Error::::::::", err);
        setIsError(true);
        setError("Something went wrong. Try again");
      });
  };
  const formik = useFormik({
    initialValues: {
      id: data.id,
      video_id: data.video_id,
      is_disable: data.is_disable,
      is_special: data.is_special,
      category_name: data.category_name,
      channel_id: data.channel_id,
      video_name: data.video_name,
      video_short_name: data.video_short_name,
      thumbnail: data.thumbnail,
    },
    onSubmit: (values) => {
      addLiveDarshan(values);
    },
  });

  if (token && token != "undefined") {
    return (
      <>
        <section
          className="postCount edit-live-darshan-mob"
          style={{ overflow: "scroll" }}
        >
          <form
            onSubmit={formik.handleSubmit}
            style={{ width: "90%", margin: "auto" }}
          >
            <div className="row">
              <div className="col-lg-6">
                {isSuccess && (
                  <Alert color="info" isOpen={visible} toggle={onDismiss}>
                    {message}
                  </Alert>
                )}
              </div>
            </div>
            <div className="row row-bg-2" style={{ padding: "7px" }}>
              <div
                className="col-lg-12 p-2"
                style={{
                  backgroundColor: "#6c757d",
                  color: "white",
                  marginBottom: "30px",
                }}
              >
                <h5>Edit Live Darshan</h5>
              </div>

              <div className="col-lg-6">
                <div className="form-group">
                  <div className="row">
                    <label className="col-md-4 col-form-label">{"ID: "}</label>
                    <div className="col-md-8 mb-2">
                      <input
                        type="text"
                        name="id"
                        onChange={formik.handleChange}
                        value={formik.values.id}
                        disabled={true}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <div className="row">
                    <label className="col-md-4 col-form-label">
                      {"Catagory Name: "}
                    </label>
                    <div className="col-md-8 mb-2">
                      <input
                        type="text"
                        name="catagory_name"
                        onClick={formik.values.category_name}
                        onChange={formik.handleChange}
                        value={formik.values.category_name}
                        disabled={true}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <div className="row">
                    <label className="col-md-4 col-form-label">
                      {"Channel ID: "}
                    </label>
                    <div className="col-md-8 mb-2">
                      <input
                        type="text"
                        name="channel_id"
                        onChange={formik.handleChange}
                        disabled={true}
                        value={formik.values.channel_id}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <div className="row">
                    <label className="col-md-4 col-form-label">
                      {"Video Name: "}
                    </label>
                    <div className="col-md-8 mb-2">
                      <input
                        type="text"
                        name="video_name"
                        onChange={formik.handleChange}
                        value={formik.values.video_name}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <div className="row">
                    <label className="col-md-4 col-form-label">
                      {"Video Short Name: "}
                    </label>
                    <div className="col-md-8 mb-2">
                      <input
                        type="text"
                        name="video_short_name"
                        onChange={formik.handleChange}
                        value={formik.values.video_short_name}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <div className="row">
                    <label className="col-md-4 col-form-label">
                      {"Video-ID: "}
                    </label>
                    <div className="col-md-8 mb-2">
                      <input
                        type="text"
                        name="video_id"
                        onChange={formik.handleChange}
                        value={formik.values.video_id}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <div className="row">
                    <label className="col-md-4 col-form-label">
                      {"Thumbnail: "}
                    </label>
                    <div className="col-md-8 mb-2">
                      <input
                        type="text"
                        name="thumbnail"
                        onChange={formik.handleChange}
                        value={formik.values.thumbnail}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <div className="row">
                    <label className="col-md-4 col-form-label">
                      {"Disable: "}{" "}
                      <span
                        style={
                          formik.values.is_disable
                            ? { backgroundColor: "red" }
                            : { backgroundColor: "green" }
                        }
                        className="select-option"
                      ></span>
                    </label>
                    <div className="col-md-8 mb-2">
                      <select
                        name="is_disable"
                        onChange={formik.handleChange}
                        style={{ width: "100%" }}
                      >
                        <option value={"Select"}>Select</option>
                        <option value={"false"}>Enable</option>
                        <option value={"true"}>Disable</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <div className="row">
                    <label className="col-md-4 col-form-label">
                      {"Special: "}{" "}
                      <span
                        style={
                          formik.values.is_special
                            ? { backgroundColor: "green" }
                            : { backgroundColor: "red" }
                        }
                        className="select-option"
                      ></span>
                    </label>
                    <div className="col-md-8 mb-2">
                      <select
                        name="is_special"
                        onChange={formik.handleChange}
                        style={{ width: "100%" }}
                      >
                        <option value={"Select"}>Select</option>
                        <option value={"true"}>Enable</option>
                        <option value={"false"}>Disable</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="submit-btn" style={{ textAlign: "center" }}>
              <button
                type="submit"
                className="btn mb-2"
                style={{
                  background:
                    "linear-gradient(180deg, #DD2A12 0%, #FFA214 100%)",
                  color: "white",
                  padding: "10px",
                  width: "100px",
                }}
              >
                Submit
              </button>
            </div>
          </form>
        </section>
      </>
    );
  } else {
    window.location.href = "/";
  }
}

export default Form;
