import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import RichTextEditor from "./Components/RichTextEditor";
import "reactjs-popup/dist/index.css";
import "./App.css";
import { string } from "yup";

const Editor = () => {
  // useLocations
  const history = useLocation();

  // token
  const token = localStorage.getItem("token");

  // states
  const [data, setData] = useState();
  const [value, setValue] = useState("");

  // functions
  const getValue = (value) => {
    string(value);
    if (value.includes("'")) {
      const textEditorBox = document.getElementById("textEditorBox");
      textEditorBox.style.color = "red";
      popUp();
    } else {
      const textEditorBox = document.getElementById("textEditorBox");
      setValue(value);
      textEditorBox.style.color = "black";
    }
  };
  function popUp() {
    alert(`Please remove ( ' )single Quote`);
  }

  if (token && history.state != null) {
    return (
      <div>
        <div
          style={{ display: "flex", flexDirection: "row-reverse" }}
          className="text-editor-desktop"
        >
          <div id="textEditorBox" style={{ width: "50%", height: "90vh" }}>
            <RichTextEditor
              getValue={getValue}
              data={
                history.state.language == "hi"
                  ? history.state.content.content_hi
                  : history.state.content
                  ? history.state.content.content_en
                  : ""
              }
              setData={setData}
              language={history.state.language}
              type={history.state.type}
              state={history.state}
            />
          </div>
          <textarea
            className="scroll"
            style={{ width: "50%", height: "100vh" }}
            value={value}
          ></textarea>
        </div>
      </div>
    );
  } else {
    window.location.href = "/";
  }
};

export default Editor;
