import React from "react";
import SubUploadImage from "./SubUploadImage";
const Index = () => {
  const token = localStorage.getItem("token");

  if (token) {
    return (
      <>
        <SubUploadImage />
      </>
    );
  } else {
    window.location.href = "/";
  }
};

export default Index;
