import React, { useState } from "react";
import { useFormik } from "formik";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { Label, Input, Button, Row, Col, Alert } from "reactstrap";
import * as Yup from "yup";

const url = process.env.REACT_APP_BASE_URL;

function Index() {
  // useLocation
  const history = useLocation();

  // token
  const token = localStorage.getItem("token");

  // states
  const [visible, setVisible] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const onDismiss = () => setVisible(false);
  const [data, setData] = useState(history.state);

  // functions
  const addFestivalCatagory = (values) => {
    axios
      .post(`${url}/admin/add-new-seo?type=${data.type}`, values, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "any",
        },
      })
      .then((result) => {
        if (result.status == "200") {
          setIsSuccess(true);
          setData(result.data.data);
          setMessage("Successfully Add  SEO");
          setTimeout(() => {
            setIsSuccess(false);
            setMessage("");
          }, 4000);
        }
        formik.resetForm();
      })
      .catch((err) => {
        console.log("Error::::::::", err);
        setIsError(true);
        setMessage("Already Exist Entry !");
        setError("Something went wrong. Try again");
        setTimeout(() => {
          setIsError(false);
          setMessage("");
        }, 4000);
      });
  };
  const formik = useFormik({
    initialValues: {
      categoryId: data.id,
      title: data.title,
      metaDescription: "",
      metaKeyword: "",
      metaRobots: "",
      metaOgUrl: "",
      metaOgImage: "",
      titleHi: "",
      metaDescriptionHi: "",
      metaKeywordHi: "",
      metaOgUrlHi: "",
    },
    onSubmit: (values) => {
      addFestivalCatagory(values);
    },
    validationSchema: () =>
      Yup.object().shape({
        categoryId: Yup.string().required("Category id is required"),
        title: Yup.string().required("title  is required"),
      }),
  });

  if (token && token != "undefined") {
    return (
      <>
        <section className="postCount" style={{ overflow: "scroll" }}>
          <div
            style={{
              color: "black",
              marginBottom: "30px",
              textAlign: "center",
              width: "100%",
              borderRadius: "10px",
              justifyContent: "center",
              marginLeft: "10px",
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <h3>Add {data.componentName} SEO</h3>
          </div>

          <form
            onSubmit={formik.handleSubmit}
            style={{ padding: "10px 20px 0px 20px" }}
          >
            <div className="row">
              <div className="col-lg-6">
                {isSuccess && (
                  <Alert color="info" isOpen={visible} toggle={onDismiss}>
                    {message}
                  </Alert>
                )}
              </div>
              <div className="row">
                <div className="col-lg-6">
                  {isError && (
                    <Alert
                      style={{
                        background: "rgb(251, 99, 71)",
                        color: "white",
                      }}
                      isOpen={visible}
                      toggle={onDismiss}
                    >
                      Error::
                      {message}
                    </Alert>
                  )}
                </div>
              </div>
            </div>
            <div></div>
            <div className="col-lg-12">
              <div className="row">
                <Row form>
                  <Col>
                    <Label for="name" className="lableCategory">
                      Category Id:
                    </Label>
                    <Input
                      type="text"
                      name="id"
                      placeholder={
                        history.state ? history.state.id : `Enter CategoryId`
                      }
                      values={formik.values.categoryId}
                      disabled
                      onChange={formik.handleChange}
                    />
                  </Col>
                  <Col>
                    <Label for="name" className="lableCategory">
                      Title:
                    </Label>
                    <Input
                      type="text"
                      name="title"
                      disabled
                      values={formik.values.title}
                      placeholder={
                        history.state ? history.state.name_en : `Enter Title`
                      }
                      onChange={formik.handleChange}
                    />
                  </Col>
                </Row>
                <Row form>
                  <Col>
                    <Label for="name" className="lableCategory">
                      Title Hindi:
                    </Label>
                    <Input
                      type="text"
                      name="titleHi"
                      placeholder={
                        history.state ? history.state.name_hi : `Enter TitleHi`
                      }
                      values={formik.values.titleHi}
                      onChange={formik.handleChange}
                    />
                  </Col>

                  <Col>
                    <Label for="name" className="lableCategory">
                      Meta Description:
                    </Label>
                    <Input
                      type="text"
                      name="metaDescription"
                      placeholder={`Enter Meta Description`}
                      values={formik.values.metaDescription}
                      onChange={formik.handleChange}
                    />
                  </Col>
                </Row>
                <Row form>
                  <Col>
                    <Label for="name" className="lableCategory">
                      {" "}
                      Meta Description Hindi:
                    </Label>
                    <Input
                      type="name"
                      name="metaDescriptionHi"
                      placeholder={` Enter Meta Description Hindi`}
                      value={formik.values.metaDescriptionHi}
                      onChange={formik.handleChange}
                    />
                  </Col>
                  <Col>
                    <Label for="name" className="lableCategory">
                      Meta Keyword:
                    </Label>
                    <Input
                      type="text"
                      name="metaKeyword"
                      placeholder={`Enter Meta Keyword `}
                      values={formik.values.metaKeyword}
                      onChange={formik.handleChange}
                    />
                  </Col>
                </Row>
                <Row form>
                  <Col>
                    <Label for="name" className="lableCategory">
                      Meta Keyword Hindi:
                    </Label>
                    <Input
                      type="text"
                      name="metaKeywordHi"
                      placeholder={`Enter Meta Key Word Hindi`}
                      value={formik.values.metaKeywordHi}
                      onChange={formik.handleChange}
                    />
                  </Col>
                  <Col>
                    <Label for="name" className="lableCategory">
                      Meta Og Url Name:
                    </Label>
                    <Input
                      type="name"
                      name="metaOgUrl"
                      placeholder={`Enter Meta Og Url`}
                      value={formik.values.metaOgUrl}
                      onChange={formik.handleChange}
                    />
                  </Col>
                </Row>
                <Row form>
                  <Col>
                    <Label for="name" className="lableCategory">
                      {" "}
                      Meta Og Url Hindi:
                    </Label>
                    <Input
                      type="name"
                      name="metaOgUrlHi"
                      placeholder={` Enter Meta Og Url Hindi`}
                      value={formik.values.metaOgUrlHi}
                      onChange={formik.handleChange}
                    />
                  </Col>

                  <Col>
                    <Label for="name" className="lableCategory">
                      Meta Robots:
                    </Label>
                    <Input
                      type="text"
                      name="metaRobots"
                      placeholder={`Enter Meta Robots `}
                      value={formik.values.metaRobots}
                      onChange={formik.handleChange}
                    />
                  </Col>
                </Row>
                <Row form>
                  <Col>
                    <Label for="name" className="lableCategory">
                      Meta Og Image:
                    </Label>
                    <Input
                      type="name"
                      name="metaOgImage"
                      placeholder={`Enter Meta Og Image`}
                      value={formik.values.metaOgImage}
                      onChange={formik.handleChange}
                    />
                  </Col>
                </Row>
              </div>
            </div>
            <Button
              style={{
                display: "inline-block",
                width: "200px",
                float: "right",
                marginTop: "15px",
                background: "linear-gradient(180deg, #DD2A12 0%, #FFA214 100%)",
              }}
              type="submit"
            >
              Add Live Category
            </Button>
          </form>
        </section>
      </>
    );
  } else {
    window.location.href = "/";
  }
}

export default Index;
