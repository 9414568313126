import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faImage } from "@fortawesome/free-solid-svg-icons";
import Data from '../../../../Data';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import axios from 'axios';
import { useState } from 'react';
const url = process.env.REACT_APP_BASE_URL


export default function FullWidthGrid(props) {
  const [festivals, setFestivals] = useState([])
  const history = useNavigate();

  // const token = localStorage.getItem("token");
  const FestivalMonthId = props.data
  const token = `Bearer ${localStorage.getItem("token")}`

  const getContent = async (data,language,basic) => {
    await axios.get(
      url+`/pg/devotion/rituals-post/${data.id}?language=${language}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          Accept: "application/json",
          "ngrok-skip-browser-warning": "any",
          "Content-Type": "application/json",
        },
      }
    ).then((result) => {
      if(basic){
        history("/basic-details", {
          state: {
            data: data,
            language: language,
            pageTitle:"Rituals",
            type:3
          },
        });
      }else{history("/text-editor", {
        state: {
          data: data,
          language: language,
          content : result.data.data,
          type : 3
        },
      });}
    });
  }

  const FestivalByMonth = async () => {
    await axios.get(url + `/pg/devotion/get-all-rituals-post/${FestivalMonthId}`, {
      params: {
        language: 'en'

      },
      headers: {
        'authorization': token,
        'Accept': 'application/json',
        "ngrok-skip-browser-warning": "any",
        'Content-Type': 'application/json'
      }
    }).then((res) => {
      setFestivals(res.data.data)
    }).catch((err) => {
      console.log("Error::::::::", err)
    })
  }
  useEffect(() => {
    FestivalByMonth()
  }, [])

  if (token && token != 'undefined' ) {
    return (
      <div className='postCountIII ' >
        <div className="grid-container">
          {festivals && festivals.length > 0 && festivals.map((data, index) => {
            return <div key={index} style={{ backgroundImage: `url(${data.image})` ,backgroundSize: "cover",
            backgroundPosition: "center center",boxShadow:"inset 0px 0px 0px 200px rgba(0,0,0,0.25)"}} >
              {/* onClick={() => { subCat(data) }} */}
              <div style={{position:"relative",height:"100%"}}>
              <span className='imageAdd'><FontAwesomeIcon icon={faImage} size='sm' style={{color:"white"}} className='imageAddIcon' /></span>
              {/* <div className='buttonContainer buttonContainerInline'>
                <div> */}
                  <p className='span' style={{ color: 'white' }}>{data.title_en} 
                  {/* <FontAwesomeIcon icon={faPen} className='icon' /> */}
                  </p>
                  {/* <p className='spanI'>{data.color} <FontAwesomeIcon icon={faPen} className='icon' /></p> */}
                  
                {/* </div> */}
                {/* <div className='box'>
                  <div><button className='box1'>Save</button></div>
                  <div><button className='box2'>Special</button></div>
                  <div><button className='box3'>Disable</button></div>
                </div> */}
              {/* </div> */}
              <div style={{position:"absolute",width:"100%",bottom:"0px"}}>
              <button className='boxbutton' onClick={() => {getContent(data,"en")}}>English</button>
              <button className='boxbutton' onClick={() => {getContent(data,"hi")}}>hindi</button> 
               <button className='boxbutton' onClick={() => {getContent(data,"en","basic")}}>Basic</button>
              </div>
              </div>
            </div>

          })}
        </div>
      </div>

    );
  } else {
    window.location.href = '/'
  }
}
