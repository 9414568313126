import React, { useState, useEffect } from "react";
import { pilgrim_tour_details_By_tour_id, updated_package_details } from "../../../store/the-pilgrim-tours";
import { useLocation, useNavigate } from "react-router-dom";
import { error } from "jquery";

const TourDetails = () => {

    const router = useNavigate();
    const history = useLocation();

    const [tour, setTour] = useState(history.state ? history.state : { packageId: history.pathname.split("/")[2] });

    function handleChange(event) {
        const { name, value } = event.target;
        setTour((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    async function handleSubmit(e) {
        e.preventDefault();
        console.log("result", tour)
        const response = await updated_package_details(tour, tour.packageId);
        if (response.status === 200) {
            console.log("successfully updated package details")
        } else {
        }
    };

    async function getTourDetailsByTourId() {
        const response = await pilgrim_tour_details_By_tour_id(tour.packageId);
        if (response.status === 200) {
            setTour(response.data.data);
        } else {
        }
    }

    useEffect(() => {
        if (!tour.slug) getTourDetailsByTourId();
    }, [history]);

    console.log("tour", tour, history);


    return (
        <section className="postCountIV" style={{ overflowY: "scroll", marginTop: "12px" }} >

            <div>TourDetails</div>
            <div>{tour.title}</div>
            {<form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label for="exampleInputEmail1">Title</label>
                    <input type="text" className="form-control" id="title" aria-describedby="emailHelp" placeholder="title" />
                </div>
                <div className="form-group">
                    <label for="exampleInputEmail1">Slug</label>
                    <input
                        required
                        className="form-control"
                        placeholder="Enter Slug"
                        name="slug"
                        value={tour.slug}
                        onChange={handleChange}
                        type="text"
                    />
                </div>
                <div className="form-group">
                    <label for="exampleInputPassword1">Discount Price</label>
                    <input type="number" className="form-control" id="discountPrice" placeholder="discountPrice" />
                </div>
                <button type="submit" className="btn btn-warning">Submit</button>
            </form>}

        </section>
    );
};

export default TourDetails;
