import React, { useEffect, useState, useRef } from "react";
import { faFilter, faMagnifyingGlass, faCircleCheck, } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import style from "./PilgrimUsers.module.css";
import { pilgrim_user_accounts_api, pilgrim_Search_api, } from "../../../store/the-pilgrim-tours";

const PilgrimUser = () => {

  const accountType = ["All", "Pending", "Active", "Suspended"];
  const [filter, setFilter] = useState("");
  const [userType, setUsertype] = useState("All");
  const [filterBox, setOpenFIlterBox] = useState(false);
  const [data, setData] = useState([]);
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]); // Stores the search results

  const debounceTimeout = useRef(null);

  const getUserAccountsData = async () => {
    const response = await pilgrim_user_accounts_api(filter);
    // console.log("response accounts ", response);
    if (response.status === 200) {
      setData(response.data.data);
    }
  };

  const handleSearch = async (search) => {
    const SearchApiResult = await pilgrim_Search_api(search);

    if (SearchApiResult.status === 200 && search.length !== 0) {
      setData(SearchApiResult.data.data)
    } else {
      getUserAccountsData("All")
    }
    // console.log(SearchApiResult);
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setQuery(value);
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    debounceTimeout.current = setTimeout(() => {
      handleSearch(event.target.value);
    }, 500);
  };

  // useEffect
  useEffect(() => {
    getUserAccountsData(filter);
  }, [userType]);

  return (
    <div>
      <div className={style.tableheader}>
        <h3>Pilgrim Users</h3>
        <div className={style.filterandsearch}>
          <FontAwesomeIcon
            icon={faFilter}
            style={{ cursor: "pointer" }}
            onClick={() => {
              setOpenFIlterBox(!filterBox);
            }}
          />
          {filterBox && (
            <div className={style.filterbox}>
              <ul>
                {accountType.map((each) => {
                  return (
                    <li
                      onClick={() => {
                        setUsertype(each);
                        setFilter(each === "All" ? "" : each);
                        setOpenFIlterBox(false);
                      }}
                      className={each === userType && style.activeAccountType}
                    >
                      <FontAwesomeIcon
                        color={each === userType ? "green" : "grey"}
                        icon={faCircleCheck}
                      />
                      <span>{each}</span>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
          <div className={style.inputBox}>
            <input
              ref={debounceTimeout}
              value={query}
              onChange={handleChange}
              placeholder="Search for user.."
            ></input>
            <FontAwesomeIcon icon={faMagnifyingGlass} />
          </div>
        </div>
      </div>
      <table className={style.contentTable}>
        <thead>
          <tr>
            <th>Sr. no</th>
            <th>Name</th>
            <th>Number</th>
            <th>Email</th>
            <th>Login Type</th>
            <th>Number Verified</th>
            <th>Mail Verified</th>
            <th>Kyc Status</th>
            <th>DOB</th>
            <th>Account Status</th>
          </tr>
        </thead>
        {!data.length ? (
          <tbody>
            <tr className={style.emptyRow}>
              <td colspan="10" style={{textAlign:'center'}}>{"No user available"}</td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {data.map((each, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{each.name ? each.name : "Not-Available"}</td>
                  <td>
                    {each.mobileNumber ? each.mobileNumber : "Not-Available"}
                  </td>
                  <td>{each.email ? each.email : "Not-Available"}</td>
                  <td>{each.loginType}</td>
                  <td>{each.isNumberVerified ? "Yes" : "No"}</td>
                  <td>{each.isMailVerified ? "Yes" : "No"}</td>
                  <td>{each.isKycDone ? "Yes" : "No"}</td>
                  <td>{each.Dob ? each.Dob : "Not-Available"}</td>
                  <td>{each.accountStatus}</td>
                </tr>
              );
            })}
          </tbody>
        )}
      </table>
    </div>
  );
};

export default PilgrimUser;