import React from "react";
import axios from "axios";
import Tabs from "../Tabs/Tabs";
import Category from "./Tabs/Category";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
const URL = process.env.REACT_APP_BASE_URL;

const ReportList = () => {
  const [category, setAstrologyCategory] = React.useState(null);

  const getCategory = async () => {
    axios
      .get(`${URL}/category/get-list-of-parent-categories`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "any",
        },
      })
      .then((res) => {
        setAstrologyCategory(res.data.data);
      });
  };

  React.useEffect(() => {
    getCategory();
  }, []);
  if (!category) return null;

  return (
    <section className="postCountIV categories-phn">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "5px",
          padding: "0 1rem",
        }}
      >
        <div>
          <span className="lableCategory">All Category</span>
        </div>
        <div>
          <Link to="/add-category">
            <button className="addCategoryButton">
              Add Category <FontAwesomeIcon icon={faAngleDoubleRight} />
            </button>
          </Link>
        </div>
      </div>
      <Tabs>
        {category.map((data, index) => {
          return (
            <div key={index} label={data.name}>
              <Category data={data.id} />
            </div>
          );
        })}
      </Tabs>
    </section>
  );
};

export default ReportList;
