import React, { useState } from "react";
import Forms from "../../CommonComponents/Forms";
import { useFormik } from "formik";
import { sattvik_editHotels } from "../../../../store/sattvik-stays";
import { Alert } from "reactstrap";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
const AddCityForm = () => {
  const Navigate = useNavigate("");
  const Location = useLocation("");

  const heading = "Edit Hotel Details";
  const [active, setActive] = useState(4);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const handleEditHotel = async (data) => {
    const formData = new FormData();
    for (let key in data) {
      formData.append(key, data[key]);
    }
    const addApiResponse = await sattvik_editHotels({}, formData);
    
    if (addApiResponse.data && addApiResponse.data.status == "success") {
      setSuccess("Hotel Edited Successfully");
      setTimeout(() => {
        setSuccess("");
        Navigate("/sattvik-stays/hotels");
      }, 2000);
    } else {
      setError(addApiResponse.response.data.msg);
      setTimeout(() => {
        setError("");
      }, 2000);
    }
  };
  const formik = useFormik({
    initialValues: {
      hotelName: Location.state.data.name,
      hotelImage: Location.state.data.image,
      address: Location.state.data.address,
      discountPrice: Location.state.data.discountPrice,
      price: Location.state.data.price,
      userRating: Location.state.data.userRating,
      category: Location.state.data.category,
      cityId: Location.state.data.cityDetails.cityId,
      city: Location.state.data.cityDetails.city,
      popular: Location.state.data.popular,
      isActive: Location.state.data.isActive,
    },

    onSubmit: (values) => {
      const data = {
        hotelName: values.hotelName,
        address: values.address,
        discountPrice: values.discountPrice,
        price: values.price,
        userRating: values.userRating,
        image: values["city-image"]?values["city-image"]:values.hotelImage,
        hotelId: Location.state.data.hotelId,
        isActive: values.isActive,
        popular: values.popular,
      };
      handleEditHotel(data);
    
    },
  });

  return (
    <div>
      <div className="row pt-4 d-flex justify-content-center">
        {success && (
          <div className="col-lg-6">
            <Alert
              className="bg-bs-success-bg-subtle"
              style={{
                color: "black",
              }}
            >
              {success}
            </Alert>
          </div>
        )}
        {error && (
          <div className="col-lg-6">
            <Alert
              style={{
                background: "rgb(251, 99, 71)",
                color: "white",
              }}
            >
              {error}
            </Alert>
          </div>
        )}
      </div>
      <Forms
        active={active}
        formik={formik}
        heading={heading}
        formData={formik.values}
        handleChange={formik.handleChange}
        handleSubmit={formik.handleSubmit}
      />
    </div>
  );
};

export default AddCityForm;
