import React, { useEffect, useState } from 'react'
import Forms from '../../CommonComponents/Forms'
import {
  sattvik_addCity,
  sattvik_get_all_state
} from '../../../../store/sattvik-stays'
import { useFormik } from 'formik'
import { Alert } from 'reactstrap'
import { useNavigate } from 'react-router-dom'
const AddCityForm = () => {
  const heading = 'Add New City'
  const [active, setActive] = useState(1)
  const [state, setState] = useState([])
  const Navigate = useNavigate('')
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const handleAddCity = async data => {
    const formData = new FormData()
    for (let key in data) {
      formData.append(key, data[key])
    }
    const addApiResponse = await sattvik_addCity({}, formData)

    if (addApiResponse.data && addApiResponse.data.status == 'success') {
      setSuccess('Hotel Added Successfully')
      setTimeout(() => {
        setSuccess('')
        Navigate('/sattvik-stays/cities')
      }, 2000)
    } else {
      setError(addApiResponse.response.data.msg)
      setTimeout(() => {
        setError('')
      }, 2000)
    }
  }
  const formik = useFormik({
    initialValues: {
      cityName: '',
      slug: '',
      stateId: ''
    },

    onSubmit: values => {
      const data = {
        cityName: values.cityName,
        stateId: values.stateId,
        image: values['city-image'],
        banner: values['banner-image'],
        slug: values.slug
      }
      handleAddCity(data)
    }
  })

  const getAllState = async () => {
    const response = await sattvik_get_all_state()
    if (response.data.statusCode == 200) setState(response.data.data)
  }

  useEffect(() => {
    getAllState()
  }, [])

  return (
    <div>
      <div className='row pt-4 d-flex justify-content-center'>
        {success && (
          <div className='col-lg-6'>
            <Alert
              className='bg-bs-success-bg-subtle'
              style={{
                color: 'black'
              }}
            >
              {success}
            </Alert>
          </div>
        )}
        {error && (
          <div className='col-lg-6'>
            <Alert
              style={{
                background: 'rgb(251, 99, 71)',
                color: 'white'
              }}
            >
              {error}
            </Alert>
          </div>
        )}
      </div>
      <Forms
        state={state}
        active={active}
        formik={formik}
        heading={heading}
        formData={formik.values}
        handleChange={formik.handleChange}
        handleSubmit={formik.handleSubmit}
      />
    </div>
  )
}

export default AddCityForm
