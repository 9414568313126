import React from 'react'
import style from '../SattvikStaysAddForm.module.css'

const CityForm = props => {
  const { heading, handleChange, handleSubmit, formData, formik, state } = props
  return (
    <form className={style.formBox} onSubmit={handleSubmit}>
      <h2>{heading}</h2>
      <div className={style.inputBoxParent}>
        <div className={style.inputBox}>
          <div>
            <p>City Name</p>
            <input
              className='w-full'
              value={formik.values.cityName}
              name='cityName'
              placeholder='Enter your city name'
              required
              type='text'
              onChange={handleChange}
            ></input>
          </div>
          <div>
            <p>State Name</p>
            <select
              name='stateId'
              // value={formik.values.stateName}
              onChange={handleChange}
              style={{
                width: '100%',
                paddingTop: '6px',
                paddingBottom: '6px',
                marginTop: '2px',
                border: '1px solid gray',
                borderRadius: '3px',
                color: 'gray'
              }}
            >
              <option>Select State</option>
              {state.length > 0 && state.map(e => <option value={e.stateId}>{e.name}</option>)}
            </select>
          </div>
          <div>
            <p>Slug</p>
            <input
              className='w-full'
              value={formik.values.slug}
              name='slug'
              placeholder='Enter your city unique slug'
              required
              type='text'
              onChange={handleChange}
            ></input>
          </div>
        </div>
        <div className={`${style.inputBox}`}>
          <div>
            <p>Image</p>
            <input
              type={'file'}
              name='city-image'
              required
              onChange={e => {
                formik.setFieldValue('city-image', e.currentTarget.files[0])
              }}
            />
          </div>
          <div>
            <p>Banner Image</p>
            <input
              type={'file'}
              required
              name='banner-image'
              onChange={e => {
                formik.setFieldValue('banner-image', e.currentTarget.files[0])
              }}
            />
          </div>
        </div>
        <div className={style.submitBtn}>
          <button type='submit'>Create</button>
        </div>
      </div>
    </form>
  )
}

export default CityForm
