import React from 'react'
import { useState, useEffect } from 'react'
import {
  sattvik_get_hotel_enquery,
  sattvik_user_enqiries
} from '../../../store/sattvik-stays'
import PropertyCard from './PropertyCard'
import UserQuery from './UserQuery'
const index = () => {
  const [loaderUser, setLoaderUser] = useState(false)
  const [loaderList, setLoaderList] = useState(false)
  const [enquiries, setEnquiries] = useState([])
  const [msg, setMsg] = useState('')
  const [noData, setNodata] = useState(false)
  const [enquiriesList, setEnquiriesList] = useState([])
  const [active, setActive] = useState(1)
  const getEnquiry = async () => {
    setNodata(false)
    setMsg('')
    setLoaderUser(true)
    setEnquiries([])
    const response = await sattvik_user_enqiries()
    if (response.data.status == 'success') {
      setLoaderUser(false)
      setEnquiries(response.data.data)
      if (!response.data.data.length) {
        setNodata(true)
        setMsg('No User Enqueries found!!!')
      }
    }
  }

  const getListEnquiry = async () => {
    setNodata(false)
    setMsg('')
    setLoaderList(true)
    setEnquiriesList([])
    const response = await sattvik_get_hotel_enquery()
    if (response.data.status == 'success') {
      setLoaderList(false)
      setEnquiriesList(response.data.data)
      if (!response.data.data.length) {
        setNodata(true)
        setMsg('No Property Listing Enqueries found!!!')
      }
    }
  }
  useEffect(() => {
    if (active == 1) getEnquiry()
    if (active == 2) getListEnquiry()
  }, [active])
  return (
    <div>
      <div style={{ display: 'flex', padding: '20px 10px', gap: '20px' }}>
        <div
          onClick={() => setActive(1)}
          style={
            active == 1
              ? {
                  border: '1px solid gray',
                  borderRadius: '6px',
                  padding: '5px 10px',
                  backgroundColor: '#FFA214',
                  cursor: 'pointer',
                  fontWeight: '500',
                  color: 'white'
                }
              : {
                  border: '1px solid gray',
                  borderRadius: '6px',
                  padding: '5px 10px',
                  cursor: 'pointer',
                  fontWeight: '500'
                }
          }
        >
          User Enquery
        </div>
        <div
          onClick={() => setActive(2)}
          style={
            active == 2
              ? {
                  border: '1px solid gray',
                  borderRadius: '6px',
                  padding: '5px 10px',
                  backgroundColor: '#FFA214',
                  cursor: 'pointer',
                  fontWeight: '500',
                  color: 'white'
                }
              : {
                  border: '1px solid gray',
                  borderRadius: '6px',
                  padding: '5px 10px',
                  cursor: 'pointer',
                  fontWeight: '500'
                }
          }
        >
          Hotel Listing Enquery
        </div>
      </div>
      {loaderUser && <div>Loader...</div>}
      {loaderList && <div>Loader...</div>}
      {noData && <div>{msg}</div>}
      {active == 1 ? (
        <UserQuery data={enquiries} />
      ) : (
        <PropertyCard data={enquiriesList} />
      )}
    </div>
  )
}

export default index

// <div
//   style={{
//     border: '2px solid gray',
//     borderRadius: '10px',
//     padding: '12px',
//     width: '49.5%',
//     color: 'black',
//     textTransform: 'capitalize'
//   }}
// >
//   <div
//     style={{
//       display: '',
//       justifyContent: 'space-between',
//       alignItems: 'center'
//     }}
//   >
//     <div style={{ fontWeight: '500', fontSize: '18px' }}>
//       Owner Details:
//     </div>
//     <div style={{ display: 'flex' }}>
//       <div>
//         <span style={{ fontWeight: '500' }}>Name:</span>{' '}
//         {e.ownerName ? e.ownerName : 'User'}
//       </div>
//       <div style={{ textTransform: 'lowercase' }}>
//         <span style={{ fontWeight: '500' }}>Email:</span>{' '}
//         {e.email ? e.email : 'abc@gmail.com'}
//       </div>
//       <div>
//         <span style={{ fontWeight: '500' }}>Mobile Number:</span>{' '}
//         {e.number ? e.number : '+91-1234567890'}
//       </div>
//     </div>
//     <div className='text-xs'>{/* <b>Status:</b> Pending */}</div>
//   </div>
//   <div>
//     <div style={{ fontWeight: '500', fontSize: '18px' }}>
//       Property Details:
//     </div>
//     <div>
//       <div>Name: {e.hotelName}</div>
//       <div>Category: {e.category}</div>
//       <div>
//         Address: {e.address}, {e.city}, {e.state}
//       </div>
//       <div>Description: {e.description}</div>
//     </div>
//   </div>
// </div>
