import React, { useState } from "react";
import Editor from "../../Blog/BlogContent/index";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { Input, Alert } from "reactstrap";
import Select from "react-select";
const index = () => {
  // token
  const token = localStorage.getItem("token");

  // useLocation and usenavigate
  const history = useLocation();
  const navigate = useNavigate();

  // state
  const [selmonth, setselmonth] = useState(history.state.month);
  const url = process.env.REACT_APP_BASE_URL;
  const [contentEn, setContentEn] = useState(false);
  const [contentHi, setContentHi] = useState(false);
  const [visible, setVisible] = useState(true);
  const [stage2, setStage2] = useState(false);
  const [formdata, setformdata] = useState({
    nameEn: "",
    nameHi: "",
    slug: "",
    month: "",
    descriptionEn: "",
    descriptionHi: "",
    image: "",
    festivalDate: "",
  });
  const [formdata2, setformdata2] = useState({
    titleEn: "",
    titleHi: "",
    contentEn: "",
    contentHi: "",
    festivalSubCategoryImage: "",
  });
  const [isSuccess, setIsSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState("");
  let months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const defaultvalue = {
    value: history.state.month,
    label: months[history.state.month - 1],
  };
  const selectOption = [
    { value: "1", label: "January" },
    { value: "2", label: "February" },
    { value: "3", label: "March" },
    { value: "4", label: "April" },
    { value: "5", label: "May" },
    { value: "6", label: "June" },
    { value: "7", label: "July" },
    { value: "8", label: "August" },
    { value: "9", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];
  const days = {
    1: 31,
    2: 29,
    3: 31,
    4: 30,
    5: 31,
    6: 30,
    7: 31,
    8: 31,
    9: 30,
    10: 31,
    11: 30,
    12: 31,
  };

  // functions
  const onDismiss = () => setVisible(false);
  const handleupdate = (e) => {
    const { name, value } = e.target;
    if (name == "image") {
      setformdata((pre) => ({
        ...pre,
        [name]: e.target.files[0],
      }));
    } else {
      setformdata((pre) => ({
        ...pre,
        [name]: value,
      }));
    }
  };
  const handleupdate2 = (e) => {
    const { name, value } = e.target;
    setformdata2((pre) => ({
      ...pre,
      [name]: value,
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      nameHi: formdata.nameHi,
      nameEn: formdata.nameEn,
      slug: formdata.slug,
      descriptionEn: formdata.descriptionEn,
      descriptionHi: formdata.descriptionHi,
      imageUrl: formdata.imageUrl,
      month: selmonth,
      festivalDate: formdata.festivalDate,
    };
    setStage2(true);
  };
  const handleSubmit2 = (e) => {
    e.preventDefault();
    var date = new Date(formdata.festivalDate);
    var day = date.getDate();
    const data = {
      nameHi: formdata.nameHi,
      nameEn: formdata.nameEn,
      slug: formdata.slug,
      descriptionEn: formdata.descriptionEn,
      descriptionHi: formdata.descriptionHi,
      image: formdata.image,
      month: selmonth,
      parentId: history.state.id,
      festivalDate: day,
      titleHi: formdata2.titleHi,

      contentEn: formdata2.contentEn,
      contentHi: formdata2.contentHi,
      titleEn: formdata2.titleEn,
    };
    setStage2(true);
    const fetchdata = new FormData();

    for (let key in data) {
      fetchdata.append(key, data[key]);
    }
    axios
      .post(`${url}/admin/add-festival-sub-category`, fetchdata, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "any",
        },
      })
      .then((res) => {
        // console.log(res, "this is res");
        if (res) {
          setIsSuccess(true);
          setMessage("Festival Sub category added Successfully");
          setTimeout(() => {
            setIsSuccess(false);
            setMessage("");
            navigate(-1);
          }, 3000);
        }
      })
      .catch((Err) => {
        setIsError(true);
        setError(Err.response.data.msg);
        setTimeout(() => {
          setIsError(false);
          setError("");
        }, 3000);
      });
  };
  function createArray(N) {
    const newArr = Array.from({ length: N }, (_, index) => index + 1);
    const newoptions = newArr.map((each) => {
      const result = {
        value: each,
        label: each,
      };
      return result;
    });
    return newoptions;
  }

  if (token && selmonth) {
    return (
      <>
        {contentEn && (
          <Editor
            setContentEn={setContentEn}
            setContentHi={setContentHi}
            festival={true}
            setformdata2={setformdata2}
            festtype="en"
          />
        )}
        {contentHi && (
          <Editor
            festival={true}
            setformdata2={setformdata2}
            setContentEn={setContentEn}
            setContentHi={setContentHi}
            festtype="hi"
          />
        )}
        {!(contentEn || contentHi) && (
          <div>
            <div className="alert-side">
              {isSuccess && (
                <Alert color="info" isOpen={visible} toggle={onDismiss}>
                  {message}
                </Alert>
              )}
            </div>
            <div className="alert-side">
              {" "}
              {isError && (
                <Alert
                  style={{
                    background: "rgb(251, 99, 71)",
                    color: "white",
                  }}
                  isOpen={visible}
                  toggle={onDismiss}
                >
                  {error}
                </Alert>
              )}
            </div>
            {!stage2 && (
              <p className="name-add">
                Add new festival sub category in {history.state.name_en}
              </p>
            )}
            {!stage2 && (
              <form onSubmit={handleSubmit}>
                <div className="form">
                  <div className="each-input">
                    <label>Name English*</label>
                    <input
                      required
                      type="text"
                      value={formdata.nameEn}
                      name="nameEn"
                      onChange={handleupdate}
                      placeholder="add festival name in English"
                    ></input>
                  </div>

                  <div className="each-input">
                    <label>Name Hindi*</label>
                    <input
                      required
                      value={formdata.nameHi}
                      name="nameHi"
                      onChange={handleupdate}
                      type="text"
                      placeholder="add festival name in Hindi"
                    ></input>
                  </div>

                  <div className="each-input">
                    <label>Slug*</label>
                    <input
                      required
                      value={formdata.slug}
                      name="slug"
                      onChange={handleupdate}
                      type="text"
                      placeholder="Cretae a unique slug"
                    ></input>
                  </div>

                  <div className="each-input">
                    <label>Description English</label>
                    <input
                      value={formdata.descriptionEn}
                      name="descriptionEn"
                      onChange={handleupdate}
                      placeholder="add description in English"
                      type="text"
                    ></input>
                  </div>

                  <div className="each-input">
                    <label>Description Hindi</label>
                    <input
                      value={formdata.descriptionHi}
                      name="descriptionHi"
                      onChange={handleupdate}
                      placeholder="add description in Hindi"
                      type="text"
                    ></input>
                  </div>

                  <div className="each-input">
                    <label>Month*</label>
                    <Select
                      id="slect-month"
                      defaultValue={defaultvalue}
                      isDisabled={true}
                      options={selectOption}
                    />
                  </div>

                  <div className="each-input">
                    <label>Festival Date*</label>

                    <Select
                      id="slect-month"
                      required
                      options={createArray(days[defaultvalue.value])}
                      name="festivalDate"
                      onChange={(e) => {
                        setformdata((pre) => ({
                          ...pre,
                          ["festivalDate"]: e.value,
                        }));
                      }}
                    />
                  </div>

                  <div className="each-input">
                    <label>Image url*</label>
                    <Input type={"file"} name="image" onChange={handleupdate} />
                  </div>
                </div>
                <div className="add-f-parent2">
                  <button type="submit" className="add-festivel">
                    Next
                  </button>
                </div>
              </form>
            )}
            {stage2 && (
              <p className="name-add">
                Add new festival sub category in {history.state.name_en}
              </p>
            )}
            {stage2 && (
              <form onSubmit={handleSubmit2}>
                <div className="form">
                  <div className="each-input">
                    <label>Title En*</label>
                    <input
                      required
                      type="text"
                      value={formdata2.titleEn}
                      name="titleEn"
                      onChange={handleupdate2}
                      placeholder="add title in English"
                    ></input>
                  </div>
                  <div className="each-input">
                    <label>Title Hi*</label>
                    <input
                      required
                      value={formdata2.titleHi}
                      name="titleHi"
                      onChange={handleupdate2}
                      type="text"
                      placeholder="add title in Hindi"
                    ></input>
                  </div>
                  <div
                    className="each-input"
                    onClick={() => {
                      if (formdata2.contentEn.length > 0) {
                        return "";
                      } else {
                        setContentEn(true);
                      }
                    }}
                  >
                    <label>Content En*</label>
                    <input
                      required
                      disabled={formdata2.contentEn.length > 0 ? true : false}
                      value={formdata2.contentEn}
                      name="contentEn"
                      placeholder="add content in English"
                      type="text"
                    ></input>
                  </div>
                  <div
                    className="each-input"
                    onClick={() => {
                      if (formdata2.contentHi.length > 0) {
                        return "";
                      } else {
                        setContentHi(true);
                        setContentEn(false);
                      }
                    }}
                  >
                    <label>Content Hi*</label>
                    <input
                      required
                      disabled={formdata2.contentHi.length > 0 ? true : false}
                      value={formdata2.contentHi}
                      name="contentHi"
                      placeholder="add content in Hindi"
                      type="text"
                    ></input>
                  </div>{" "}
                  <div className="each-input">
                    <label>Month*</label>
                    <Select
                      id="slect-month"
                      defaultValue={defaultvalue}
                      isDisabled={true}
                      options={selectOption}
                    />
                  </div>
                  <div className="each-input">
                    <label>Slug*</label>
                    <input
                      style={{ background: "#f2f2f2" }}
                      required
                      value={formdata.slug}
                      disabled={true}
                      placeholder="slug"
                      type="text"
                    ></input>
                  </div>
                </div>
                <div className="add-f-parent2">
                  <button
                    onClick={() => {
                      setStage2(false);
                    }}
                    className="add-festivel"
                  >
                    back
                  </button>

                  <button type="" className="add-festivel">
                    submit
                  </button>
                </div>
              </form>
            )}
          </div>
        )}
      </>
    );
  } else {
    window.location.href = "/";
  }
};

export default index;
