import React, { useState } from "react";
import { useFormik } from "formik";
import axios from "axios";
import { Label, Input, Button, Form, Row, Col, Alert } from "reactstrap";
const url = process.env.REACT_APP_BASE_URL;

function Index() {
  // token
  const token = localStorage.getItem("token");
  
  // states
  const [visible, setVisible] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  // functions
  const onDismiss = () => setVisible(false);
  const addCatagory = (values) => {
    axios
      .post(`${url}/add-new-chant-life-post`, values, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "ngrok-skip-browser-warning": "any",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((result) => {
        if (result.status == "200") {
          setIsSuccess(true);

          setMessage("Successfully Add Catagory");

          setTimeout(() => {
            setIsSuccess(false);
            setMessage("");
          }, 4000);
        }
        formik.resetForm();
      })
      .catch((err) => {
        console.log("Error::::::::", err);
        setIsError(true);
        setMessage("Already Exist Entry !");
        setError("Something went wrong. Try again");
        setTimeout(() => {
          setIsError(false);
          setMessage("");
        }, 4000);
      });
  };
  const formik = useFormik({
    initialValues: {
      id: "",
      titleEn: "",
      titleHi: "",
      categoryId: "",
      videoId: "",
      mantraEn: "",
      mantraHi: "",
      image: "",
      contentEn: "",
      contentHi: "",
      audioUrl: "",
      status: "",
      isPopular: "",
    },
    onSubmit: (values) => {
      addCatagory(values);
    },
  });


  if (token && token != "undefined") {
    return (
      <>
        <section className="postCount" style={{ overflow: "scroll" }}>
          <Form
            onSubmit={formik.handleSubmit}
            style={{ padding: "10px 20px 0px 20px" }}
          >
            <div className="row">
              <div className="col-lg-6">
                {isSuccess && (
                  <Alert color="info" isOpen={visible} toggle={onDismiss}>
                    {message}
                  </Alert>
                )}
              </div>
            </div>
            <div
              style={{
                width: "100%",
                textAlign: "center",
                marginBottom: "40px",
                fontSize: "40px",
              }}
            >
              <h2>Add New Post</h2>
            </div>
            <div className="col-lg-12">
              <div className="row">
                <Row form>
                  <Col>
                    <Label for="name" className="lableCategory">
                      {" "}
                      ID:
                    </Label>
                    <Input
                      type="text"
                      name="id"
                      values={formik.values.id}
                      placeholder={`Enter ID`}
                      onChange={formik.handleChange}
                    />
                  </Col>
                  <Col>
                    <Label for="name" className="lableCategory">
                      Title English:
                    </Label>
                    <Input
                      type="text"
                      name="titleEn"
                      placeholder={`Enter Title English`}
                      values={formik.values.titleEn}
                      onChange={formik.handleChange}
                    />
                  </Col>
                </Row>
                <Row form>
                  <Col>
                    <Label for="name" className="lableCategory">
                      Title Hindi:
                    </Label>
                    <Input
                      type="text"
                      name="titleHi"
                      placeholder={`Enter titleHi`}
                      values={formik.values.titleHi}
                      onChange={formik.handleChange}
                    />
                  </Col>
                  <Col>
                    <Label for="name" className="lableCategory">
                      Category Id:
                    </Label>
                    <Input
                      type="text"
                      name="categoryId"
                      required
                      placeholder={`Enter category Id`}
                      values={formik.values.categoryId}
                      onChange={formik.handleChange}
                    />
                  </Col>
                </Row>
                <Row form>
                  <Col>
                    <Label for="name" className="lableCategory">
                      Mantra English:
                    </Label>
                    <Input
                      type="text"
                      name="mantraEn"
                      placeholder={`Enter mantra en`}
                      value={formik.values.mantraEn}
                      onChange={formik.handleChange}
                    />
                  </Col>
                  <Col>
                    <Label for="name" className="lableCa tegory">
                      Mantra Hindi:
                    </Label>
                    <Input
                      type="name"
                      name="mantraHi"
                      placeholder={`mantra hi`}
                      value={formik.values.mantraHi}
                      onChange={formik.handleChange}
                    />
                  </Col>
                </Row>
                <Row form>
                  <Col>
                    <Label for="name" className="lableCategory">
                      Image:
                    </Label>
                    <Input
                      type="text"
                      name="image"
                      placeholder={`Enter image`}
                      value={formik.values.image}
                      onChange={formik.handleChange}
                    />
                  </Col>
                  <Col>
                    <Label for="name" className="lableCategory">
                      Content English:
                    </Label>
                    <Input
                      type="name"
                      name="contentEn"
                      placeholder={` Enter contentEn`}
                      value={formik.values.contentEn}
                      onChange={formik.handleChange}
                    />
                  </Col>
                </Row>
                <Row form>
                  <Col>
                    <Label for="name" className="lableCategory">
                      Content Hindi:
                    </Label>
                    <Input
                      type="text"
                      name="contentHi"
                      placeholder={`Enter content hi`}
                      value={formik.values.contentHi}
                      onChange={formik.handleChange}
                    />
                  </Col>

                  <Col>
                    <Label for="name" className="lableCategory">
                      Audio Url:
                    </Label>
                    <Input
                      type="name"
                      name="audioUrl"
                      placeholder={` Enter audio_url`}
                      value={formik.values.audioUrl}
                      onChange={formik.handleChange}
                    />
                  </Col>
                </Row>
                <Row form>
                  <Col>
                    <Label for="radio" className="lableCategory">
                      Status:
                    </Label>
                    <br />
                    <Input
                      type="radio"
                      name="status"
                      id="radio"
                      value={true}
                      placeholder={`Change status `}
                      onChange={formik.handleChange}
                    />
                    {" True "}
                    <Input
                      type="radio"
                      name="status"
                      id="radio"
                      value={false}
                      placeholder={`Change status`}
                      onChange={formik.handleChange}
                    />
                    {" False "}
                  </Col>
                  <Col>
                    <Label for="radio" className="lableCategory">
                      {" "}
                      Is Popular:
                    </Label>
                    <br />
                    <Input
                      type="radio"
                      name="isPopular"
                      id="radio"
                      value={true}
                      placeholder={`Change  isPopular`}
                      onChange={formik.handleChange}
                    />
                    {" True "}
                    <Input
                      type="radio"
                      name="isPopular"
                      id="radio"
                      value={false}
                      placeholder={`Change isPopular`}
                      onChange={formik.handleChange}
                    />
                    {" False "}
                  </Col>
                </Row>
                <Row form>
                  <Col>
                    <Label for="name" className="lableCategory">
                      Slug:
                    </Label>
                    <Input
                      type="name"
                      name="slug"
                      placeholder={` Enter slug`}
                      value={formik.values.slug}
                      onChange={formik.handleChange}
                    />
                  </Col>
                </Row>
              </div>
            </div>

            <Button
              style={{
                display: "inline-block",
                width: "200px",
                float: "right",
                marginTop: "15px",
                background: "linear-gradient(180deg, #DD2A12 0%, #FFA214 100%)",
              }}
              type="submit"
            >
              <strong>Add Post Category </strong>
            </Button>
          </Form>
        </section>
      </>
    );
  } else {
    window.location.href = "/";
  }
}

export default Index;
