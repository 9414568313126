import React, { useState, useEffect } from "react";
import { pilgrim_tours_categories } from "../../../store/the-pilgrim-tours";
import { useNavigate } from "react-router-dom";
import style from "../pilgrimTours.module.css";
import AddButton from "../CommonComponents/AddButton";
const ToursCategories = () => {
  const router = useNavigate();

  const [categories, setCategories] = useState();

  async function getCategroies() {
    const response = await pilgrim_tours_categories();
    if (response.status === 200) {
      setCategories(response.data.data);
    } else {
    }
  }

  useEffect(() => {
    getCategroies();
  }, []);

  return (
    <section
      className="postCountIV"
      style={{ overflowY: "scroll", marginTop: "12px" }}
    >
      {categories &&
        categories.map((cate, cateIndex) => {
          return (
            <div key={cateIndex}>
              <div
                className={`d-flex align-items-center justify-content-between w-100`}
              >
                <p className={`mb-0 ${style.cateHead}`}>{cate.name}</p>
                {cateIndex === 0 && (
                  <div className="d-flex align-items-center justify-content-end gap-3">
                    <AddButton
                      link="/pilgrim-tours/add-categories"
                      name="Add New Sub Catgory"
                      subCat={true}
                      subCatoptions={categories.map((each) => {
                        const options = {
                          name: each.name,
                          value: each.categoryId,
                        };
                        return options;
                      })}
                    />
                    <AddButton
                      subCat={false}
                      link="/pilgrim-tours/add-categories"
                      name="Add New Catgory"
                    />
                  </div>
                )}
              </div>
              <div className="my-3 p-3 d-flex flex-wrap gap-2">
                {cate.subCategories &&
                  cate.subCategories.map((subCat, subCateIndex) => {
                    return (
                      <div
                        key={subCateIndex}
                        className={`card ${style.subCateBox}`}
                      >
                        {subCat.image && (
                          <img
                            class="card-img-top"
                            src={subCat.image}
                            alt="tours sub category image"
                          ></img>
                        )}
                        <div class="card-body">
                          <h5 class="card-title">{subCat.name}</h5>
                          <button
                            type="button"
                            class="btn btn-warning"
                            onClick={() => {
                              router(
                                `/pilgrim-tours/tours-categories/${subCat.categoryId}`,
                                { state: subCat }
                              );
                            }}
                          >
                            See Tours
                          </button>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          );
        })}
    </section>
  );
};

export default ToursCategories;
