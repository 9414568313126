import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FormGroup, Label, Input, Button, Form } from "reactstrap";
import { post } from "axios";
import AddCategoryForm1 from "../AddCategory/addCategoryForm";
import Tabs from "../../Tabs/Tabs";
const url = process.env.REACT_APP_BASE_URL;

const AddCategoryForm = () => {
  // token
  const token = `Bearer ${localStorage.getItem("token")}`;

  // states
  const addCategoryForm = async (values) => {
    try {
      const formdata = new FormData();
      for (let key in values) {
        formdata.append(key, values[key]);
      }
      const res = await post(url + "/category/add-sub-category", formdata, {
        headers: {
          Authorization: token,
          "ngrok-skip-browser-warning": "any",
        },
      });
    } catch (err) {
      console.log("Error::::::::", err);
    }
  };
  const formik = useFormik({
    initialValues: {
      name: "",
      color: "",
      image: "",
      parentId: "",
      is_special: false,
    },
    onSubmit: (values) => {
      addCategoryForm(values);
    },
    validationSchema: () =>
      Yup.object().shape({
        name: Yup.string()
          .required("Category name is required")
          .min(3, "Please enter you full name."),
        color: Yup.string().required("Category color is required"),
        image: Yup.string().required("Category image is required"),
        parentId: Yup.string().required("Parent ID is required"),
      }),
  });
  const inputError = (formik, input) => {
    return (
      formik.errors[input] &&
      formik.touched[input] && (
        <div
          style={{
            color: "rgb(235, 54,54)",
            fontSize: "14px",
          }}
        >
          {formik.errors[input]}
        </div>
      )
    );
  };

  return (
    <section
      className="postCountIV"
      style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
    >
      <Tabs>
        <div label={"Add Category"}>
          <AddCategoryForm1 />
        </div>
        <div label={"Add Sub Category"}>
          <Form
            onSubmit={formik.handleSubmit}
            style={{ padding: "10px 20px 0px 20px" }}
          >
            <FormGroup>
              <Label for="name" className="lableCategory">
                Name:
              </Label>
              <Input
                type="name"
                name="name"
                id="name"
                placeholder={`Enter Category Name`}
                onChange={formik.handleChange}
              />
              {inputError(formik, "name")}
            </FormGroup>
            <FormGroup>
              <Label for="color" className="lableCategory">
                Color:
              </Label>
              <Input
                type="color"
                name="color"
                id="color"
                onChange={formik.handleChange}
              />
              {inputError(formik, "color")}
            </FormGroup>
            <FormGroup>
              <Label for="image" className="lableCategory">
                Image:
              </Label>
              <Input
                type="file"
                name="image"
                id="image"
                placeholder={` Image`}
                onChange={(event) => {
                  formik.setFieldValue("image", event.currentTarget.files[0]);
                }}
              />
              {inputError(formik, "image")}
            </FormGroup>
            <FormGroup>
              <Label for="radio" className="lableCategory">
                Special:
              </Label>
              <br />
              <Input
                type="radio"
                name="is_special"
                id="radio"
                value={true}
                placeholder={`Change Category Image`}
                onChange={formik.handleChange}
              />
              {" True "}
              <Input
                type="radio"
                name="is_special"
                id="radio"
                value={false}
                placeholder={`Change Category Image`}
                onChange={formik.handleChange}
              />
              {" False "}
            </FormGroup>
            <FormGroup>
              <Label for="exampleSelect" className="lableCategory">
                Parent Category:
              </Label>
              <Input
                type="select"
                name="parentId"
                id="exampleSelect"
                onChange={formik.handleChange}
              >
                <option value="1">Astrology</option>
                <option value="7">Suvichar</option>
                <option value="15">Holy Places</option>
                <option value="23">Health</option>
                <option value="33">Spiritual</option>
                <option value="39">Arts & Society</option>
                <option value="45">Festival</option>
              </Input>
              {inputError(formik, "parent_id")}
            </FormGroup>
            <Button
              style={{
                display: "inline-block",
                width: "200px",
                float: "right",
              }}
              type="submit"
            >
              <strong>Add Sub Category</strong>
            </Button>
          </Form>
        </div>
      </Tabs>
    </section>
  );
};

export default AddCategoryForm;
