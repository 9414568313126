import React, { useState, useEffect } from "react";
import { pilgrim_tours_tours_by_sub_category } from "../../../store/the-pilgrim-tours";
import { useLocation, useNavigate } from "react-router-dom";
import style from "../pilgrimTours.module.css";
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const SubCategories = () => {
  const router = useNavigate();
  const history = useLocation();

  const [subCategories, setSubCategories] = useState(
    history.state
      ? history.state
      : { categoryId: history.pathname.split("/")[3] }
  );
  const [tours, setTours] = useState();

  async function getToursBySubCategory() {
    const response = await pilgrim_tours_tours_by_sub_category(
      subCategories.categoryId
    );
    if (response.status === 200) {
      setTours(response.data.data);
    } else {
    }
  }

  useEffect(() => {
    getToursBySubCategory();
  }, [history]);

  // console.log("subCategories", subCategories, tours);

  return (
    <section
      className="postCountIV"
      style={{ overflowY: "scroll", marginTop: "12px" }}
    >
      {subCategories.name && (
        <div
          className={` d-flex align-items-center justify-content-between w-100`}
        >
          <p className={`mb-0 ${style.cateHead}`}> {subCategories.name}</p>

          <div
            onClick={() => {
              router(`/pilgrim-tours/add-packages`, {
                state: history.pathname.split("/")[3],
              });
            }}
            style={{
              backgroundColor: "#63636363",
              border: "1px solid #636363",
              borderRadius: "20px",
              padding: "5px 20px",
              display: "flex",
              gap: "10px",
              alignItems: "center",
              cursor: "pointer",
              color: "black",
            }}
          >
            Add New
            <FontAwesomeIcon icon={faAdd} />
          </div>
        </div>
      )}

      {/* {tours && tours.map((tour, tourIndex) => {
                return <div key={tourIndex} onClick={() => { router(`/pilgrim-tours/${tour.packageId}`, { state: tour }) }}>
                    <div>{tour.title}</div>
                </div>
            })} */}
      <div className="my-3 p-3 d-flex flex-wrap gap-2">
        {tours &&
          tours.map((tour, tourIndex) => {
            return (
              <div key={tourIndex} className={`card ${style.subCateBox}`}>
                {tour.url && (
                  <img
                    class="card-img-top"
                    src={tour.url}
                    alt="tours sub category image"
                    height={250}
                  ></img>
                )}
                <div class="card-body">
                  <h5 class="card-title">{tour.title}</h5>
                  <button
                    type="button"
                    class="btn btn-warning"
                    onClick={() => {
                      router(`/pilgrim-tours/${tour.packageId}`, {
                        state: tour,
                      });
                    }}
                  >
                    See Details
                  </button>
                </div>
              </div>
            );
          })}
      </div>
    </section>
  );
};

export default SubCategories;
