import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import Heading from "../../SattvikStays/CommonComponents/Heading";
import {
  sattvik_get_all_hotel_api,
  sattvik_get_all_city_api,
} from "../../../store/sattvik-stays";
import { get_all_package_details } from "../../../store/the-pilgrim-tours";
import style from "../../SattvikStays/Hotels/SattvikStaysHotels.module.css";
import {
  faFilter,
  faCircleCheck,
  faStar,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
const Hotels = () => {
  const Navigate = useNavigate();
  const [active, setActive] = useState(true);
  const [hotels, setHotels] = useState([]);
  const [filter, setFilter] = useState("");
  const [userType, setUsertype] = useState("All");
  const [filterBox, setOpenFIlterBox] = useState(false);
  const accountType = [
    "All",
    "hotel",
    "dharamshala",
    "guest house",
    "home stay",
  ];
  const [cities, setCities] = useState([]);
  const getCities = async () => {
    const response = await sattvik_get_all_city_api();

    if (response.data.status == "success") {
      setCities(response.data.data);
    }
  };
  useEffect(() => {
    getCities();
  }, []);

  const getAllHotels = async () => {
    const response = await get_all_package_details();
    if (response.data.status == "success") {
      console.log("---succ", response);
      setHotels(response.data.data);
    }
  };
  useEffect(() => {
    getAllHotels();
  }, [filter]);
  return (
    <div>
      <Heading
        filter={true}
        title={"Hotels"}
        text={"Add New"}
        link="/sattvik-stays/add-hotel"
        filterBox={filterBox}
        setFilter={setFilter}
      >
        <div className={style.hotels}>
          <div className={style.filterAndSearch}>
            <FontAwesomeIcon
              icon={faFilter}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setOpenFIlterBox(!filterBox);
              }}
            />
            {filterBox && (
              <div className={style.filterbox}>
                <div className={style.moreFilter}>
                  <div>
                    <ul>
                      {accountType.map((each) => {
                        return (
                          <li
                            onClick={() => {
                              setUsertype(each);
                              setFilter(each === "All" ? "" : each);
                              setOpenFIlterBox(false);
                            }}
                            className={
                              each === userType && style.activeAccountType
                            }
                          >
                            <FontAwesomeIcon
                              color={each === userType ? "green" : "grey"}
                              icon={faCircleCheck}
                            />
                            <span>{each}</span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Heading>
      <div className="d-flex gap-2 p-sm-0 px-3">
        <Button
          onClick={() => {
            setActive(true);
          }}
          className={`${active ? "bg-warning" : "bg-white text-black"} px-4`}
        >
          Active
        </Button>
        <Button
          onClick={() => {
            setActive(false);
          }}
          className={`${!active ? "bg-warning" : "bg-white text-black"} px-4`}
        >
          Disable
        </Button>
      </div>
      <div className="row overflow-x-hidden">
        {active
          ? hotels &&
            hotels
              .filter((each) => {
                return each.isActive === true;
              })
              .map((e, i) => (
                <div className="col-11  col-sm-6 col-md-4  m-auto" key={i}>
                  <div
                    className={`${i > 2 && "mt-sm-3"} mt-2`}
                    style={{
                      width: "100%",
                      borderStartEndRadius: "5px",
                      borderStartStartRadius: "5px",
                      overflow: "hidden",
                      position: "relative",
                      height: "220px",
                    }}
                  >
                    <div
                      className="position-relative"
                      style={{
                        width: "100%",
                        height: "100%",
                        boxShadow: "0px 0px 4px 4px rgba(0,0,0,.5)",
                      }}
                    >
                      <img
                        src={e.url}
                        alt={e.title}
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                      />

                      <div
                        onClick={() => {
                          Navigate(`/pilgrim-tours/${e.packageId}`, {
                            state: e,
                          });
                        }}
                        className="position-absolute top-0 end-0 pe-1 pt-1 z-3 hotels-pen"
                      >
                        <FontAwesomeIcon
                          style={{ cursor: "pointer" }}
                          icon={faPen}
                          className="me-2  "
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        maxWidth: "90%",
                        zIndex: "1051",
                        bottom: 0,
                        color: "white",
                        transform: "translateX(-50%)",
                        left: "50%",
                        textAlign: "center",
                        borderRadius: "6px 6px 0 0",
                        padding: "5px",
                      }}
                    ></div>
                  </div>
                  <div className={style.hotelBtmContent}>
                    <h2 className={style.hotelName}>
                      {e.title.substring(0, 60)}..
                    </h2>
                    <div className={style.ratingbox}>
                      <p>
                        {e.price}
                        (
                        <FontAwesomeIcon color="orange" icon={faStar} />)
                      </p>
                      <p>{e.discountPrice} </p>
                    </div>
                    <div className={style.addresHeight}>
                      <p className="fs-6">{e.destination}</p>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: `${e.description.substring(3, 90)}....`,
                        }}
                      />
                    </div>
                  </div>
                </div>
              ))
          : hotels &&
            hotels
              .filter((each) => {
                return each.isActive === false;
              })
              .map((e, i) => (
                <div className="col-11  col-sm-6 col-md-4  m-auto" key={i}>
                  <div
                    className={`${i > 2 && "mt-sm-3"} mt-2`}
                    style={{
                      width: "100%",
                      borderStartEndRadius: "5px",
                      borderStartStartRadius: "5px",
                      overflow: "hidden",
                      position: "relative",
                      height: "220px",
                    }}
                  >
                    <div
                      className="position-relative"
                      style={{
                        width: "100%",
                        height: "100%",
                        boxShadow: "0px 0px 4px 4px rgba(0,0,0,.5)",
                      }}
                    >
                      <img
                        src={e.url}
                        alt={e.title}
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                      />

                      <div
                        onClick={() => {
                          Navigate(`/pilgrim-tours/${e.packageId}`, {
                            state: e,
                          });
                        }}
                        className="position-absolute top-0 end-0 pe-1 pt-1 z-3 hotels-pen"
                      >
                        <FontAwesomeIcon
                          style={{ cursor: "pointer" }}
                          icon={faPen}
                          className="me-2  "
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        maxWidth: "90%",
                        zIndex: "1051",
                        bottom: 0,
                        color: "white",
                        transform: "translateX(-50%)",
                        left: "50%",
                        textAlign: "center",
                        borderRadius: "6px 6px 0 0",
                        padding: "5px",
                      }}
                    ></div>
                  </div>
                  <div className={style.hotelBtmContent}>
                    <h2 className={style.hotelName}>
                      {e.title.substring(0, 60)}..
                    </h2>
                    <div className={style.ratingbox}>
                      <p>
                        {e.price}
                        (
                        <FontAwesomeIcon color="orange" icon={faStar} />)
                      </p>
                      <p>{e.discountPrice} </p>
                    </div>
                    <div className={style.addresHeight}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: `${e.description.substring(1, 90)}....`,
                        }}
                      />
                    </div>
                  </div>
                </div>
              ))}
      </div>
    </div>
  );
};

export default Hotels;
