import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faImage } from "@fortawesome/free-solid-svg-icons";
// import Data from '../../../../Data';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import axios from 'axios';
import { useState } from 'react';
const url = process.env.REACT_APP_BASE_URL


export default function FullWidthGrid(props) {
  const [chalisa, setChalisa] = useState([])
  const history = useNavigate();

  function subCat(data) {
    history('/editChalisa', { state: data })
  }
  const token = `Bearer ${localStorage.getItem("token")}`


  const FestivalByMonth = async () => {
    await axios.get(url + `/pg/rgyan-audio/list-of-chalisa`, {

      headers: {
        'authorization': token,
        'Accept': 'application/json',
        "ngrok-skip-browser-warning": "any",
        'Content-Type': 'application/json'
      }
    }).then((res) => {

      setChalisa(res.data.data)
    }).catch((err) => {
      console.log("Error::::::::", err)
    })
  }
  useEffect(() => {
    FestivalByMonth()
  }, [])

  if (token && token != 'undefined' ) {
    return (
      <div className='postCountIII'>
        <div className="grid-container">
          {chalisa && chalisa.length > 0 && chalisa.map((data, index) => {
            return <div key={index} style={{ backgroundImage: `url(${data.image})` }} onClick={() => { subCat(data) }}>

              <span className='imageAdd'><FontAwesomeIcon icon={faImage} size='sm' className='imageAddIcon' /></span>
              <div className='buttonContainer'>
                <div>
                  <p className='span' style={{ color: 'white', marginTop: '10px' }}>{data.name_en} <FontAwesomeIcon icon={faPen} className='icon' /></p>
                </div>

              </div>
            </div>

          })}
        </div>
      </div>

    );
  } else {
    window.location.href = '/'
  }
}
