import React from "react";
import Month from "../Tabs/Month";
import { useState, useEffect } from "react";

const Festivals = () => {
  // states
  let months = [
    { id: 1, name: "January" },
    { id: 2, name: "February" },
    { id: 3, name: "March" },
    { id: 4, name: "April" },
    { id: 5, name: "May" },
    { id: 6, name: "June" },
    { id: 7, name: "July" },
    { id: 8, name: "August" },
    { id: 9, name: "September" },
    { id: 10, name: "October" },
    { id: 11, name: "November" },
    { id: 12, name: "December" },
  ];
  const [curmonth, setCurMonth] = useState(() => {
    const savedValue = localStorage.getItem("myValue");
    return savedValue !== null ? JSON.parse(savedValue) : 1;
  });

  // useEffects
  useEffect(() => {
    localStorage.setItem("myValue", JSON.stringify(curmonth));
  }, [curmonth]);

  return (
    <section
      className="postCountIV"
      style={{ overflowY: "scroll", marginTop: "12px" }}
    >
      <div>
        <ol className="tab-list">
          {months.map((child) => {
            return (
              <li
                onClick={() => {
                  setCurMonth(child.id);
                }}
                className={`tab-list-item ${
                  curmonth === child.id && "tab-list-active"
                }`}
              >
                {child.name}
              </li>
            );
          })}
        </ol>

        {months &&
          months.length > 0 &&
          months
            .filter((each) => {
              return each.id === curmonth;
            })
            .map((items, index) => {
              return (
                <div key={index} label={items.name}>
                  <Month data={curmonth} />
                </div>
              );
            })}
      </div>
    </section>
  );
};

export default Festivals;
