import React from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
const URL = process.env.REACT_APP_BASE_URL;
const img = require('../../../../../assets/img/dddd.webp')

export default function FullWidthGrid(props) {
    const [subCategory, setSubCategory] = React.useState([])
    const history = useNavigate();
    const getSubCategory = async () => {
        await axios.get(`${URL}/category/get-list-of-child-categories/${props.data}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
                "ngrok-skip-browser-warning": "any"
            },
        }).then((res) => {
            setSubCategory(res.data.data)
        })
    };

    React.useEffect(() => {
        getSubCategory();
    }, []);

    function subCat(data) {
        history('/single-category-details', {state:data})
    }

    return (
        <div className='postCountIII postCountX'>
            <div className="grid-container">
                {subCategory.map((data, index) => {
                    return <div key={index} style={{backgroundColor: `${data.color}`}} onClick={()=>{subCat(data)}}>
                        <img src={data.image === "null" ? img : data.image } className='categoryLogo'></img>
                        <p><strong>{data.name}</strong></p>
                    </div>
                })}
            </div>
        </div>
    );
}