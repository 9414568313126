import React from "react";
import Tabs from "../../../Tabs/Tabs";
import Astrology from "../../Divotion/VartkathaAllCat";
import { useState, useEffect } from "react";
const VratKathaCategory = () => {
  let Astrologys = [
    { id: 2, name: "Weekly Vrat Katha" },
    { id: 4, name: "Ekadashi Vrat katha" },
    { id: 1, name: "Monthly Vrat katha" },
    { id: 3, name: "Yearly Vrat Katha" },
  ];

  const [curcategory, setCurCategory] = useState(() => {
    // Load initial state from localStorage, if available
    const savedValue = localStorage.getItem("mycurrentvarthkatha");
    return savedValue !== null ? JSON.parse(savedValue) : 1;
  });

  // let className = `tab-list-item ${tab - list - active}`;
  useEffect(() => {
    // Save state to localStorage whenever `value` changes

    localStorage.setItem("mycurrentvarthkatha", JSON.stringify(curcategory));
  }, [curcategory]);

  return (
    <section className="postCountIV">
      <div>
        <ul className="tab-list-new">
          <li
            className={curcategory === 1 && "active"}
            onClick={() => {
              setCurCategory(1);
            }}
          >
            Monthly Vrat katha
          </li>
          <li
            className={curcategory === 2 && "active"}
            onClick={() => {
              setCurCategory(2);
            }}
          >
            Weekly Vrat Katha
          </li>
          <li
            className={curcategory === 3 && "active"}
            onClick={() => {
              setCurCategory(3);
            }}
          >
            Yearly Vrat Katha
          </li>
          <li
            className={curcategory === 4 && "active"}
            onClick={() => {
              setCurCategory(4);
            }}
          >
            Ekadashi Vrat katha
          </li>
        </ul>

        <div className="tabs">
          <div className="tab-content">
            {Astrologys &&
              Astrologys.length > 0 &&
              Astrologys.map((items, index) => {
                if(items.id === curcategory){
                  return (
                    <div key={index} label={items.name}>
                      <Astrology data={items.id} />
                    </div>
                  );
                }
                
              })}
          </div>
        </div>
      </div>
    </section>

    // <section className='postCountIV'>
    //   <Tabs>
    //     <div label="Masik Vrat Katha">
    //       <Astrology />
    //     </div>
    //     <div label="Weekly Vrat katha">
    //       <Astrology />
    //     </div>
    //     <div label="Weekly Vrat katha">
    //       <Astrology />
    //     </div>
    //     <div label="Ekadashi Vrat Katha">
    //       <Astrology />
    //     </div>
    //   </Tabs>
    // </section>
  );
};

export default VratKathaCategory;
