exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".sattvikStaysDashboardCards_dashboardCards__3d9UH .sattvikStaysDashboardCards_contentBox__364Fm {\n    display: flex;\n    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;\n    margin: 0 1rem;\n    padding: 1.5rem 1rem;\n    align-items: center;\n    justify-content: space-between;\n    border-radius: 6px;\n    cursor: pointer;\n\n}\n\n.sattvikStaysDashboardCards_dashboardCards__3d9UH .sattvikStaysDashboardCards_contentBox__364Fm .sattvikStaysDashboardCards_icon__3wK1o {\n    height: 40px;\n    width: 40px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border-radius: 12px;\n    box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;\n\n}\n\n.sattvikStaysDashboardCards_dashboardCards__3d9UH .sattvikStaysDashboardCards_contentBox__364Fm .sattvikStaysDashboardCards_text__3H0Bx {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-end;\n    grid-gap: 2px;\n    gap: 2px;\n    font-weight: 700;\n}\n\n.sattvikStaysDashboardCards_dashboardCards__3d9UH .sattvikStaysDashboardCards_contentBox__364Fm .sattvikStaysDashboardCards_text__3H0Bx .sattvikStaysDashboardCards_name__3a2WO {\n    font-weight: 600;\n    font-size: 1.1rem;\n}\n\n.sattvikStaysDashboardCards_dashboardCards__3d9UH .sattvikStaysDashboardCards_contentBox__364Fm .sattvikStaysDashboardCards_text__3H0Bx p {\n    margin-bottom: 0 !important;\n}", ""]);

// exports
exports.locals = {
	"dashboardCards": "sattvikStaysDashboardCards_dashboardCards__3d9UH",
	"contentBox": "sattvikStaysDashboardCards_contentBox__364Fm",
	"icon": "sattvikStaysDashboardCards_icon__3wK1o",
	"text": "sattvikStaysDashboardCards_text__3H0Bx",
	"name": "sattvikStaysDashboardCards_name__3a2WO"
};