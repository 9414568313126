import React from "react";
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Alert, Button } from "reactstrap";
const SeoForm = ({ createdSeoData, setCreatedSeoData, language }) => {
  const URL = process.env.REACT_APP_BASE_URL;
  const history = useNavigate();
  const [isSuccess, setIsSuccess] = useState(false);
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState("");
  const handleUpdate = (e) => {
    e.preventDefault();
    setSubmitInProgress(true);

    axios
      .put(
        `${URL}/admin/update-fest-category-seo`,
        {
          id: createdSeoData.id,
          categoryId: createdSeoData.category_id,
          title: createdSeoData.title,
          titleHi: createdSeoData.titleHi,
          description: createdSeoData.description,
          descriptionHI: createdSeoData.descriptionHI,
          keyword: createdSeoData.keyword,
          keywordHi: createdSeoData.keywordHI,
          image: createdSeoData.image,
          url: createdSeoData.url,
          urlHi: createdSeoData.urlHi,
          image: createdSeoData.image,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            Accept: "application/json",
            "Content-Type": "application/json",         "ngrok-skip-browser-warning": "any",
          },
        }
      )
      .then((res) => {
        if (res) {
          setIsSuccess(true);
          setMessage("Seo Content Updated Successfully Please Wait");
          setTimeout(() => {
            setIsSuccess(false);
            setMessage("");
            setIsSuccess(true);
            setSubmitInProgress(false);
            history("/festivals");
          }, 3000);
        }
      })
      .catch((err) => {
        setSubmitInProgress(false);

        setIsError(true);
        setError(err.response.data.msg);
        setTimeout(() => {
          setIsError(false);
          setError("");
        }, 3000);
      });
  };
  return (
    <div>
      <section className="postCountIV ">
        {" "}
        <div
          style={{
            color: "black",
            marginBottom: "30px",
            textAlign: "center",
            width: "100%",
            borderRadius: "10px",
            justifyContent: "center",
            marginLeft: "10px",
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <h3>Update Festival Category</h3>
        </div>
        <div className="row">
          <div className="col-lg-6">
            {isSuccess && <Alert color="info">{message}</Alert>}
          </div>

          <div className="row">
            <div className="col-lg-6">
              {isError && (
                <Alert
                  style={{
                    background: "rgb(251, 99, 71)",
                    color: "white",
                  }}
                >
                  Error::
                  {error}
                </Alert>
              )}
            </div>
          </div>
        </div>
        <form onSubmit={handleUpdate}>
          <div className="form">
            <div className="each-input">
              <label>Title English</label>
              <input
                type="text"
                value={createdSeoData.title}
                onChange={(e) => {
                  setCreatedSeoData((prev) => ({
                    ...prev,
                    ["title"]: e.target.value,
                  }));
                }}
                placeholder="Add Festival Category Title"
              ></input>
            </div>
            <div className="each-input">
              <label>Title Hindi</label>
              <input
                type="text"
                value={createdSeoData.title_hi}
                onChange={(e) => {
                  setCreatedSeoData((prev) => ({
                    ...prev,
                    ["title"]: e.target.value,
                  }));
                }}
                placeholder="Add Festival Category Title"
              ></input>
            </div>

            <div className="each-input">
              <label>Keywords English</label>
              <input
                value={createdSeoData.keyword}
                onChange={(e) => {
                  setCreatedSeoData((prev) => ({
                    ...prev,
                    ["keyword"]: e.target.value,
                  }));
                }}
                type="text"
                placeholder="Add Festival Category Keywords"
              ></input>
            </div>
            <div className="each-input">
              <label>Keywords Hindi</label>
              <input
                value={createdSeoData.keyword_hi}
                onChange={(e) => {
                  setCreatedSeoData((prev) => ({
                    ...prev,
                    ["keyword"]: e.target.value,
                  }));
                }}
                type="text"
                placeholder="Add Festival Category Keywords"
              ></input>
            </div>

            <div className="each-input">
              <label>Description English</label>
              <input
                name="descriptionEn"
                placeholder="Add Festival Category Description"
                type="text"
                value={createdSeoData.description}
                onChange={(e) => {
                  setCreatedSeoData((prev) => ({
                    ...prev,
                    ["description"]: e.target.value,
                  }));
                }}
              ></input>
            </div>
            <div className="each-input">
              <label>Description Hindi</label>
              <input
                name="descriptionEn"
                placeholder="Add Festival Category Description"
                type="text"
                value={createdSeoData.description_hi}
                onChange={(e) => {
                  setCreatedSeoData((prev) => ({
                    ...prev,
                    ["description"]: e.target.value,
                  }));
                }}
              ></input>
            </div>

            <div className="each-input">
              <label> Url English</label>
              <input
                value={createdSeoData.url}
                onChange={(e) => {
                  setCreatedSeoData((prev) => ({
                    ...prev,
                    ["image"]: e.target.value,
                  }));
                }}
                placeholder="add your image url here"
                type="text"
              ></input>
            </div>
            <div className="each-input">
              <label> Url Hindi</label>
              <input
                value={createdSeoData.url_hi}
                onChange={(e) => {
                  setCreatedSeoData((prev) => ({
                    ...prev,
                    ["image"]: e.target.value,
                  }));
                }}
                placeholder="add your image url here"
                type="text"
              ></input>
            </div>
            <div style={{ width: "100%" }} className="each-input">
              <label>Image</label>
              <input
                value={createdSeoData.image}
                onChange={(e) => {
                  setCreatedSeoData((prev) => ({
                    ...prev,
                    ["image"]: e.target.value,
                  }));
                }}
                placeholder="add your image url here"
                type="text"
              ></input>
            </div>
          </div>
          <div className="add-f-parent2">
            <Button
              onClick={() => {
                history("/festival", {
                  state: {
                    content: `<p></p>`,
                    type: 1,
                    language: "en",
                    id: createdSeoData.post_id,
                    categoryId: createdSeoData.id,
                  },
                });
              }}
              disabled={submitInProgress}
              style={{
                marginRight: "12px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "200px",
                float: "right",
                marginTop: "15px",
                background: "linear-gradient(180deg, #DD2A12 0%, #FFA214 100%)",
              }}
            >
              <strong>Skip</strong>
            </Button>

            <Button
              disabled={submitInProgress}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "200px",
                float: "right",
                marginTop: "15px",
                background: "linear-gradient(180deg, #DD2A12 0%, #FFA214 100%)",
              }}
              type="submit"
            >
              {!submitInProgress ? (
                <strong>Update SEO</strong>
              ) : (
                <span className="special-loader"></span>
              )}
            </Button>
          </div>
        </form>
      </section>
    </div>
  );
};

export default SeoForm;
