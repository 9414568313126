import React from "react";

import { useState, useEffect } from "react";

import Category from "./Tabs/Category";

const ReportList = () => {
  // token
  const token = localStorage.getItem("token");

  // states
  const [curcategory, setCurCategory] = useState(() => {
    const savedValue = localStorage.getItem("mycurrentwithseo");
    return savedValue !== null ? JSON.parse(savedValue) : 1;
  });

  // useEffect
  useEffect(() => {
    localStorage.setItem("mycurrentwithseo", JSON.stringify(curcategory));
  }, [curcategory]);
  if (token && token != "undefined") {
    const data = [
      { name: "Ritual Category ", type: 1 },
      { name: "Ritual Post", type: 2 },
      { name: "Temple", type: 3 },
      { name: "Varatkatha Category ", type: 4 },
      { name: "Varatkatha", type: 5 },
      { name: "English Blog", type: 6 },
      { name: "Hindi Blog", type: 7 },
      { name: "Festivals", type: 8 },
    ];
    return (
      <>
        <section
          className="postCount"
          style={{ overflow: "scroll", marginTop: "5px" }}
        >
          <div className="seo-grid">
            <h5
              className="col-lg-12 p-2"
              style={{
                backgroundColor: "#D3D3D3",
                color: "black",

                textAlign: "center",
                borderRadius: "10px",
                fontSize: "30px",
              }}
            >
              {" "}
              SEO Catagory
            </h5>{" "}
            <div>
              <ul className="tab-list-new">
                <li
                  className={curcategory === 1 && "active"}
                  onClick={() => {
                    setCurCategory(1);
                  }}
                >
                  Ritual Categor
                </li>
                <li
                  className={curcategory === 2 && "active"}
                  onClick={() => {
                    setCurCategory(2);
                  }}
                >
                  Ritual Post
                </li>
                <li
                  className={curcategory === 3 && "active"}
                  onClick={() => {
                    setCurCategory(3);
                  }}
                >
                  Temple
                </li>
                <li
                  className={curcategory === 4 && "active"}
                  onClick={() => {
                    setCurCategory(4);
                  }}
                >
                  Varatkatha Category
                </li>
                <li
                  className={curcategory === 5 && "active"}
                  onClick={() => {
                    setCurCategory(5);
                  }}
                >
                  Varatkatha
                </li>
                <li
                  className={curcategory === 6 && "active"}
                  onClick={() => {
                    setCurCategory(6);
                  }}
                >
                  English Blog
                </li>
                <li
                  className={curcategory === 7 && "active"}
                  onClick={() => {
                    setCurCategory(7);
                  }}
                >
                  Hindi Blog
                </li>
                <li
                  className={curcategory === 8 && "active"}
                  onClick={() => {
                    setCurCategory(8);
                  }}
                >
                  Festivals
                </li>
              </ul>

              <div className="tabs tabs-mobile">
                <div className="tab-content">
                  {data &&
                    data.length > 0 &&
                    data.map((data, index) => {
                      if (data.type === curcategory) {
                        return (
                          <div key={index} label={data.name}>
                            <Category data={data.type} name={data.name} />
                          </div>
                        );
                      } else {
                        return;
                      }
                    })}
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  } else {
    window.location.href = "/";
  }
};

export default ReportList;
