import React from "react";
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
const url = process.env.REACT_APP_BASE_URL;

const Index = (props) => {
  // usenavigate
  const history = useNavigate();
  
  // states
  const [blogCategory, setBlogCategory] = useState([]);
  const [englishBlog, setEnglishBlog] = useState([]);
  const [skip, setSkip] = useState(0);
  const [id, setId] = useState("adhyatmikata");
  const [categoryId, setCategoryId] = useState();
  const [curcategory, setCurCategory] = useState(() => {
    const savedValue = localStorage.getItem("mycurrenthindiblogcategory");
    return savedValue !== null ? JSON.parse(savedValue) : "adhyatmikata";
  });

  // functions 
  const getCategory = async (data, language, basic) => {
    data.categoryId = categoryId;
    if (basic) {
      history("/update-basic-details", {
        state: {
          data: data,
          language: language,
          pageTitle: "Hindi Blog",
          type: 2,
        },
      });
    } else {
      history("/update-blog-content", {
        state: {
          data: data,
          language: language,
          content: data.content,
          type: 2,
        },
      });
    }
  };

  const BlogCategoryData = async () => {
    await axios
      .get(`${url}/pg/blog/list-of-hi-blog-category`, {
        headers: {
          Accept: "application/json",
          client: 3,
          "Content-Type": "application/json",
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "any",
        },
      })
      .then((res) => {
        setBlogCategory(res.data.data);
        setCategoryId(res.data.data[0].id);
      })
      .catch((err) => console.log("Error::::::::", err));
  };

  const BlogsData = async (params1) => {
    await axios
      .get(`${url}/pg/blog/list-of-hi-blog`, {
        params: {
          categorySlug: params1,
          skip: skip,
          limit: 200,
        },
        headers: {
          Accept: "application/json",
          client: 3,
          "Content-Type": "application/json",
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "any",
        },
      })
      .then((res) => {
        const filtered = res.data.blogList.filter((every) => {
          return every != null;
        });
        setEnglishBlog(filtered);
      })
      .catch((err) => console.log("Error::::::::", err));
  };
  const changeStyle = (id) => {
    setId(id);
  };

  // useEffects
  useEffect(() => {
    BlogCategoryData();
    BlogsData(curcategory);
  }, [curcategory]);

  useEffect(() => {
    localStorage.setItem(
      "mycurrenthindiblogcategory",
      JSON.stringify(curcategory)
    );
  }, [curcategory]);
  
  return (
    <div
      className="postCount"
      style={{
       
        cursor: "pointer",
        overflow: "scroll",
        marginTop: "12px",
      }}
    >
      <div className="blog-correct">
        {blogCategory &&
          blogCategory.length > 0 &&
          blogCategory.map((element, index) => {
            return (
              <div
                key={index}
                style={{ cursor: "pointer" }}
                className={[
                  element.slug == curcategory ? "BlogNavBar1" : "BlogNavBar",
                ]}
                onClick={() => {
                  changeStyle(element.id);
                  BlogsData(element.slug);
                  setCurCategory(element.slug);
                }}
              >
                <strong> {element.category_name}</strong>
              </div>
            );
          })}
        <div className="btn-add">
          <button
            style={{
              margin: "5px",
              marginLeft: "10px",
              top: "0px",
              width: "120px",
              height: "34px",
              background: "orange",
              border: "none",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              borderRadius: "14px",
              fontWeight: "bold",
              color: "black",
            }}
            onClick={() => {
              history("/add-new-blog", {
                state: {
                  data: blogCategory,
                  language: "hi",
                  pageTitle: "Create Hindi Blog",
                  type: 2,
                },
              });
            }}
          >
            Add Blog
          </button>
        </div>{" "}
      </div>

      <div className="postCountIII">
        <div className="grid-container">
          {englishBlog &&
            englishBlog.length > 0 &&
            englishBlog.map((element, index) => {
              return (
                <div
                  key={index}
                  style={{
                    backgroundImage: `url(${element.image})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                    boxShadow: "inset 0px 0px 0px 200px rgba(0,0,0,0.25)",
                  }}
                >
                  <div
                    style={{ position: "relative" }}
                    className="detail-outer-box"
                  >
                    <span className="imageAdd">
                      <FontAwesomeIcon
                        icon={faImage}
                        size="sm"
                        style={{ color: "white" }}
                        className="imageAddIcon"
                      />
                    </span>
                    <div>
                      <p className="spanP">
                        <strong>{element.title}</strong>
                      </p>
                      <div>
                        <button
                          className="boxbutton"
                          onClick={() => {
                            getCategory(element, "hi");
                          }}
                        >
                          {" "}
                          Content{" "}
                        </button>
                        <button
                          className="boxbutton"
                          onClick={() => {
                            getCategory(element, "hi", "basic");
                          }}
                        >
                          basic
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Index;
