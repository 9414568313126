import React from "react";
import style from "./sattvikStaysDashboardCards.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const dashboardCards = ({ icon, name, number, color }) => {
  return (
    <div className={style.dashboardCards}>
      <div className={`${style.contentBox} flex-column align-items-start`}>
        <p className="d-sm-none fs-6 text-capitalize fw-bold">{name}</p>
        <div className="d-flex items-center justify-content-between w-100">
          <div className={style.icon} style={{ background: color }}>
            <FontAwesomeIcon className="text-white" icon={icon} />
          </div>
          <div className={`${style.text} d-flex align-items-center justify-content-center d-sm-block`}>
            <p className={`${style.name} d-none d-sm-block`}>{name}</p>
            <p className={`${style.number} text-sm-end`}>{number}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default dashboardCards;
