import React, { useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "../../Side";
import { useNavigate } from "react-router-dom";
import Navigation from "../../Navigation";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

const url = process.env.REACT_APP_BASE_URL;

const AddHinduFestivalDates = (props) => {
  const [festival, setFestival] = useState();
  const navigate = useNavigate();

  const token = localStorage.getItem("token");
  const name = localStorage.getItem("user");
  const pageTitle = "Add New Hindu Festivals Date";
  const active = "active";
  if (token && token != "undefined") {
    const HandleRedirect = (value) => {
      navigate("/edit-seo", {
        state: value,
      });
    };

    const Data = async () => {
      await axios
        .get(url + "/admin/festival-seo-data", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            Accept: "application/json",
            "Content-Type": "application/json",
            client: 3,
          },
        })
        .then((res) => {
          setFestival(res.data.data);
        })
        .catch((err) => {
          console.log("Error::::::::", err);
        });
    };
    useEffect(() => {
      Data();
    }, []);
    return (
      <>
        <div className="main-parentcontainer">
          <div className="grid-parent">
            {" "}
            <Sidebar data={name} ugc={active} />
            <div className="grid-child">
              {" "}
              <Navigation data={pageTitle} />
              <section
                className="postCount"
                style={{ overflow: "scroll", height: "95vh" }}
              >
                
            
              </section>
            </div>{" "}
          </div>
        </div>
      </>
    );
  } else {
    window.location.href = "/";
  }
};

export default AddHinduFestivalDates;
