import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";
import { Button, CardBody, Card } from "reactstrap";
import styles from "../../../assets/css/styles.css";
const url = process.env.REACT_APP_BASE_URL;
const Index = () => {
  // useNavigate
  const navigate = useNavigate();

  // token
  const token = localStorage.getItem("token");

  // states
  const [darshan, setDarshan] = useState([]);
  if (token && token != "undefined") {
    const HandleRedirect = (value) => {
      navigate("/edit-live-darshan", {
        state: value,
      });
    };

    const Live = async () => {
      await axios
        .get(url + "/admin/live-darshan-data", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            Accept: "application/json",
            "Content-Type": "application/json",
            client: 3,
            "ngrok-skip-browser-warning": "any",
          },
        })
        .then((res) => {
          setDarshan(res.data.data);
        })
        .catch((err) => {
          console.log("Error::::::::", err);
        });
    };

    useEffect(() => {
      Live();
    }, []);

    return (
      <>
        <section
          className="postCount"
          style={{
            position: "relative",
            overflow: "scroll",
            height: "99%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "5px",
              background: "white",
              position: "sticky",
              top: 0,
              zIndex: "9",
            }}
          >
            <div className=" width-full d-flex align-items-center justify-content-end p-2 position-sticky top-0">
              <Link to="/add-new-live-darshan-category">
                <button className="addCategoryButton">
                  ADD New Live Darshan{" "}
                  <FontAwesomeIcon icon={faAngleDoubleRight} />
                </button>
              </Link>
            </div>
          </div>
          <table
            class="table table-striped table-bordered"
            l
            style={{
              borderRadius: "3px",
              padding: "5px",
              backgroundColor: "white",
            }}
          >
            <tbody>
              {darshan &&
                darshan.length > 0 &&
                darshan.map((item, index) => {
                  return (
                    <>
                      {!index && (
                        <thead
                          style={{
                            width: "100%",
                            display: "inline-block",
                          }}
                        >
                          <th style={{ float: "left" }}>S No</th>
                          <th style={{ float: "left" }}>Video Name</th>
                          <th style={{ float: "right" }}>Edit</th>
                        </thead>
                      )}
                      <tr
                        className={styles.upButton}
                        key={index + 1}
                        style={{ borderWidth: "0px" }}
                      >
                        <Button
                          id={item.id}
                          style={{
                            display: "inline-block",
                            border: "none",
                            borderWidth: "0px",
                            color: "black",
                            background: "none",
                            width: "100%",
                            textalign: "center",
                            padding: "0px",
                          }}
                        >
                          <td
                            style={{
                              padding: "10px",
                              width: "100%",
                              display: "inline-block",
                              textAlign: "left",
                              fontWeight: "700",
                            }}
                          >
                            {item.categoryName}
                          </td>
                        </Button>
                      </tr>

                      <Card>
                        <CardBody>
                          <table
                            class="table table-striped table-bordered"
                            style={{
                              borderRadius: "3px",
                              padding: "5px",
                              backgroundColor: "white",
                            }}
                          >
                            <tbody>
                              <option selected> {item.video_name}</option>
                              {item.video.map((ele, i) => {
                                return (
                                  <tr key={i + 1}>
                                    <td>{i + 1}</td>
                                    <td>{ele.video_name}</td>
                                    <td
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        HandleRedirect(ele);
                                      }}
                                    >
                                      {" "}
                                      Edit{" "}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </CardBody>
                      </Card>
                    </>
                  );
                })}
            </tbody>
          </table>
        </section>
      </>
    );
  } else {
    window.location.href = "/";
  }
};

export default Index;
