import React, { useEffect, useState } from "react";
import { sattvik_get_all_city_api } from "../../../store/sattvik-stays";
import Heading from "../CommonComponents/Heading";
import { Button } from "reactstrap";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
const Cities = () => {
  const Navigate = useNavigate();
  const [active, setActive] = useState(true);
  const [cities, setCities] = useState([]);
  const [loader, setLoader] = useState(true);
  const getCities = async () => {
    setLoader(true);
    const response = await sattvik_get_all_city_api();
    if (response.data.status == "success") {
      setCities(response.data.data);
      setLoader(false);
    }
  };

  useEffect(() => {
    getCities();
  }, []);
  return (
    <React.Fragment>
      <Heading
        title={"Cities"}
        filter={false}
        text={"Add New City"}
        link="/sattvik-stays/add-city"
      />
      <div className="d-flex gap-2 p-sm-0 px-3">
        <Button
          onClick={() => {
            setActive(true);
          }}
          className={`${active ? "bg-warning" : "bg-white text-black"} px-4`}
        >
          Active
        </Button>
        <Button
          onClick={() => {
            setActive(false);
          }}
          className={`${!active ? "bg-warning" : "bg-white text-black"} px-4`}
        >
          Disable
        </Button>
      </div>
      <div
        className="w-100 mt-2 d-flex flex-wrap justify-content-between pb-2"
        style={{ gap: "10px" }}
      >
        {loader && (
          <div class="d-flex justify-content-center w-100">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
        {active
          ? cities &&
            cities
              .filter((each) => {
                return each.isActive == true;
              })
              .map((e, i) => {
                return (
                  <div
                    className="position-relative p-0 rounded"
                    style={{ width: "calc(33% - 5px)", overflow: "hidden" ,maxHeight:"220px"}}
                    key={i}
                  >
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <img
                        src={e.image}
                        alt={e.slug}
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                      />
                      <div
                        className={`position-absolute top-0 end-0 z-3 p-2 city-pen`}
                        onClick={() => {
                          Navigate("/sattvik-stays/edit-city", {
                            state: {
                              data: e,
                            },
                          });
                        }}
                      >
                        <FontAwesomeIcon
                          style={{ cursor: "pointer" }}
                          icon={faPen}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        maxWidth: "90%",
                        zIndex: "1051",
                        bottom: 0,
                        color: "white",
                        background: "rgba(0,0,0,.5)",
                        transform: "translateX(-50%)",
                        left: "50%",
                        textAlign: "center",
                        borderRadius: "6px 6px 0 0",
                        padding: "5px",
                      }}
                    >
                      {e.city}
                    </div>
                  </div>
                );
              })
          : cities &&
            cities
              .filter((each) => {
                return each.isActive == false;
              })
              .map((e, i) => (
                <div
                  className="position-relative p-0 rounded"
                  style={{ width: "calc(33% - 5px)", overflow: "hidden" ,maxHeight:"220px"}}
                  key={i}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src={e.image}
                      alt={e.slug}
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    />
                    <div
                      className={`position-absolute top-0 end-0 z-3 p-2 city-pen`}
                      onClick={() => {
                        Navigate("/sattvik-stays/edit-city", {
                          state: {
                            data: e,
                          },
                        });
                      }}
                    >
                      <FontAwesomeIcon
                        style={{ cursor: "pointer" }}
                        icon={faPen}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      maxWidth: "90%",
                      zIndex: "1051",
                      bottom: 0,
                      color: "white",
                      background: "rgba(0,0,0,.5)",
                      transform: "translateX(-50%)",
                      left: "50%",
                      textAlign: "center",
                      borderRadius: "6px 6px 0 0",
                      padding: "5px",
                    }}
                  >
                    {e.city}
                  </div>
                </div>
              ))}
      </div>
    </React.Fragment>
  );
};

export default Cities;
