import React, { useState } from "react";
import { useFormik } from "formik";
import { useLocation } from "react-router-dom";
import { Alert } from "reactstrap";
const url = process.env.REACT_APP_BASE_URL;

function Form(props) {
  // useHistory
  const history = useLocation();

  // token
  const token = localStorage.getItem("token");

  // states
  const [visible, setVisible] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  const [data, setData] = useState(history.state);

  // functions
  const EddSeoFestival = (values) => {
    fetch(`${url}/admin/update-all-seo-data?type=${data.type}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        Accept: "application/json",
        "Content-Type": "application/json",
        "ngrok-skip-browser-warning": "any",
      },
      body: JSON.stringify(values),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.status == "success") {
          setIsSuccess(true);
          setMessage(result.msg);
          setData(result.data[0]);
          setTimeout(() => {
            setIsSuccess(false);
            setMessage("");
          }, 3000);
        } else if (result.status == "failed") {
          console.log("Error::::::::", result);
          setIsError(true);
          setError(result.msg);
        }
      })
      .catch((err) => {
        console.log("Error::::::::", err);
        setIsError(true);
        setError("Something went wrong. Try again");
      });
  };
  const onDismiss = () => setVisible(false);
  const formik = useFormik({
    initialValues: {
      category_id: data.category_id,
      title: data.title,
      meta_description: data.meta_description,
      meta_keyword: data.meta_keyword,
      meta_og_image: data.meta_og_image,
      title_hi: data.title_hi,
      meta_description_hi: data.meta_description_hi,
      meta_keyword_hi: data.meta_keyword_hi,
      meta_og_url_hi: data.meta_og_url_hi,
      meta_og_url: data.meta_og_url,
    },
    onSubmit: (values) => {
      EddSeoFestival(values);
    },
  });

  if (token && token != "undefined") {
    return (
      <>
        <section className="postCount" style={{ overflow: "scroll" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              style={{
                color: "black",
                marginBottom: "30px",
                textAlign: "center",
                borderRadius: "10px",
                margin: "auto",
              }}
            >
              <h3>Edit {data.componentName} SEO</h3>
            </div>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            style={{ width: "90%", margin: "auto" }}
          >
            <div className="row">
              <div className="col-lg-6">
                {isSuccess && (
                  <Alert color="info" isOpen={visible} toggle={onDismiss}>
                    {message}
                  </Alert>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                {isError && (
                  <Alert
                    style={{
                      background: "rgb(251, 99, 71)",
                      color: "white",
                    }}
                    isOpen={visible}
                    toggle={onDismiss}
                  >
                    Error::
                    {error}
                  </Alert>
                )}
              </div>
            </div>
            <div
              className="row row-bg-2"
              style={{ padding: "7px", marginTop: "30px" }}
            >
              <div className="col-lg-6">
                <div className="form-group">
                  <div className="row">
                    <label className="col-md-4 col-form-label">
                      {"Category ID: "}
                    </label>
                    <div className="col-md-8 mb-6">
                      <input
                        type="text"
                        name="category_id"
                        onChange={formik.handleChange}
                        value={formik.values.category_id}
                        disabled={true}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <div className="row">
                    <label className="col-md-4 col-form-label">
                      {"Title: "}
                    </label>
                    <div className="col-md-8 mb-4">
                      <input
                        type="text"
                        name="title"
                        onChange={formik.handleChange}
                        value={formik.values.title}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <div className="row">
                    <label className="col-md-4 col-form-label">
                      {"Meta Description: "}
                    </label>
                    <div className="col-md-8 mb-4">
                      <input
                        type="text"
                        name="meta_description"
                        onChange={formik.handleChange}
                        value={formik.values.meta_description}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <div className="row">
                    <label className="col-md-4 col-form-label">
                      {"Meta Keyword: "}
                    </label>
                    <div className="col-md-8 mb-4">
                      <input
                        type="text"
                        name="meta_keyword"
                        onChange={formik.handleChange}
                        value={formik.values.meta_keyword}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <div className="row">
                    <label className="col-md-4 col-form-label">
                      {"Meta og Image: "}
                    </label>
                    <div className="col-md-8 mb-4">
                      <input
                        type="text"
                        name="meta_og_image"
                        onChange={formik.handleChange}
                        value={formik.values.meta_og_image}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <div className="row">
                    <label className="col-md-4 col-form-label">
                      {"Title Hindi : "}
                    </label>
                    <div className="col-md-8 mb-4">
                      <input
                        type="text"
                        name="title_hi"
                        onChange={formik.handleChange}
                        value={formik.values.title_hi}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <div className="row">
                    <label className="col-md-4 col-form-label">
                      {"Meta Description Hindi : "}
                    </label>
                    <div className="col-md-8 mb-4">
                      <input
                        type="text"
                        name="meta_description_hi"
                        onChange={formik.handleChange}
                        value={formik.values.meta_description_hi}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <div className="row">
                    <label className="col-md-4 col-form-label">
                      {"Meta Keyword Hindi: "}
                    </label>
                    <div className="col-md-8 mb-4">
                      <input
                        type="text"
                        name="meta_keyword_hi"
                        onChange={formik.handleChange}
                        value={formik.values.meta_keyword_hi}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <div className="row">
                    <label className="col-md-4 col-form-label">
                      {"Meta Og Url English: "}
                    </label>
                    <div className="col-md-8 mb-4">
                      <input
                        type="text"
                        name="meta_og_url"
                        onChange={formik.handleChange}
                        value={formik.values.meta_og_url}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <div className="row">
                    <label className="col-md-4 col-form-label">
                      {"Meta og Url Hindi: "}
                    </label>
                    <div className="col-md-8 mb-4">
                      <input
                        type="text"
                        name="meta_og_url_hi"
                        onChange={formik.handleChange}
                        value={formik.values.meta_og_url_hi}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="submit-btn" style={{ textAlign: "center" }}>
              <button
                type="submit"
                className="btn mb-2"
                style={{
                  background:
                    "linear-gradient(180deg, #DD2A12 0%, #FFA214 100%)",
                  color: "white",
                  padding: "10px",
                  width: "100px",
                }}
              >
                Submit
              </button>
            </div>
          </form>
        </section>
      </>
    );
  } else {
    window.location.href = "/";
  }
}

export default Form;
