import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import SubUploadImage from "../../../Others/UploadImage/SubUploadImage";
import { Modal, ModalHeader, ModalBody, Alert } from "reactstrap";
const index = () => {
  // token
  const token = localStorage.getItem("token");

  // useLocation and useNavigate
  const history = useLocation();
  const navigate = useNavigate();

  // state
  const [selmonth, setselmonth] = useState(history.state);
  const url = process.env.REACT_APP_BASE_URL;
  const [visible, setVisible] = useState(true);
  const [formdata, setformdata] = useState({
    nameEn: history.state.name_en,
    nameHi: history.state.name_hi,
    slug: history.state.slug,
    month: "",
    descriptionEn: history.state.description_en,
    descriptionHi: history.state.description_hi,
    imageUrl: history.state.image,
    festivalDate: ` 2024-${
      history.state.month.toString().length === 1
        ? `0${history.state.month}`
        : history.state.month
    }-${
      history.state.festival_date.toString().length === 1
        ? `0${history.state.festival_date}`
        : history.state.festival_date
    }`,
  });
  const [isSuccess, setIsSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState("");
  const [save, setSave] = useState("");
  const [modal, setModal] = useState(false);

  // functions
  const onDismiss = () => setVisible(false);
  const handleupdate = (e) => {
    const { name, value } = e.target;
    setformdata((pre) => ({
      ...pre,
      [name]: value,
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    var date = new Date(formdata.festivalDate);
    var day = date.getDate();
    var month = date.getMonth();
    const data = {
      nameHi: formdata.nameHi,
      nameEn: formdata.nameEn,
      slug: formdata.slug,
      descriptionEn: formdata.descriptionEn,
      descriptionHi: formdata.descriptionHi,
      imageUrl: save ? save : formdata.imageUrl,
      month: month + 1,
      festivalDate: day,
    };
    axios
      .post(`${url}/admin/festival-category-update`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "any",
        },
      })
      .then((res) => {
        if (res.data.code === 200) {
          setIsSuccess(true);
          setMessage("Festival category Updated Successfully");
          setTimeout(() => {
            setIsSuccess(false);
            setMessage("");
            navigate("/festivals");
          }, 3000);
        }
      })
      .catch((Err) => {
        // console.log("Error::::::::", Err);
        setIsError(true);
        setError(Err.response.data.msg);
      });
  };
  const toggle = () => setModal(!modal);
  if (token && selmonth) {
    return (
      <>
        <div>
          <div className="alert-side">
            {isSuccess && (
              <Alert color="info" isOpen={visible} toggle={onDismiss}>
                {message}
              </Alert>
            )}
          </div>
          <div className="alert-side">
            {isError && (
              <Alert
                style={{
                  background: "rgb(251, 99, 71)",
                  color: "white",
                }}
                isOpen={visible}
                toggle={onDismiss}
              >
                {error}
              </Alert>
            )}
          </div>

          <p className="name-add">Update {history.state.name_en} Festival</p>
          <form onSubmit={handleSubmit}>
            <div className="form">
              <div className="each-input">
                <label>Name En*</label>
                <input
                  required
                  type="text"
                  value={formdata.nameEn}
                  name="nameEn"
                  onChange={handleupdate}
                  placeholder="add festival name in English"
                ></input>
              </div>

              <div className="each-input">
                <label>Name Hi*</label>
                <input
                  required
                  value={formdata.nameHi}
                  name="nameHi"
                  onChange={handleupdate}
                  type="text"
                  placeholder="add festival name in Hindi"
                ></input>
              </div>

              <div className="each-input">
                <label>Slug*</label>
                <input
                  required
                  id="slect-month"
                  disabled={true}
                  style={{ background: "#f2f2f2" }}
                  value={formdata.slug}
                  name="slug"
                  onChange={handleupdate}
                  type="text"
                  placeholder="Cretae a unique slug"
                ></input>
              </div>

              <div className="each-input">
                <label>Description En*</label>
                <input
                  id="slect-month"
                  required
                  style={{ background: "#f2f2f2" }}
                  disabled={true}
                  value={formdata.descriptionEn}
                  name="descriptionEn"
                  onChange={handleupdate}
                  placeholder="add description in English"
                  type="text"
                ></input>
              </div>

              <div className="each-input">
                <label>Description Hi*</label>
                <input
                  required
                  style={{ background: "#f2f2f2" }}
                  disabled={true}
                  id="slect-month"
                  value={formdata.descriptionHi}
                  name="descriptionHi"
                  onChange={handleupdate}
                  placeholder="add description in Hindi"
                  type="text"
                ></input>
              </div>

              <div className="each-input">
                <label>Festival Date* </label>
                <input
                  required
                  value={moment(formdata.festivalDate).format("YYYY-MM-DD")}
                  name="festivalDate"
                  onChange={handleupdate}
                  type="date"
                ></input>
              </div>

              <div className="each-input">
                <label>Image url*</label>
                <input
                  id={save && "slect-month"}
                  required
                  disabled={save ? true : false}
                  value={save ? save : formdata.imageUrl}
                  name="imageUrl"
                  onChange={handleupdate}
                  placeholder="add your image url here"
                  type="text"
                ></input>
              </div>
            </div>
            <div className="add-f-parent2">
              <button type="submit" className="add-festivel">
                Update Now
              </button>
              <button
                type="button"
                onClick={() => {
                  setModal(true);
                }}
                className="add-festivel"
              >
                {save ? "Change Image" : "Upload Image"}
              </button>
            </div>
          </form>
        </div>

        <Modal isOpen={modal} toggle={toggle} id="upload-image-width">
          <ModalHeader toggle={toggle}>Upload Image On Aws</ModalHeader>
          <ModalBody>
            <div className="upload-div">
              <SubUploadImage
                setSave={setSave}
                toggle={toggle}
                parentid={"1"}
                section={true}
                sectonvalue={"festival"}
              />{" "}
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  } else {
    window.location.href = "/";
  }
};

export default index;
