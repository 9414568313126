exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".SattvikStaysAddForm_formBox__1RYBU h2 {\n  text-align: center;\n  font-size: 2rem;\n  font-weight: 700;\n  padding: 1rem 0;\n  margin-bottom: 0;\n}\n.SattvikStaysAddForm_formBox__1RYBU .SattvikStaysAddForm_inputBoxParent__3KfS0 {\n  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;\n  width: 80%;\n  padding: 1rem;\n  border-radius: 12px;\n  margin: auto;\n}\n.SattvikStaysAddForm_inputBox__7m5PC {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  grid-gap: 0.8rem;\n  gap: 0.8rem;\n}\n.SattvikStaysAddForm_inputBox__7m5PC:nth-child(1) {\n  margin-top: 1rem;\n}\n\n.SattvikStaysAddForm_inputBox__7m5PC:nth-child(2) {\n  margin-top: 0.8rem;\n}\n\n.SattvikStaysAddForm_inputBox__7m5PC div {\n  width: 50%;\n}\n\n.SattvikStaysAddForm_inputBox__7m5PC div p {\n  margin: 0 !important;\n  font-size: 1rem;\n  font-weight: 700;\n}\n\n.SattvikStaysAddForm_inputBox__7m5PC div input {\n  width: 100%;\n  border-radius: 3px;\n  border: 1px solid gray;\n  outline: none;\n  margin-top: 0.2rem;\n  padding: 0.4rem;\n}\n\n.SattvikStaysAddForm_inputBox__7m5PC div input[type='file'] {\n  border: 1px solid gray;\n  padding: 0;\n}\n\n.SattvikStaysAddForm_inputBoxSelect__1eyJh{\n    width: 100%;\n    padding: 0.4rem;;\n}\n\n.SattvikStaysAddForm_submitBtn__2cug5 {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-top: 1rem;\n}\n\n.SattvikStaysAddForm_submitBtn__2cug5 button {\n  background-color: orange;\n  color: white;\n  padding: 0.4rem 1rem;\n  border: 1px solid white;\n  border-radius: 9px;\n}\n\n\n@media screen and (max-width:480px){\n  .SattvikStaysAddForm_inputBox__7m5PC{\n    flex-direction: column;\n  }\n\n  .SattvikStaysAddForm_inputBox__7m5PC div{\n    width: 100%;\n  }\n}", ""]);

// exports
exports.locals = {
	"formBox": "SattvikStaysAddForm_formBox__1RYBU",
	"inputBoxParent": "SattvikStaysAddForm_inputBoxParent__3KfS0",
	"inputBox": "SattvikStaysAddForm_inputBox__7m5PC",
	"inputBoxSelect": "SattvikStaysAddForm_inputBoxSelect__1eyJh",
	"submitBtn": "SattvikStaysAddForm_submitBtn__2cug5"
};