import React from "react";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faImage } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
const url = process.env.REACT_APP_BASE_URL;

const Index = (props) => {
  const [open, setOpen] = useState(false);
  const [enable, setEnable] = useState(true);
  const [current, setCurrent] = useState(1);

  const [festivals, setFestivals] = useState([]);
  const history = useNavigate();
  const URL = process.env.REACT_APP_BASE_URL;

  const getCategory = async (data, language, basic) => {
    await axios
      .get(`${URL}/admin/get-festival-post?categoryId=${data.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "any",
        },
      })
      .then((result) => {
        if (basic) {
          history("/update-basic-details", {
            state: {
              data: data,
              language: language,
              pageTitle: "Festivals",
              type: 3,
            },
          });
        } else {
          history("/text-editor", {
            state: {
              data: data,
              language: language,
              content: result.data.data,
              type: 1,
            },
          });
        }
      });
  };

  const token = `Bearer ${localStorage.getItem("token")}`;
  const id = props.data;
  const FestivalByMonth = async () => {
    await axios
      .get(url + "/pg/festival/list-of-sub-category-festival", {
        params: {
          festivalId: id.id,
        },
        headers: {
          authorization: token,
          Accept: "application/json",
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "any",
        },
      })
      .then((res) => {
        setFestivals(res.data.data);
      })
      .catch((err) => {
        console.log("Error::::::::", err);
      });
  };
  useEffect(() => {
    FestivalByMonth();
  }, []);
  function reDirect(data) {
    history("/add-sub-festivals", { state: data });
  }

  if (token && token != "undefined") {
    return (
      <section className="postCountIV postCountIII">
        <div className="postCountIII">
          <div
            className="add-f-parent"
            style={{ justifyContent: "space-between" }}
          >
            <div className="status">
              {[
                { no: 1, name: "Active " },
                { no: 2, name: "Disabled " },
              ].map((e) => {
                return (
                  <button
                    onClick={() => {
                      setEnable(e.no === 1 ? true : false);
                      setCurrent(e.no);
                    }}
                    key={e.name}
                    className={current === e.no && "enable"}
                  >
                    {e.name}
                  </button>
                );
              })}
            </div>
            <a
              src="/add-sub-festivals"
              onClick={() => {
                reDirect(props.data);
              }}
            >
              <button
                style={{
                  cursor: "pointer",
                  border: "2px solid gray",
                  borderRadius: "10px",
                  justifyContent: "flex-end",
                  marginRight: "0px",
                }}
              >
                Add Sub Festivals <FontAwesomeIcon icon={faAngleDoubleRight} />
              </button>
            </a>
          </div>
          <div className="grid-container">
            {festivals && festivals.length > 0 ? (
              festivals.filter((each) => {
                return enable
                  ? each.postStatus == false
                  : each.postStatus == true;
              }).length > 0 ? (
                festivals
                  .filter((each) => {
                    return enable
                      ? each.postStatus == false
                      : each.postStatus == true;
                  })
                  .map((data, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          backgroundImage: `url(${data.image})`,
                          boxShadow: "inset 0px 0px 0px 200px rgba(0,0,0,0.25)",
                          backgroundSize: "cover",
                          backgroundPosition: "center center",
                        }}
                        //  onClick={() => { subCat(data) }}
                      >
                        <div style={{ position: "relative", height: "100%" }}>
                          <span className="imageAdd">
                            <FontAwesomeIcon
                              icon={faImage}
                              size="sm"
                              style={{ color: "white" }}
                              className="imageAddIcon"
                            />
                          </span>
                          {/* <div className="buttonContainer buttonContainerInline"> */}
                          {/* <div style={{width:"100%"}}> */}
                          <p className="span" style={{ color: "white" }}>
                            {data.name_en}{" "}
                            {/* <FontAwesomeIcon icon={faPen} className="icon" /> */}
                          </p>

                          {/* <button style={{  height: "30px", width: "70px" }} onClick={() => { subCat(data,"hi") }} >other</button> */}

                          {/* <p className='spanI'>{data.color} <FontAwesomeIcon icon={faPen} className='icon' /></p> */}
                          {/* </div> */}

                          {/* <div className='box'>
                                        <div><button className='box1'>Save</button></div>
                                        <div><button className='box2'>Special</button></div>
                                        <div><button className='box3'>Disable</button></div>
                                    </div> */}
                          {/* </div> */}
                          <div
                            style={{
                              position: "absolute",
                              width: "100%",
                              bottom: "0px",
                            }}
                          >
                            <button
                              className="boxbutton"
                              onClick={() => {
                                getCategory(data, "en");
                              }}
                            >
                              {" "}
                              English{" "}
                            </button>
                            <button
                              className="boxbutton"
                              onClick={() => {
                                getCategory(data, "hi");
                              }}
                            >
                              {" "}
                              Hindi{" "}
                            </button>
                            <button
                              className="boxbutton"
                              onClick={() => {
                                getCategory(data, "en", "basic");
                              }}
                            >
                              basic
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })
              ) : (
                <p className="no-data">No Related Data Available </p>
              )
            ) : (
              <p className="no-data">No Related Data Available </p>
            )}
          </div>
        </div>
      </section>
    );
  } else {
    window.location.href = "/";
  }
};

export default Index;
