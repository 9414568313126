import React from 'react';
import Side from '../Side'
import Navigation from '../Navigation';

import HomePage from '../HomePage';
const Admin = () => {
  const name = localStorage.getItem("user")
  const token = localStorage.getItem("token")
  const active = "active"
  
  if (token && token != 'undefined') {
    const pageTitle = 'Dashboard'
    return (
      <>
        <div className='main-parentcontainer'>
          <div className='grid-parent'>
            <Side data={name} dashboard={active} />
            <div className='grid-child'>
              <Navigation data={pageTitle} />

              <HomePage />
            </div>
          </div>
        </div>


        {/* <HomePage /> */}
      </>
    )
  }
  else {
    window.location.href = "/"
  }

}

export default Admin