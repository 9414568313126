import React from "react";
import { useNavigate } from "react-router-dom";
import data1 from "../../../Data";
const img2 = require("../../../../assets/img/defaultimage.jpg");

const ReportList = () => {
  // states
  const data = data1.data1;

  // useNavigate
  const history = useNavigate();

  // functions
  const handleAccount = (values) => {
    history("/report-account-detail", { state: values });
  };
  return (
    <section className="postCountVII accountmobcorrect">
      <div className="accountBox">
        {data.map((data, index) => {
          return (
            <div className="" key={index} onClick={() => handleAccount(data)}>
              <img src={img2} alt="profilePic" className="profilePic" />
              <p className="profileName">{data.name}</p>
              <p>{data.userId}</p>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default ReportList;
