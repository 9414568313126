import React from "react";
import style from "./notfound.module.css";
import { Link } from "react-router-dom";
const index = () => {
  return (
    <div className={style.notfoundbox}>
      <div className={style.linkbox}>
        <h3>Page Do Not Exist</h3>
        <Link to="/dashboard">Go To Home</Link>
      </div>
    </div>
  );
};

export default index;
