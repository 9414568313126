import { pilgrim_tours_dashboard } from "../../../store/the-pilgrim-tours";
import React, { useEffect, useState } from "react";
import style from "../../SattvikStays/Dashboard/sattvikStaysDashboard.module.css";
import DashboardCards from "../../SattvikStays/Dashboard/DashboardCards";
import {
  faClipboard,
  faUsers,
  faEnvelopesBulk,
} from "@fortawesome/free-solid-svg-icons";
import { faFilter, faCircleCheck } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const Dashboard = () => {
  const [days, setdays] = useState(1);
  const [totalData, setTotalData] = useState([]);
  const [daysByData, setDaysByData] = useState([]);
  const [filterBox, setOpenFIlterBox] = useState(false);

  const iconArray = {
    totalEnquiryCount: faEnvelopesBulk,
    totalPackageCount: faClipboard,
    enquiryCountByDays: faEnvelopesBulk,
    totalUserCount: faUsers,
    totalActiveUserCount: faUsers,
    totalSuspendedUserCount: faUsers,
    totalPendingUserCount: faUsers,
    userPendingCountDays: faUsers,
    userCountByDays: faUsers,
    userActiveCountByDays: faUsers,
  };
  const nameArray = {
    totalEnquiryCount: "Total Enquire Count",
    totalPackageCount: "Package Count",
    totalUserCount: "Total Users",
    totalActiveUserCount: "Total Active Users",
    totalPendingUserCount: "Total Pending Users",
    totalSuspendedUserCount: "Total Suspended Users",
    enquiryCountByDays: "Enquire",
    userPendingCountDays:"Pending User",
    userCountByDays:"User count",
    userActiveCountByDays:"Active Users"
  };
  const colorArray = {
    totalEnquiryCount: "#16C47F",
    totalPackageCount: "#006BFF",
    totalActiveUserCount: "#0A3981",
    totalUserCount: "#001A6E",
    totalPendingUserCount: "#E38E49",
    totalSuspendedUserCount: "#72BAA9",
    enquiryCountByDays: "#EC8305",
    userPendingCountDays:"#0A1981",
    userCountByDays:"#0A3981",
    userActiveCountByDays:"#0A9981"
  };

  const bydays = ["1 Days", "1 Week", "1 Month", "1 Year"];
  const daysParams = {
    "1 Days": 1,
    "1 Week": 7,
    "1 Month": 31,
    "1 Year": 365,
  };
  const [currentFilterType, setCurrentFilterType] = useState("1 Days");
  const getDashboardData = async () => {
    let params = daysParams[currentFilterType];
    const response = await pilgrim_tours_dashboard({ params });
    if (response.status === 200) {
      const wantedFormOfTotalCount = Object.keys(
        response.data.data.totalCount
      ).map((key) => ({ [key]: response.data.data.totalCount[key] }));

      const newArrayOfTotalCount = wantedFormOfTotalCount.map((each) => {
        const keyValue = Object.keys(each)[0];
        const value = Object.values(each)[0];

        return {
          icon: iconArray[keyValue],
          name: nameArray[keyValue],
          number: value,
          color: colorArray[keyValue],
        };
      });
      setTotalData(newArrayOfTotalCount);
      const wantedFormOfDaysCount = Object.keys(
        response.data.data.countByDays
      ).map((key) => ({ [key]: response.data.data.countByDays[key] }));
      const newArrayOfDaysCount = wantedFormOfDaysCount.map((each) => {
        const keyValue = Object.keys(each)[0];
        const value = Object.values(each)[0];

        return {
          icon: iconArray[keyValue],
          name: nameArray[keyValue],
          number: value,
          color: colorArray[keyValue],
        };
      });
      setDaysByData(newArrayOfDaysCount);
    }
  };

  useEffect(() => {
    getDashboardData();
  }, []);

  // console.log("dashboardData", dashboardData);

  return (
    <div className={style.Dashboard}>
      <div className={style.welcomeBox}>
        <div className={style.leftBox}>
          <p> Dashboard</p>
        </div>
      </div>
      <div>
        <div>
          <p className={style.tabsHeading}>Statics</p>
        </div>
        <div className={style.topCardSections}>
          {totalData.length > 0
            ? totalData.map((each) => {
                return (
                  <DashboardCards
                    icon={each.icon}
                    name={each.name}
                    number={each.number}
                    color={each.color}
                  />
                );
              })
            : [1, 2, 3, 4, 5, 6].map((each) => {
                return <div key={each} className={style.skleton}></div>;
              })}
        </div>
      </div>
      <div className={style.btmTabs}>
        <div className={style.filterBoxParent}>
          <p className={style.tabsHeading}>
            Total data by last {currentFilterType}
          </p>
          {filterBox && (
            <div className={style.filterbox}>
              <ul>
                {bydays.map((each) => {
                  return (
                    <li
                      onClick={() => {
                        setCurrentFilterType(each);
                        setOpenFIlterBox(false);
                      }}
                      className={
                        each === currentFilterType && style.activeAccountType
                      }
                    >
                      <FontAwesomeIcon
                        color={each === currentFilterType ? "green" : "grey"}
                        icon={faCircleCheck}
                      />
                      <span>{each}</span>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
          <FontAwesomeIcon
            icon={faFilter}
            style={{ cursor: "pointer", marginRight: "1rem" }}
            onClick={() => {
              setOpenFIlterBox(!filterBox);
            }}
          />
        </div>
        <div className={`${style.topCardSections}`}>
          {daysByData.length > 0
            ? daysByData.map((each) => {
                return (
                  <DashboardCards
                    icon={each.icon}
                    name={each.name}
                    number={each.number}
                    color={each.color}
                  />
                );
              })
            : [1, 2, 3, 4, 5].map((each) => {
                return <div key={each} className={style.skleton}></div>;
              })}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
