import React, { useEffect } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import AddNewBlog from './components/Admin/Blog/AddNewBlog'
import './App.css'
import { jwtDecode } from 'jwt-decode'
// Components
import HomePage from './components/Home'
import Layout from './components/Layout'
import BlogContent from './components/Admin/Blog/BlogContent'
import Dashboard from './components/Admin/Dashboard'
import UserAccountList from './components/Admin/UserAccounts'
import UserAccountDetails from './components/Admin/UserAccounts/UserAccountDetails'
import ReportAccounts from './components/Admin/Reports/ReportAccount'
import ReportPosts from './components/Admin/Reports/Post'
import ReportShorts from './components/Admin/Reports/Short'
import ReportHashTags from './components/Admin/Reports/HashTags'
import Categories from './components/Admin/Categories'
import Festivals from './components/Admin/PGC/Festivals'
import FestivalscategoryUpdate from './components/Admin/PGC/FestivalscategoryUpdate'
import Rituals from './components/Admin/PGC/Divotion/Rituals'
import VratKatha from './components/Admin/PGC/Divotion/VratKatha'
import Mantra from './components/Admin/PGC/ChantLife/Mantra'
import Chalisa from './components/Admin/PGC/ChantLife/Chalisa'
import Aarti from './components/Admin/PGC/ChantLife/Aarti'
import Video from './components/Admin/PGC/Video'
import SingleCategoryDetails from './components/Admin/Categories/SingleCategoryDetails'
import AddCategory from './components/Admin/Categories/AddSubCategory'
import FestivalSub from './components/Admin/PGC/Tabs/FestivalSubCategory'
import AddFestivalSub from './components/Admin/PGC/AddFestivalSub'
import TextEditor from './components/TextEditor'
import LiveDarshan from './components/Admin/LiveDarshan'
import EditLiveDarshan from './components/Admin/LiveDarshan/EditLiveDarshan'
import AddLiveDarshan from './components/Admin/LiveDarshan/AddLiveDarshan'
import EditSeoFestival from './components/Admin/Festival/EditSeoFestival'
import AddSeoFestival from './components/Admin/Festival/AddSeoFestival'
import CatagorySeo from './components/Admin/Festival/CatagorySeo'
import WithOutSeo from './components/Admin/WithOutSeo/index'
import EditVideo from './components/Admin/PGC/Video/EditVideo'
import AddVideo from './components/Admin/PGC/Video/AddVideo'
import ChantLife from './components/Admin/ChantLife/index'
import EditChantLife from './components/Admin/ChantLife/EditChantLife'
import AddChantLifePost from './components/Admin/ChantLife/AddChantLife'
import BasicDetail from './components/Admin/PGC/Tabs/FestivalSubCategory/basicDetails'
import BlogHi from './components/Admin/Blog/BlogHi'
import BlogEn from './components/Admin/Blog/BlogEn'
import Wellness from './components/Admin/Wellness'
import TrendingBlogs from './components/Admin/Blog/TrendingBlog'
import UploadImage from './components/Others/UploadImage'
// Pilgrim Tours
import PilgrimToursDashboard from './components/PilgrimTours/Dashboard'
// Sattvik Stays
import SattvikStaysDashboard from './components/SattvikStays/Dashboard'
// CSS
import './assets/css/home.css'
// Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css'
import UpdateForm from './components/Others/UpdateForm'
import UserAccounts from './components/SattvikStays/UserAccounts'
import NotFound from './components/My404Component'
import Cities from './components/SattvikStays/Cities'
import Hotels from './components/SattvikStays/Hotels'
import AddCityForm from './components/SattvikStays/Cities/AddCityForm'
import EditCityForm from './components/SattvikStays/Cities/EditCity'
import AddHoteelForm from './components/SattvikStays/Hotels/AddHotel'
import EditHotel from './components/SattvikStays/Hotels/EditHotel'
import Enquiry from "./components/SattvikStays/Enquiry"
import PilgrimUser from "./components/PilgrimTours/PilgrimUser";
import ToursCategories from "./components/PilgrimTours/ToursCategories";
import SubCategories from "./components/PilgrimTours/SubCategories";
import TourDetails from "./components/PilgrimTours/TourDetails";
import AddTourCategory from "./components/PilgrimTours/ToursCategories/AddCategories"
import EditPackages from "./components/PilgrimTours/Packages/EditPackages"
import PilgrimEnquiry from "./components/PilgrimTours/PilgrimEnquiry";
import Packages from "./components/PilgrimTours/Packages";
import AddPackages from "./components/PilgrimTours/Packages/AddPackages";
// unwanted Imports
// import Createnewblog from "./components/Admin/PGC/Tabs/FestivalSubCategory/Createnew";
// import PlayVideo from "./components/playVideo";
// import Form from "./components/Form";
// import ReportAccountDetail from "./components/Admin/Reports/ReportAccount/ReportAccountDetail";
// import AartiSubCat from "./components/Admin/PGC/ChantLife/AartiSubCat";
// import Test from "./components/Admin/Categories/Category";
// import AddFestivals from "./components/Admin/PGC/AddFestivals";
// import NewUserList from "./components/Admin/NewUserList";
// import LiveFestival from "./components/Admin/Festival";

// import HinduFestivals from "./components/Admin/PGC/Video/HinduFestivals";
// import Temples from "./components/Admin/PGC/Video/Temples";
// import VratKathaVideo from "./components/Admin/PGC/Video/VratKatha";
// import PrayersMantras from "./components/Admin/PGC/Video/PrayersMantras";
// import PopularVideo from "./components/Admin/PGC/Video/PopularVideo";
// import More from "./components/Admin/PGC/Video/More";
// import Blogs from "./components/Admin/PGC/Blogs";
// import Profile from "./components/Profile";
// import HinduFestivalDates from "./components/Admin/HinduFestivalDates";
// import EditHinduFestivals from "./components/Admin/HinduFestivalDates/EditHinduFestivals";
// import AddHinduFestivalDates from "./components/Admin/HinduFestivalDates/AddHinduFestivalDates";
// import About from "./components/About";
function App () {
  const token = localStorage.getItem('token')

  useEffect(() => {
    let result
    let decodedToken = token ? jwtDecode(token) : ''
    let currentDate = new Date()

    // JWT exp is in seconds
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      console.log('Token expired.')
      localStorage.removeItem('token', '')
      window.location.href = '/'
    } else {
      console.log('Valid token')
      result = true
    }
  }, [])

  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          {/* 1. Login Page Cleaned */}
          <Route path='/' element={<HomePage />} />
          {/* 2. Dashboard Page Cleaned */}
          <Route path='/dashboard' element={<Dashboard />} />
          {/* 3. User Accounts  */}
          <Route path='/user-accounts-list' element={<UserAccountList />} />
          {/* 4. Add English Blog Cleaned */}
          <Route path='/add-new-blog' element={<AddNewBlog />} />
          {/* 5. English Blog List Cleaned   */}
          <Route path='/blog-en' element={<BlogEn />} />
          {/* 6. Hindi Blog List Cleanded */}
          <Route path='/blog-hi' element={<BlogHi />} />
          {/* 7. Trending Blogs Cleaned*/}
          <Route path='/trending-blogs' element={<TrendingBlogs />} />
          {/* 8. Upload Image cleaned*/}
          <Route path='/upload-image' element={<UploadImage />} />
          {/* 9. Wellness cleaned */}
          <Route path='/wellness' element={<Wellness />} />
          {/* 10.  Basic Details Cleaned */}
          <Route path='/basic-details' element={<BasicDetail />} />
          {/* 10. New Update Basic Details Cleaned */}
          <Route path='/update-basic-details' element={<UpdateForm />} />
          {/* 11. Chant Life cleaned */}
          <Route path='/chant-life' element={<ChantLife />} />
          {/* 12. Edit Chant life cleaned*/}
          <Route path='/edit-chant-life' element={<EditChantLife />} />
          {/* 13. Add Chant Life Posts cleaned*/}
          <Route path='/add-chant-life-post' element={<AddChantLifePost />} />
          {/* 14. Update Blog Content cleaned */}
          <Route path='/update-blog-content' element={<BlogContent />} />
          {/* 15. Singl e User Detail cleaned */}
          <Route path='/single-user-details' element={<UserAccountDetails />} />
          {/* 16. Report Accounts cleaned*/}
          <Route path='/report-accounts' element={<ReportAccounts />} />
          {/* 17. Report Posts cleaned */}
          <Route path='/report-posts' element={<ReportPosts />} />
          {/* 18. Report Shorts cleaned */}
          <Route path='/report-shorts' element={<ReportShorts />} />
          {/* 19. Report Report Hash Tags cleaned*/}
          <Route path='/report-hash-tags' element={<ReportHashTags />} />
          {/* 20. Categories cleaned */}
          <Route path='/categories' element={<Categories />} />
          {/* 21. Festivals cleaned */}
          <Route path='/festivals' element={<Festivals />} />
          {/* 22. Festival Categories Update Cleaned*/}
          <Route
            path='/festival-category-update'
            element={<FestivalscategoryUpdate />}
          />
          {/* 23. Rituals cleaned */}
          <Route path='/rituals' element={<Rituals />} />
          {/* 24. Vart Katha cleaned */}
          <Route path='/vratKatha' element={<VratKatha />} />
          {/* 25. Mantra cleaned */}
          <Route path='/mantra' element={<Mantra />} />
          {/* 27. Aarti cleaned */}
          <Route path='/aarti' element={<Aarti />} />
          {/* 28. Videos cleaned*/}
          <Route path='/videos' element={<Video />} />
          {/* 29. Edit Vedio cleaned */}
          <Route path='/edit-video' element={<EditVideo />} />
          {/* 30. Add Vedio cleaned*/}
          <Route path='/add-video' element={<AddVideo />} />
          {/* 31. Add Category cleaned */}
          <Route path='/add-category' element={<AddCategory />} />
          {/* 32. Signle Category Details Cleaned */}
          <Route
            path='/single-category-details'
            element={<SingleCategoryDetails />}
          />
          {/* 33. Sub Festivals cleaned */}
          <Route path='/sub-festival' element={<FestivalSub />} />
          {/* 34. Add Sub Festivals  cleaned */}
          <Route path='/add-sub-festivals' element={<AddFestivalSub />} />
          {/* 35. Live Darshan  cleaned*/}
          <Route path='/live-darshan' element={<LiveDarshan />} />
          {/* 36. Add New Live Darshan cleaned*/}
          <Route
            path='/add-new-live-darshan-category'
            element={<AddLiveDarshan />}
          />
          {/* 37. Category Seo cleaned*/}
          <Route path='/catagory-seo' element={<CatagorySeo />} />
          {/* 38. Edit seo  cleaned*/}
          <Route path='/edit-seo' element={<EditSeoFestival />} />
          {/* 39. Without Seo cleaned*/}
          <Route path='/without-seo' element={<WithOutSeo />} />
          {/* 40. Add Seo Festivals cleaned*/}
          <Route path='/add-seo-festival' element={<AddSeoFestival />} />
          {/* 41. Text editor token cleaned */}
          <Route path='/text-editor' element={<TextEditor />} />
          {/* 42. Edit Live Darshan  cleaned*/}
          <Route path='/edit-live-darshan' element={<EditLiveDarshan />} />
          <Route path='/not-found' element={<NotFound />} />

          <Route path='*' element={<Navigate to='/not-found' />} />
          {/* Pilgrim Tours Routes */}
          <Route
            path='/pilgrim-tours/dashboard'
            element={<PilgrimToursDashboard />}
          />
          <Route
            path="/pilgrim-tours/user-accounts"
            element={<PilgrimUser />}
          />
          <Route
            path="/pilgrim-tours/user-enquiries"
            element={<PilgrimEnquiry />}
          />
          <Route
            path="/pilgrim-tours/tours-categories"
            element={<ToursCategories />}
          />
          <Route
            path="/pilgrim-tours/add-categories"
            element={<AddTourCategory />}
          />
          <Route
            path="/pilgrim-tours/tours-categories/:categoryId"
            element={<SubCategories />}
          />
          <Route
            path="/pilgrim-tours/add-packages"
            element={<AddPackages />}
          />
          <Route
            path="/pilgrim-tours/:packageId"
            element={<EditPackages />}
          />
          <Route
            path="/pilgrim-tours/packages"
            element={<Packages />}
          />
          {/* Sattvik Stays Routes */}
          <Route
            path='/sattvik-stays/dashboard'
            element={<SattvikStaysDashboard />}
          />
          <Route
            path='/sattvik-stays/user-accounts'
            element={<UserAccounts />}
          />
          <Route path='/sattvik-stays/cities' element={<Cities />} />
          <Route path='/sattvik-stays/add-city' element={<AddCityForm />} />
          <Route path='/sattvik-stays/edit-city' element={<EditCityForm />} />
          <Route path='/sattvik-stays/edit-hotel' element={<EditHotel />} />
          <Route path='/sattvik-stays/hotels' element={<Hotels />} />
          <Route path='/sattvik-stays/add-hotel' element={<AddHoteelForm />} />
          <Route path='/sattvik-stays/enquiry' element={<Enquiry />} />
          {/* Not in use now */}
          {/* <Route path="/more-video" element={<More />} />
            <Route path="/popular-video" element={<PopularVideo />} />
            <Route path="/prayers-mantras" element={<PrayersMantras />} />
            <Route path="/temples" element={<Temples />} />
             <Route path="/live-festival" element={<LiveFestival />} />
            <Route path="/add-festivals" element={<AddFestivals />} />
            <Route path="/about" element={<About />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/test" element={<Test />} />
            <Route path="/form" element={<Form />} />
            <Route path="/vrat-katha-video" element={<VratKathaVideo />} />
            <Route path="/add-new-hindi-blog" element={<Createnewblog />} />
            <Route path="/add-new-english-blog" element={<Createnewblog />} />
            <Route path="/hindu-festivals" element={<HinduFestivals />} />
            <Route path="/blogs" element={<Blogs />} />
                <Route path="/aarti-sub-category" element={<AartiSubCat />} />
             <Route path="/play-video" element={<PlayVideo />} />
              <Route path="/new-user-list" element={<NewUserList />} />
            <Route
              path="/report-account-detail"
              element={<ReportAccountDetail />}
            />
            <Route
              path="/hindu-date-and-festival"
              element={<HinduFestivalDates />}
            />
            <Route
              path="/edit-hindu-date-and-festival"
              element={<EditHinduFestivals />}
            />
            <Route
              path="/add-new-hindu-date-and-festival"
              element={<AddHinduFestivalDates />}
            /> */}
          {/* 26. Chalisa cleaned */}
          {/* <Route path="/chalisa" element={<Chalisa />} /> */}
        </Routes>
      </Layout>
    </BrowserRouter>
  )
}

export default App
