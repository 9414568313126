import React, { useEffect, useState } from 'react'
import { Button } from 'reactstrap'
import Heading from '../CommonComponents/Heading'
import {
  sattvik_get_all_hotel_api,
  sattvik_get_all_city_api
} from '../../../store/sattvik-stays'
import style from './SattvikStaysHotels.module.css'
import {
  faFilter,
  faCircleCheck,
  faStar,
  faPen
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom'
const Hotels = () => {
  const Navigate = useNavigate()
  const [active, setActive] = useState(true)
  const [hotels, setHotels] = useState([])
  const [filter, setFilter] = useState('')
  const [userType, setUsertype] = useState('All')
  const [filterBox, setOpenFIlterBox] = useState(false)
  const accountType = [
    'All',
    'hotel',
    'dharamshala',
    'guest house',
    'home stay'
  ]
  const [cities, setCities] = useState([])
  const getCities = async () => {
    const response = await sattvik_get_all_city_api()

    if (response.data.status == 'success') {
      setCities(response.data.data)
    }
  }
  useEffect(() => {
    getCities()
  }, [])

  const getAllHotels = async () => {
    const response = await sattvik_get_all_hotel_api(filter)
    if (response.data.status == 'success') {
      setHotels(response.data.data)
    }
  }
  useEffect(() => {
    getAllHotels()
  }, [filter])
  return (
    <div>
      <Heading
        filter={true}
        title={'Hotels'}
        text={'Add New'}
        link='/sattvik-stays/add-hotel'
        filterBox={filterBox}
        setFilter={setFilter}
      >
        <div className={style.hotels}>
          <div className={style.filterAndSearch}>
            <FontAwesomeIcon
              icon={faFilter}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setOpenFIlterBox(!filterBox)
              }}
            />
            {filterBox && (
              <div className={style.filterbox}>
                <div className={style.moreFilter}>
                  <div>
                    <ul>
                      {accountType.map(each => {
                        return (
                          <li
                            onClick={() => {
                              setUsertype(each)
                              setFilter(each === 'All' ? '' : each)
                              setOpenFIlterBox(false)
                            }}
                            className={
                              each === userType && style.activeAccountType
                            }
                          >
                            <FontAwesomeIcon
                              color={each === userType ? 'green' : 'grey'}
                              icon={faCircleCheck}
                            />
                            <span>{each}</span>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Heading>
      <div className='d-flex gap-2 p-sm-0 px-3'>
        <Button
          onClick={() => {
            setActive(true)
          }}
          className={`${active ? 'bg-warning' : 'bg-white text-black'} px-4`}
        >
          Active
        </Button>
        <Button
          onClick={() => {
            setActive(false)
          }}
          className={`${!active ? 'bg-warning' : 'bg-white text-black'} px-4`}
        >
          Disable
        </Button>
      </div>
      <div className='row overflow-x-hidden'>
        {active
          ? hotels &&
            hotels
              .filter(each => {
                return each.isActive === true
              })
              .map((e, i) => (
                <div className='col-11  col-sm-6 col-md-4  m-auto' key={i}>
                  <div
                    className={`${i > 2 && 'mt-sm-3'} mt-2`}
                    style={{
                      width: '100%',
                      borderStartEndRadius: '5px',
                      borderStartStartRadius: '5px',
                      overflow: 'hidden',
                      position: 'relative',
                      height: '220px'
                    }}
                  >
                    <div
                      className='position-relative'
                      style={{
                        width: '100%',
                        height: '100%',
                        boxShadow: '0px 0px 4px 4px rgba(0,0,0,.5)'
                      }}
                    >
                      <img
                        src={e.image}
                        alt={e.slug}
                        style={{
                          width: '100%',
                          height: '100%'
                        }}
                      />

                      <div
                        onClick={() => {
                          Navigate('/sattvik-stays/edit-hotel', {
                            state: {
                              data: e
                            }
                          })
                        }}
                        className='position-absolute top-0 end-0 pe-1 pt-1 z-3 hotels-pen'
                      >
                        <FontAwesomeIcon
                          style={{ cursor: 'pointer' }}
                          icon={faPen}
                          className='me-2  '
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        position: 'absolute',
                        maxWidth: '90%',
                        zIndex: '1051',
                        bottom: 0,
                        color: 'white',
                        transform: 'translateX(-50%)',
                        left: '50%',
                        textAlign: 'center',
                        borderRadius: '6px 6px 0 0',
                        padding: '5px'
                      }}
                    ></div>
                  </div>
                  <div className={style.hotelBtmContent}>
                    <h2 className={style.hotelName}> {e.name}</h2>
                    <div className={style.ratingbox}>
                      <p  style={{ fontSize: '12px' }}>
                        {e.userRating} (
                        <FontAwesomeIcon
                          color='orange'
                          style={{ fontSize: '10px' }}
                          // height={20}
                          // width={20}
                          icon={faStar}
                        />
                        )
                      </p>
                      <p>{e.cityDetails.city} </p>
                    </div>
                    <div className={style.addresHeight}>
                      <p className='fs-6'>{e.address}</p>
                    </div>
                    {/* <div className="d-flex  align-items-center">
                  <Button className="ms-auto bg-warning bg-gradient border-0">
                    <FontAwesomeIcon icon={faPen} className="me-2 " />
                    Edit
                  </Button>
                </div> */}
                  </div>
                </div>
              ))
          : hotels &&
            hotels
              .filter(each => {
                return each.isActive === false
              })
              .map((e, i) => (
                <div className='col-11  col-sm-4 m-auto' key={i}>
                  <div
                    className={`${i > 2 && 'mt-sm-3'} mt-2`}
                    style={{
                      width: '100%',
                      borderStartEndRadius: '5px',
                      borderStartStartRadius: '5px',
                      overflow: 'hidden',
                      position: 'relative',
                      height: '220px'
                    }}
                  >
                    <div
                      className='position-relative'
                      style={{
                        width: '100%',
                        height: '100%',
                        boxShadow: '0px 0px 4px 4px rgba(0,0,0,.5)'
                      }}
                    >
                      <img
                        src={e.image}
                        alt={e.slug}
                        style={{
                          width: '100%',
                          height: '100%'
                        }}
                      />

                      <div
                        onClick={() => {
                          Navigate('/sattvik-stays/edit-hotel', {
                            state: {
                              data: e
                            }
                          })
                        }}
                        className='position-absolute top-0 end-0 pe-1 pt-1 z-3 hotels-pen'
                      >
                        <FontAwesomeIcon
                          style={{ cursor: 'pointer' }}
                          icon={faPen}
                          className='me-2  '
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        position: 'absolute',
                        maxWidth: '90%',
                        zIndex: '1051',
                        bottom: 0,
                        color: 'white',
                        transform: 'translateX(-50%)',
                        left: '50%',
                        textAlign: 'center',
                        borderRadius: '6px 6px 0 0',
                        padding: '5px'
                      }}
                    ></div>
                  </div>
                  <div className={style.hotelBtmContent}>
                    <h2 className={style.hotelName}> {e.name}</h2>
                    <div className={style.ratingbox}>
                      <p>
                        {e.userRating} (
                        <FontAwesomeIcon color='orange' icon={faStar} />)
                      </p>
                      <p>{e.cityDetails.city} </p>
                    </div>
                    <div className={style.addresHeight}>
                      <p className='fs-6'>{e.address}</p>
                    </div>
                    {/* <div className="d-flex  align-items-center">
                  <Button className="ms-auto bg-warning bg-gradient border-0">
                    <FontAwesomeIcon icon={faPen} className="me-2 " />
                    Edit
                  </Button>
                </div> */}
                  </div>
                </div>
              ))}
      </div>
    </div>
  )
}

export default Hotels
