import React from "react";
import Navigation from "../../Navigation";
import Sidebar from "../../Side";
import HindiBlog from "./HindiBlog";
const Index = () => {
  const token = localStorage.getItem("token");
  const pageTitle = "Hindi Blog   ";
  const active = "active";
  const name = "Sprituality";
  if (token && token != "undefined") {
    return (
      <>
        <div className="main-parentcontainer">
          <div className="grid-parent">
            <Sidebar data={name} pgc={active} />
            <div className="grid-child">
              {" "}
              <Navigation data={pageTitle} />
              <HindiBlog />
            </div>
          </div>
        </div>
      </>
    );
  } else {
    window.location.href = "/";
  }
};

export default Index;
