import React from "react";
import Tabs from "../../Tabs/Tabs";
import Month from "../Tabs/Month";
import { useState, useEffect } from "react";
import { Tab } from "bootstrap";

const Festivals = () => {
  let months = [
    { id: 1, name: "January" },
    { id: 2, name: "February" },
    { id: 3, name: "March" },
    { id: 4, name: "April" },
    { id: 5, name: "May" },
    { id: 6, name: "June" },
    { id: 7, name: "July" },
    { id: 8, name: "August" },
    { id: 9, name: "September" },
    { id: 10, name: "October" },
    { id: 11, name: "November" },
    { id: 12, name: "December" },
  ];
  const [curmonth, setCurMonth] = useState(() => {
    // Load initial state from localStorage, if available
    const savedValue = localStorage.getItem("myValue");
    return savedValue !== null ? JSON.parse(savedValue) : 1;
  });

  // let className = `tab-list-item ${tab - list - active}`;
  useEffect(() => {
    // Save state to localStorage whenever `value` changes

    localStorage.setItem("myValue", JSON.stringify(curmonth));
  }, [curmonth]);

  return (
    <section className="postCountIV" style={{ overflowY: "scroll" }}>
      {/* <Tabs> */}
      <div>
        <ol className="tab-list">
          {months.map((child) => {
            return (
              <li
                onClick={() => {
                  setCurMonth(child.id);
                }}
                className={`tab-list-item ${
                  curmonth === child.id && "tab-list-active"
                }`}
              >
                {child.name}
              </li>
            );
          })}
        </ol>

        {months &&
          months.length > 0 &&
          months
            .filter((each) => {
              return each.id === curmonth;
            })
            .map((items, index) => {
              return (
                <div key={index} label={items.name}>
                  <Month data={curmonth} />
                </div>
              );
            })}
      </div>
      {/* </Tabs> */}
    </section>

    // <section className='postCountIV'>
    //   <Tabs>
    //     <div label="January">
    //       <January />
    //     </div>
    //     <div label="February">
    //       <February />
    //     </div>
    //     <div label="March">
    //       <March />
    //     </div>
    //     <div label="April">
    //       <April />
    //     </div>
    //     <div label="May">
    //       <May />
    //     </div>
    //     <div label="June">
    //       <June />
    //     </div>
    //     <div label="July">
    //       <July />
    //     </div>
    //     <div label="August">
    //       <August />
    //     </div>
    //     <div label="September">
    //       <September />
    //     </div>
    //     <div label="October">
    //       <October />
    //     </div>
    //     <div label="November">
    //       <November />
    //     </div>
    //     <div label="December">
    //       <December />
    //     </div>
    //   </Tabs>
    // </section>
  );
};

export default Festivals;
