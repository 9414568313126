import React from "react";
import Tabs from "../../../Tabs/Tabs";
import Aarti from "../../ChantLife/AartiAllCat";

const AartiCategory = () => {
  let Aartis = [{ id: 302, name: "God Aarti" }];

  return (
    <section className="postCountIV">
      <Tabs>
        {Aartis &&
          Aartis.length > 0 &&
          Aartis.map((items, index) => {
            return (
              <div key={index} label={items.name}>
                <Aarti data={items.id} />
              </div>
            );
          })}
      </Tabs>
    </section>
  );
};

export default AartiCategory;
