import React from "react";
import EnglishBlog from "./EnglishBlog";

const Index = () => {
  const token = localStorage.getItem("token");

  if (token && token != "undefined") {
    return <EnglishBlog />;
  } else {
    window.location.href = "/";
  }
};

export default Index;
