import React from "react";

import SubCategory from "./SubCategory";
const Index = () => {
  const token = localStorage.getItem("token");

  if (token) {
    return (
      <>
        <SubCategory />
      </>
    );
  } else {
    window.location.href = "/";
  }
};

export default Index;
