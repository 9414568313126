import React, { useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import SeoForm from "./SeoForm";
import SeoFestivalform from "../AddNewBlog/SeoFestivalform";
import { Label, Input, Button, Form, Row, Col, Alert } from "reactstrap";
const index = () => {
  // States
  const [curCat, setCurCategory] = useState(2736);
  const token = localStorage.getItem("token");
  const history = useLocation();
  const [createdSeoData, setCreatedSeoData] = useState({
    id: "",
    post_id: "",
    title: "",
    description: "",
    keyword: "",
    image: "",
  });
  const [createdFestivalSeoData, setCreatedFestivalSeoData] = useState({
    id: "",
    categoryId: "",
    title: "",
    title_hi: "",
    description: "",
    description_hi: "",
    keyword: "",
    keyword_hi: "",
    url: "",
    url_hi: "",
    image: "",
  });
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const [formSeoPart, setFormSeoPart] = useState(false);
  const onDismiss = () => setVisible(false);
  const [visible, setVisible] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [selmonth, setselmonth] = useState(history.state.data);
  const [error, setError] = useState("");
  let months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const URL = process.env.REACT_APP_BASE_URL;
  const selectMonOption = [
    { value: 1, label: "January" },
    { value: 2, label: "February" },
    { value: 3, label: "March" },
    { value: 4, label: "April" },
    { value: 5, label: "May" },
    { value: 6, label: "June" },
    { value: 7, label: "July" },
    { value: 8, label: "August" },
    { value: 9, label: "September" },
    { value: 10, label: "October" },
    { value: 11, label: "November" },
    { value: 12, label: "December" },
  ];
  const selectyearOption = [
    { value: 2024, label: "2024" },
    { value: 2025, label: "2025" },
    { value: 2026, label: "2026" },
    { value: 2027, label: "2027" },
    { value: 2028, label: "2028" },
    { value: 2029, label: "2029" },
    { value: 2030, label: "2030" },
    { value: 2031, label: "2031" },
    { value: 2032, label: "2033" },
    { value: 2033, label: "2033" },
    { value: 2034, label: "2034" },
    { value: 2035, label: "2035" },
  ];

  // Function
  const formik = useFormik({
    initialValues: {
      categoryId: history.state.category ? "" : history.state.data[0].id,
      title: history.state.category ? "" : history.state.data.title,
    },

    onSubmit: (values) => {
      if (history.state.type == 1) {
        CreateNewEnglishBlog(values);
      }
      if (history.state.type == 2) {
        CreateNewHindiBlog(values);
      }
      if (history.state.type == 3) {
        CreateFestivalCategory(values);
      }
    },
  });
  const CreateFestivalCategory = (values) => {
    const formData = new FormData();
    var date = new Date(formik.values.festivalDate);
    var day = date.getDate();
    const data = {
      nameHi: formik.values.nameHi,
      nameEn: formik.values.nameEn,
      slug: formik.values.slug,
      descriptionEn: formik.values.descriptionEn,
      descriptionHi: formik.values.descriptionHi,
      image: formik.values.image,
      month: selmonth,
      festivalDate: day,
    };

    for (let key in data) {
      formData.append(key, data[key]);
    }
    setSubmitInProgress(true);
    axios
      .post(`${URL}/admin/add-festival-category`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "any",
        },
      })
      .then((result) => {
        if (result) {
          setIsSuccess(true);
          setMessage("Festival Category Created Successfully Please Wait");
          setTimeout(() => {
            setIsSuccess(false);
            setMessage("");
            setCreatedFestivalSeoData(result.data.newFestCategorySeo);
            setSubmitInProgress(false);
            setFormSeoPart(true);
          }, 3000);
        }
      })
      .catch((err) => {
        setSubmitInProgress(false);
        setIsError(true);
        setError(err.response.data.msg);
        setTimeout(() => {
          setIsError(false);
          setError("");
        }, 3000);
      });
  };
  const CreateNewHindiBlog = (values) => {
    const formdata = new FormData();
    for (let key in values) {
      formdata.append(key, values[key]);
    }
    setSubmitInProgress(true);
    axios
      .post(`${URL}/admin/create-new-hi-blog`, formdata, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",         "ngrok-skip-browser-warning": "any",
        },
      })
      .then((result) => {
        if (result) {
          setIsSuccess(true);
          setMessage("Content Created Successfully Please Wait");
          setTimeout(() => {
            setIsSuccess(false);
            setMessage("");
            setCreatedSeoData(result.data.newHiBlogSeo);
            setSubmitInProgress(false);
            setFormSeoPart(true);
          }, 3000);
        }
      })
      .catch((err) => {
        setSubmitInProgress(false);

        setIsError(true);
        setError(err.response.data.msg);
        setTimeout(() => {
          setIsError(false);
          setError("");
        }, 3000);
      });
  };
  const CreateNewEnglishBlog = (values) => {
    const month = {
      January: 1,
      February: 2,
      March: 3,
      April: 4,
      May: 5,
      June: 6,
      July: 7,
      August: 8,
      September: 9,
      October: 10,
      November: 11,
      December: 12,
    };
    const today = new Date();
    const data = {
      categoryId: values.categoryId,
      image: values.image,
      month: month[values.month],
      slug: values.slug,
      title: values.title,
      year: Number(values.year) ? Number(values.year) : today.getFullYear(),
    };

    const formdata = new FormData();
    if (curCat === 2736) {
      for (let key in data) {
        formdata.append(key, data[key]);
      }
    } else {
      for (let key in values) {
        formdata.append(key, values[key]);
      }
    }

    setSubmitInProgress(true);
    axios
      .post(`${URL}/admin/create-new-en-blog`, formdata, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",         "ngrok-skip-browser-warning": "any",
        },
      })
      .then((result) => {
        if (result) {
          setIsSuccess(true);
          setMessage("Content Created Successfully Please Wait");
          setTimeout(() => {
            setIsSuccess(false);
            setMessage("");
            setCreatedSeoData(result.data.newEnBlogSeo);
            setSubmitInProgress(false);
            setFormSeoPart(true);
          }, 3000);
        }
      })
      .catch((err) => {
        setSubmitInProgress(false);
        setIsError(true);
        setError(err.response.data.msg);
        setTimeout(() => {
          setIsError(false);
          setError("");
        }, 3000);
      });
  };


  if (token && token != "undefined") {
    return (
      <>
        {!history.state.category ? (
          !formSeoPart ? (
            <section className="postCountIV ">
              <div
                style={{
                  color: "black",
                  marginBottom: "30px",
                  textAlign: "center",
                  width: "100%",
                  borderRadius: "10px",
                  justifyContent: "center",
                  marginLeft: "10px",
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <h3>{history.state.pageTitle}</h3>
              </div>

              <Form
                onSubmit={formik.handleSubmit}
                style={{ padding: "10px 20px 0px 20px" }}
              >
                <div className="row">
                  <div className="col-lg-6">
                    {isSuccess && (
                      <Alert color="info" isOpen={visible} toggle={onDismiss}>
                        {message}
                      </Alert>
                    )}
                  </div>

                  <div className="row">
                    <div className="col-lg-6">
                      {isError && (
                        <Alert
                          style={{
                            background: "rgb(251, 99, 71)",
                            color: "white",
                          }}
                          isOpen={visible}
                          toggle={onDismiss}
                        >
                          Error::
                          {error}
                        </Alert>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="row">
                    <Row form>
                      <Col>
                        <Label for="name" className="lableCategory">
                          Category Name
                        </Label>

                        <select
                          onChange={(e) => {
                            formik.values.categoryId =
                              history.state.data[e.target.selectedIndex].id;
                            setCurCategory(
                              history.state.data[e.target.selectedIndex].id
                            );
                          }}
                          style={{
                            display: "block",
                            width: "100%",
                            fontSize: "16px",
                            fontWeight: "300",
                            borderRadius: "5px",
                            borderColor: "#ced4da",
                            padding: "7px",
                          }}
                        >
                          {history.state.data &&
                            history.state.data.length > 0 &&
                            history.state.data.map((value, index) => {
                              return (
                                <option key={index}>
                                  {value.category_name}
                                </option>
                              );
                            })}
                        </select>
                      </Col>
                      <Col>
                        <Label for="name" className="lableCategory">
                          Title:
                        </Label>
                        <Input
                          type="text"
                          required
                          name={"title"}
                          values={formik.values.title}
                          placeholder={`Enter Title`}
                          onChange={formik.handleChange}
                        />
                      </Col>
                    </Row>

                    {curCat === 2736 && (
                      <Row form>
                        <Col>
                          <Label for="name" className="lableCategory">
                            Month Name
                          </Label>

                          <select
                            onChange={(e) => {
                              formik.values.month = e.target.value;
                            }}
                            required
                            style={{
                              display: "block",
                              width: "100%",
                              fontSize: "16px",
                              fontWeight: "300",
                              borderRadius: "5px",
                              borderColor: "#ced4da",
                              padding: "7px",
                            }}
                          >
                            {selectMonOption.map((value, index) => {
                              return (
                                <option value={value.value} key={index}>
                                  {value.label}
                                </option>
                              );
                            })}
                          </select>
                        </Col>
                        <Col>
                          <Label for="name" className="lableCategory">
                            Year
                          </Label>

                          <select
                            required
                            onChange={(e) => {
                              formik.values.year = e.target.value;
                            }}
                            style={{
                              display: "block",
                              width: "100%",
                              fontSize: "16px",
                              fontWeight: "300",
                              borderRadius: "5px",
                              borderColor: "#ced4da",
                              padding: "7px",
                            }}
                          >
                            {selectyearOption.map((value, index) => {
                              return <option key={index}>{value.label}</option>;
                            })}
                          </select>
                        </Col>
                      </Row>
                    )}
                    <Row form>
                      <Col>
                        <Label for="name" className="lableCategory">
                          Slug:
                        </Label>
                        <Input
                          required
                          type="text"
                          name="slug"
                          placeholder={`Enter Slug`}
                          values={formik.values.slug}
                          onChange={formik.handleChange}
                        />
                      </Col>
                    </Row>
                    <Row form>
                      <Col>
                        <Label for="name" className="lableCategory">
                          images:
                        </Label>
                        <Input
                          type={"file"}
                          name="Image"
                          value={formik.values.images}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "image",
                              e.currentTarget.files[0]
                            );
                          }}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>

                <Button
                  disabled={submitInProgress}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "200px",
                    float: "right",
                    marginTop: "15px",
                    background:
                      "linear-gradient(180deg, #DD2A12 0%, #FFA214 100%)",
                  }}
                  type="submit"
                >
                  {!submitInProgress ? (
                    <strong>Create New Blog</strong>
                  ) : (
                    <span className="special-loader"></span>
                  )}
                </Button>
              </Form>
            </section>
          ) : (
            <SeoForm
              language={history.state.language}
              createdSeoData={createdSeoData}
              setCreatedSeoData={setCreatedSeoData}
            />
          )
        ) : !formSeoPart ? (
          <div>
            <div className="alert-side">
              {isSuccess && (
                <Alert color="info" isOpen={visible} toggle={onDismiss}>
                  {message}
                </Alert>
              )}
            </div>
            <div className="alert-side">
              {" "}
              {isError && (
                <Alert
                  style={{
                    background: "rgb(251, 99, 71)",
                    color: "white",
                  }}
                  isOpen={visible}
                  toggle={onDismiss}
                >
                  {error}
                </Alert>
              )}
            </div>

            <p className="name-add">
              Add new festival category in {months[selmonth - 1]}
            </p>
            <Form onSubmit={formik.handleSubmit}>
              <div className="form">
                <div className="each-input">
                  <label>Name En*</label>
                  <Input
                    required
                    type="text"
                    value={formik.values.nameEn}
                    name="nameEn"
                    onChange={formik.handleChange}
                    placeholder="add festival name in English"
                  />
                </div>

                <div className="each-input">
                  <label>Name Hi*</label>
                  <Input
                    required
                    value={formik.values.nameHi}
                    name="nameHi"
                    onChange={formik.handleChange}
                    type="text"
                    placeholder="add festival name in Hindi"
                  />
                </div>

                <div className="each-input">
                  <label>Slug*</label>
                  <Input
                    required
                    value={formik.values.slug}
                    name="slug"
                    onChange={formik.handleChange}
                    type="text"
                    placeholder="Cretae a unique slug"
                  />
                </div>

                <div className="each-input">
                  <label>Description En</label>
                  <Input
                    // required
                    value={formik.values.descriptionEn}
                    name="descriptionEn"
                    onChange={formik.handleChange}
                    placeholder="add description in English"
                    type="text"
                  />
                </div>

                <div className="each-input">
                  <label>Description Hi</label>
                  <Input
                    // required
                    value={formik.values.descriptionHi}
                    name="descriptionHi"
                    onChange={formik.handleChange}
                    placeholder="add description in Hindi"
                    type="text"
                  />
                </div>

                <div className="each-input">
                  <label>Festival Date*</label>
                  <Input
                    required
                    value={formik.values.festivalDate}
                    name="festivalDate"
                    onChange={formik.handleChange}
                    type="date"
                  />
                </div>

                <div className="each-input">
                  <label>Image url*</label>

                  <Input
                    type={"file"}
                    name="Image"
                    value={formik.values.images}
                    onChange={(e) => {
                      formik.setFieldValue("image", e.currentTarget.files[0]);
                    }}
                  />
                </div>
              </div>
              <div className="add-f-parent2">
                <Button
                  disabled={submitInProgress}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "200px",
                    float: "right",
                    marginTop: "15px",
                    background:
                      "linear-gradient(180deg, #DD2A12 0%, #FFA214 100%)",
                  }}
                  type="submit"
                >
                  {!submitInProgress ? (
                    <strong>Create New Category</strong>
                  ) : (
                    <span className="special-loader"></span>
                  )}
                </Button>
              </div>
            </Form>
          </div>
        ) : (
          <SeoFestivalform
            createdSeoData={createdFestivalSeoData}
            setCreatedSeoData={setCreatedFestivalSeoData}
          />
        )}
      </>
    );
  } else {
    window.location.href = "/";
  }
};

export default index;
