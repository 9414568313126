import { faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'

const PropertyCard = ({ property }) => {
  const [openDialog, setOpenDialog] = useState(false)

  const cardStyle = {
    backgroundColor: '#f9f9f9',
    border: '1px solid #ddd',
    borderRadius: '8px',
    padding: '16px',
    width: '49.5%',
    maxWidth: '49.5%',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    fontFamily: "'Roboto', sans-serif",
    position: 'relative'
  }

  const titleStyle = {
    fontSize: '18px',
    fontWeight: '600',
    color: '#333',
    marginBottom: '12px'
  }

  const detailRowStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '14px',
    color: '#555',
    marginBottom: '8px'
  }

  const descriptionStyle = {
    fontSize: '14px',
    color: '#666',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    cursor: property.description.length > 80 ? 'pointer' : 'default'
  }

  const dialogStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#fff',
    borderRadius: '8px',
    padding: '24px',
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
    zIndex: 1000,
    maxWidth: '500px',
    width: '90%'
  }

  const overlayStyle = {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 999
  }

  return (
    <>
      <div style={cardStyle}>
        <div>
          <FontAwesomeIcon
            onClick={() => setOpenDialog(true)}
            icon={faEye}
            size='sm'
            style={{
              color: 'black',
              position: 'absolute',
              right: '16px',
              top: '16px',
              cursor: 'pointer'
            }}
          />
        </div>
        <div style={titleStyle}>Owner Details</div>
        <div style={detailRowStyle}>
          <span>Name:</span>
          <span>{property.ownerName}</span>
        </div>
        {property.email && (
          <div style={detailRowStyle}>
            <span>Email:</span>
            <span>{property.email}</span>
          </div>
        )}
        {property.number && (
          <div style={detailRowStyle}>
            <span>Mobile Number:</span>
            <span>{property.number}</span>
          </div>
        )}

        <div style={{ ...titleStyle, marginTop: '16px' }}>Property Details</div>
        <div style={detailRowStyle}>
          <span>Name:</span>
          <span>{property.hotelName ? property.hotelName : 'User'}</span>
        </div>
        <div style={detailRowStyle}>
          <span>Category:</span>
          <span>{property.category}</span>
        </div>
        <div style={detailRowStyle}>
          <span>Address:</span>
          <span>{property.address}</span>
        </div>
        <div
          style={descriptionStyle}
          onClick={() =>
            property.description &&
            property.description.length > 80 &&
            setOpenDialog(true)
          }
        >
          <strong>Description:</strong>{' '}
          {property.description ? property.description : '---'}
        </div>
      </div>

      {openDialog && (
        <>
          <div style={overlayStyle} onClick={() => setOpenDialog(false)}></div>
          <div style={dialogStyle}>
            <h3 style={{ marginBottom: '16px' }}>Property Details</h3>
            <p>
              <strong>Owner Name:</strong> {property.ownerName}
            </p>
            {property.email && (
              <p>
                <strong>Email:</strong> {property.email}
              </p>
            )}
            {property.number && (
              <p>
                <strong>Mobile Number:</strong> {property.number}
              </p>
            )}
            <p>
              <strong>Property Name:</strong> {property.hotelName}
            </p>
            <p>
              <strong>Category:</strong> {property.category}
            </p>
            <p>
              <strong>Address:</strong> {property.address}
            </p>
            <p style={{ overflowWrap: 'break-word' }}>
              <strong>Description:</strong>{' '}
              {property.description ? property.description : '---'}
            </p>
            <button
              style={{
                marginTop: '16px',
                padding: '8px 16px',
                background: 'linear-gradient(180deg, #dd2a12 0%, #ffa214 100%)',
                color: '#fff',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer'
              }}
              onClick={() => setOpenDialog(false)}
            >
              Close
            </button>
          </div>
        </>
      )}
    </>
  )
}

const App = ({ data }) => {
  const properties = [
    {
      ownerName: 'Test',
      email: 'mamehta05@gmail.com',
      mobile: '2147483647',
      name: 'Testing',
      category: 'Guest House',
      address: 'Address 12, Noida, Uttar Pradesh',
      description:
        'This is a short description of the property. It fits within two lines easily.'
    },
    {
      ownerName: 'Test',
      email: 'mamehta05@gmail.com',
      mobile: '2147483647',
      name: 'Testing',
      category: 'Guest House',
      address: 'Address 12, Noida, Uttar Pradesh',
      description:
        'This is a very long description that exceeds two lines. Clicking on this will open a dialog with all the property details, including the full description and other relevant information.'
    }
  ]

  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        padding: '0px 5px 15px',
        justifyContent: 'space-between',
        rowGap: '10px'
      }}
    >
      {properties.map((property, index) => (
        <PropertyCard key={index} property={property} />
      ))}
    </div>
  )
}

export default App
