import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-solid-svg-icons";

const URL = process.env.REACT_APP_BASE_URL;

const Index = () => {
  // useNavigate
  const history = useNavigate();

  // token
  const token = localStorage.getItem("token");
 
  // states
  const [englishBlog, setEnglishBlog] = useState([]);
  const [eid, setId] = useState("1");
  const [skip, setSkip] = useState(0);
  const [enable, setEnable] = useState(true);
  const [current, setCurrent] = useState(1);

  // functions
  const getCategory = async (data, language, basic) => {
    await axios
      .get(`${URL}/pg/wellness/list-of-posts-by-slug?slug=${data.slug}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "any",
        },
      })
      .then((result) => {
        if (basic) {
          history("/basic-details", {
            state: {
              data: data,
              language: language,
              pageTitle: "Wellness",
              type: 6,
            },
          });
        } else {
          history("/text-editor", {
            state: {
              data: data,
              language: language,
              content: result.data.data.description,
              type: 6,
            },
          });
        }
      });
  };
  const changeStyle = (id) => {
    setId(id);
  };
  const BlogsData = async (params1) => {
    await axios
      .get(
        `${URL}/pg/wellness/list-of-posts?slug=${params1}&limit=200&skip=0`,
        {
          params: {
            categorySlug: params1,
            skip: skip,
            limit: 200,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            Accept: "application/json",
            client: 3,
            "Content-Type": "application/json",
            "ngrok-skip-browser-warning": "any",
          },
        }
      )
      .then((res) => {
        setEnglishBlog(res.data.data);
      })
      .catch((err) => console.log("Error::::::::", err));
  };

  // useEffect
  useEffect(() => {
    BlogsData("yoga");
  }, []);
  if (token && token != "undefined") {
    return (
      <div>
        <div
          className="postCount"
          style={{ cursor: "pointer", marginTop: "12px" }}
        >
          <div className="topbar">
            <p
              className={["1" == eid ? "BlogNavBar1" : "BlogNavBar"]}
              onClick={() => {
                changeStyle("1");
                BlogsData("yoga");
              }}
            >
              Yoga
            </p>
            <p
              className={["2" == eid ? "BlogNavBar1" : "BlogNavBar"]}
              onClick={() => {
                changeStyle("2");
                BlogsData("healing");
              }}
            >
              Healing
            </p>
            <p
              className={["3" == eid ? "BlogNavBar1" : "BlogNavBar"]}
              onClick={() => {
                changeStyle("3");
                BlogsData("health");
              }}
            >
              Health
            </p>
          </div>
          <div className="postCountIII">
            <div className="status">
              {[
                { no: 1, name: "Active Blogs" },
                { no: 2, name: "Disabled Blogs" },
              ].map((e) => {
                return (
                  <button
                    onClick={() => {
                      setEnable(e.no === 1 ? true : false);
                      setCurrent(e.no);
                    }}
                    key={e.name}
                    className={current === e.no && "enable"}
                  >
                    {e.name}
                  </button>
                );
              })}
            </div>
            <div className="grid-container" style={{
              overflow:"scroll"
            }}>
              {englishBlog && englishBlog.length > 0 && current === 1
                ? englishBlog
                    .filter((each) => each.is_disable === false)
                    .map((element, index) => {
                      return (
                        <div
                          key={index}
                          style={{
                            backgroundImage: `url(${element.image})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center center",
                            boxShadow:
                              "inset 0px 0px 0px 200px rgba(0,0,0,0.25)",
                          }}
                        >
                          <div className="d-flex flex-column full-height justify-content-between">
                            <span className="imageAdd width-full">
                              <FontAwesomeIcon
                                icon={faImage}
                                size="sm"
                                style={{ color: "white" }}
                                className="imageAddIcon"
                              />
                            </span>
                            <div className="d-flex   flex-column">
                              <p className="spanP">
                                <strong>{element.title}</strong>
                              </p>
                              <div className="width-full mb-1">
                                <button
                                  className="boxbutton"
                                  onClick={() => {
                                    getCategory(element, "en");
                                  }}
                                >
                                  {" "}
                                  Content{" "}
                                </button>
                                <button
                                  className="boxbutton"
                                  onClick={() => {
                                    getCategory(element, "en", "basic");
                                  }}
                                >
                                  basic
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                : englishBlog
                    .filter((each) => each.is_disable === true)
                    .map((element, index) => {
                      return (
                        <div
                          key={index}
                          style={{
                            backgroundImage: `url(${element.image})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center center",
                            boxShadow:
                              "inset 0px 0px 0px 200px rgba(0,0,0,0.25)",
                          }}
                        >
                          <div className="d-flex flex-column full-height justify-content-between">
                            <span className="imageAdd width-full">
                              <FontAwesomeIcon
                                icon={faImage}
                                size="sm"
                                style={{ color: "white" }}
                                className="imageAddIcon"
                              />
                            </span>
                            <div className="d-flex   flex-column">
                              <p className="spanP">
                                <strong>{element.title}</strong>
                              </p>
                              <div className="width-full mb-1">
                                <button
                                  className="boxbutton"
                                  onClick={() => {
                                    getCategory(element, "en");
                                  }}
                                >
                                  {" "}
                                  Content{" "}
                                </button>
                                <button
                                  className="boxbutton"
                                  onClick={() => {
                                    getCategory(element, "en", "basic");
                                  }}
                                >
                                  basic
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    window.location.href = "/";
  }
};

export default Index;
