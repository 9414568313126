import React, { useState, useEffect } from "react";
import { pilgrim_tours_user_enquiries } from "../../../store/the-pilgrim-tours";
import { useNavigate } from "react-router-dom";
import style from "../pilgrimTours.module.css";


const PilgrimEnquiry = () => {

    const router = useNavigate();

    const [enquiries, setEnquiries] = useState();
    const [loading, setLoading] = useState(true);

    async function getUserEnquiries() {
        const response = await pilgrim_tours_user_enquiries();
        if (response.status === 200) {
            setEnquiries(response.data.data);
            setLoading(false);
        } else {
            setLoading(false);
        }
    }

    useEffect(() => {
        getUserEnquiries();
    }, []);

    // console.log("enquiries", enquiries);

    return (
        <section className="postCountIV" style={{ overflowY: "scroll", marginTop: "12px" }} >
            <div className='my-3 p-3 d-flex flex-wrap gap-2'>
                {enquiries && enquiries.map((eq, eqIndex) => {
                    return <div key={eqIndex}  className={`card ${style.subCateBox} p-3`} >
                        <div className={`${style.widthFit} ${style.roundedFull} px-2 text-light width-fit bg-warning`}>{eqIndex + 1}</div>
                        {eq.travelDate && <div><span className='font-weight-light text-secondary'>Travel Date :</span> {eq.travelDate}</div>}
                        {eq.name && <div className='capitalize'><span className='font-weight-light text-secondary'>Name :</span> {eq.name}</div>}
                        {eq.noOfPerson && <div><span className='font-weight-light text-secondary'>No. of People :</span>{eq.noOfPerson} </div>}
                        {eq.email && <div><span className='font-weight-light text-secondary'>Email :</span> {eq.email}</div>}
                        {eq.packageName && <div className='capitalize'><span className='font-weight-light text-secondary'>Package Name :</span> {eq.packageName}</div>}
                        {eq.packageDescription && <div className='capitalize'><span className='font-weight-light text-secondary'>Package Description :</span> {eq.packageDescription}</div>}
                    </div>
                })}
                {enquiries && enquiries.length == 0 && <div className='text-center'>No Previous Enquiries.</div>}
            </div>

        </section>
    );
};

export default PilgrimEnquiry;
