import React from 'react'
import style from '../SattvikStaysAddForm.module.css'

const CityForm = props => {
  const { heading, handleChange, handleSubmit, formData, formik, state } = props
  return (
    <form className={style.formBox} onSubmit={handleSubmit}>
      <h2>{heading}</h2>
      <div className={style.inputBoxParent}>
        <div className={style.inputBox}>
          <div className='w-100'>
            <p>City Name</p>
            <input
              className='w-100'
              value={formik.values.cityName}
              name='cityName'
              placeholder='Enter your city name'
              required
              type='text'
              onChange={handleChange}
            ></input>
          </div>
          {/* <div>
            <p>State Name</p>
            <select
              name='stateId'
              value={formik.values.stateId}
              onChange={handleChange}
              style={{
                width: '100%',
                paddingTop: '6px',
                paddingBottom: '6px',
                marginTop: '2px',
                border: '1px solid gray',
                borderRadius: '3px'
                // color: 'gray'
              }}
            >
              {state.length > 0 &&
                state.map(e => <option value={e.stateId}>{e.name}</option>)}
            </select>
          </div> */}
          {/* <div>
            <div
              className={`d-flex w-100 gap-4 pt-3 align-items-center justify-content-between`}
            >
              <div>
                <p className="mb-0">Active</p>
                <FormGroup switch>
                  <Input
                    name="isActive"
                    checked={formik.values.isActive}
                    className={`${
                      formik.values.isActive ? "bg-success" : "bg-danger"
                    }`}
                    onClick={(e) => {
                      formik.setFieldValue("isActive", !formik.values.isActive);
                    }}
                    type="switch"
                  />
                </FormGroup>
              </div>

              <div>
                <p className="mb-0">Popular</p>

                <FormGroup switch>
                  <Input
                    name="popular"
                    checked={formik.values.popular}
                    className={`${
                      formik.values.popular ? "bg-success" : "bg-danger"
                    }`}
                    onClick={(e) => {
                      formik.setFieldValue("popular", !formik.values.popular);
                    }}
                    type="switch"
                  />
                </FormGroup>
              </div>
            </div>
          </div> */}
        </div>
        <div className={`${style.inputBox}`}>
          <div>
            <p>Image</p>
            {formik.values['city-image'] ? (
              <img
                style={{ width: '100%', height: '200px' }}
                src={URL.createObjectURL(formik.values['city-image'])}
              />
            ) : (
              <img
                style={{ width: '100%', height: '200px' }}
                src={formik.values.image}
              />
            )}
            <input
              type={'file'}
              name='city-image'
              onChange={e => {
                formik.setFieldValue('city-image', e.currentTarget.files[0])
              }}
            />
          </div>
          <div>
            <p>Banner Image</p>
            {formik.values['banner-image'] ? (
              <img
                style={{ width: '100%', height: '200px' }}
                src={URL.createObjectURL(formik.values['banner-image'])}
              />
            ) : (
              <img
                style={{ width: '100%', height: '200px' }}
                src={formik.values.bannerImage}
              />
            )}

            <input
              type={'file'}
              name='banner-image'
              onChange={e => {
                formik.setFieldValue('banner-image', e.currentTarget.files[0])
              }}
            />
          </div>
        </div>
        <div className={style.submitBtn}>
          <button type='submit'>Edit</button>
        </div>
      </div>
    </form>
  )
}

export default CityForm
