import React, { useState } from 'react'
import EditPackage from "./EditPackage"
import AddPackages from "./AddPackage"
import AddTourCategory from "./AddTourCategory"
const Forms = props => {
  const { active } = props
  return (
    <div>
      {active == 1 && <EditPackage {...props} />}
      {active == 2 && <AddPackages {...props} />}
      {active == 3 && <AddTourCategory {...props} />}
  
    </div>
  )
}

export default Forms

