import AxiosFunction from '../../lib/AxiosFunction'
import AxiosFunctionSattvik from '../../lib/AxiosFunctionSattvik'

export const sattvik_dashboard_api = async params => {
  return await AxiosFunction(
    'get',
    params ? 'api/sattvik/admin/dashboard' : 'api/sattvik/admin/dashboard',
    {},
    params
  )
}
export const sattvik_addHotels = async (params, body) => {
  return await AxiosFunction(
    'post',
    'api/sattvik/admin/add-new-hotel',
    body,
    params
  )
}
export const sattvik_editHotels = async (params, body) => {
  return await AxiosFunction(
    'put',
    'api/sattvik/admin/update-hotel',
    body,
    params
  )
}
export const sattvik_editCity = async (params, body) => {
  return await AxiosFunction(
    'put',
    'api/sattvik/admin/update-city',
    body,
    params
  )
}
export const sattvik_addCity = async (params, body) => {
  return await AxiosFunction(
    'post',
    'api/sattvik/admin/add-new-city',
    body,
    params
  )
}
export const sattvik_Search_api = async query => {
  return await AxiosFunction(
    'get',
    `/api/sattvik/admin/user-search?limit=100&skip=0&keyword=${query}`
  )
}
export const sattvik_listing_enqiries = async () => {
  return await AxiosFunction('get', `/api/sattvik/admin/hotel-enquiries`)
}
export const sattvik_user_enqiries = async () => {
  return await AxiosFunction('get', `/api/sattvik/admin/user-enquiries`)
}

export const sattvik_user_accounts_api = async filter => {
  return await AxiosFunction(
    'get',
    filter
      ? `/api/sattvik/admin/user-list?accountType=${filter}`
      : '/api/sattvik/admin/user-list'
  )
}

export const sattvik_get_all_city_api = async () => {
  return await AxiosFunction(
    'get',

    '/api/sattvik/admin/get-all-city'
  )
}

export const sattvik_get_all_hotel_api = async filter => {
  return await AxiosFunction(
    'get',
    filter
      ? `/api/sattvik/admin/get-all-hotel?category=${filter}`
      : '/api/sattvik/admin/get-all-hotel'
  )
}

export const sattvik_get_user_enquery_api = async () => {
  return await AxiosFunction('get', '/api/sattvik/admin/user-enquiries')
}

export const sattvik_get_hotel_enquery = async () => {
  return await AxiosFunction('get', '/api/sattvik/admin/hotel-enquiries')
}

export const sattvik_get_all_state = async () => {
  return await AxiosFunctionSattvik('get', '/state/state-list')
}

export const sattvik_get_city_by_state = async params => {
  return await AxiosFunctionSattvik('get', '/city/city-by-state', {}, params)
}
