import React, { useEffect, useState, useRef } from "react";
import InfiniteScroll from "react-infinite-scroller";
import {
  sattvik_user_accounts_api,
  sattvik_Search_api,
} from "../../../store/sattvik-stays";
import style from "./UserTable.module.css";
import {
  faFilter,
  faMagnifyingGlass,
  faCircleCheck,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const url = process.env.REACT_APP_BASE_URL;
const UserAccounts = () => {
  // states
  const [filter, setFilter] = useState("");
  const [userType, setUsertype] = useState("All");
  const [filterBox, setOpenFIlterBox] = useState(false);
  const [data, setData] = useState([]);
  const accountType = ["All", "Pending", "Active", "Suspended"];
  const [query, setQuery] = useState("");
  const debounceTimeout = useRef(null);
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);
  const [hasPosts, setHasPosts] = useState(true);

  const getUserAccountsData = async () => {
    const response = await sattvik_user_accounts_api(filter);
    if (response.status === 200) {
      setData(response.data.data);
    }
  };
  const handleSearch = async (search) => {
    const SearchApiResult = await sattvik_Search_api(search);
 
    if (SearchApiResult.status === 200 && search.length !== 0) {
      setData(SearchApiResult.data.data);
    } else {
      getUserAccountsData("All");
    }
 
  };
  const handleChange = (event) => {
    const value = event.target.value;
    setQuery(value);

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      handleSearch(event.target.value);
    }, 500);
  };

  useEffect(() => {
    getUserAccountsData(filter);
  }, [userType]);

  const response = async () => {
    await axios
      .get(`${url}/admin/list-of-accounts`, {
        params: {
          skip: 1,
          limit: 10,
        },
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
          Accept: "application/json",
          "ngrok-skip-browser-warning": "any",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
      
        setData([...data, res.data.data]);
        setLimit(limit + 10);
        setSkip(skip + 10);
        // if (res.data.data.length < 10) {
        //   setHasPosts(false);
        // }
      })
      .catch((err) => console.log("Error::::::::", err));
  };

  // useEffect
  React.useEffect(() => {
    response();
  }, []);

  return (
    <div>
      <div className={style.tableheader}>
        <h3>User List Of Sattvik Stay</h3>
        <div className={style.filterandsearch}>
          <FontAwesomeIcon
            icon={faFilter}
            style={{ cursor: "pointer" }}
            onClick={() => {
              setOpenFIlterBox(!filterBox);
            }}
          />
          {filterBox && (
            <div className={style.filterbox}>
              <ul>
                {accountType.map((each) => {
                  return (
                    <li
                      onClick={() => {
                        setUsertype(each);
                        setFilter(each === "All" ? "" : each);
                        setOpenFIlterBox(false);
                      }}
                      className={each === userType && style.activeAccountType}
                    >
                      <FontAwesomeIcon
                        color={each === userType ? "green" : "grey"}
                        icon={faCircleCheck}
                      />
                      <span>{each}</span>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
          <div className={style.inputBox}>
            <input
              ref={debounceTimeout}
              value={query}
              onChange={handleChange}
              placeholder="Search for user.."
            ></input>
            <FontAwesomeIcon icon={faMagnifyingGlass} />
          </div>
        </div>
      </div>
      <InfiniteScroll
        pageStart={0}
        loadMore={() => {
          response();
        }}
        hasMore={hasPosts}
        loader={<div key={0}>Loading</div>}
        useWindow={false}
      >
        <table className={style.contentTable}>
          <thead>
            <tr>
              <th>Sr. no</th>
              <th>User Id</th>
              <th>User Name</th>
              <th>Avatar</th>
            </tr>
          </thead>
          {!data.length ? (
            <tbody>
              <tr className={style.emptyRow}>
                <td colSpan={10} style={{ textAlign: "center" }}>
                  {"No user available"}
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody>
              {data.map((each, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{each.name ? each.name : "Not-Available"}</td>
                    <td>
                      {each.affiliate_id ? each.affiliate_id : "Not-Available"}
                    </td>
                    <td>
                      <div style={{ height: "100px", width: "100px" }}>
                        <img src={each.avatar} className="h-100 w-100" />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      </InfiniteScroll>
    </div>
  );
};

export default UserAccounts;
