import React from "react";
import SideBar from "../Admin/Side";
import Navigation from "../Admin/Navigation";
import style from "./layout.module.css";
import { useLocation } from "react-router-dom";
const Index = ({ children }) => {
  const navigate = useLocation();


  const headerData =
    navigate.pathname.includes("/pilgrim-tours") ||
    navigate.pathname.includes("/pilgrim-tours")
      ? `${navigate.pathname
          .split("/")[1]
          .replaceAll("-", " ")} ${navigate.pathname
          .split("/")[2]
          .replaceAll("-", " ")}`
      : navigate.pathname.split("/")[1].replaceAll("-", " ");

  if (
    navigate.pathname === "/text-editor" ||
    navigate.pathname === "/" ||
    navigate.pathname === "/update-blog-content"
  ) {
    return <div>{children}</div>;
  } else {
    return (
      <div className={style.mainLayoutBox}>
        <div className={style.sidebar}>
          <SideBar />
        </div>
        <div className={style.centerMainBox}>
          {navigate.pathname !== "/not-found" && (
            <div className={style.headerBox}>
              <Navigation data={headerData} />
            </div>
          )}
          <div className={`${style.childrenCommponentBox}  ${navigate.pathname === "/not-found" && style.notFoundLayout} ` }> {children}</div>
        </div>
      </div>
    );
  }
};

export default Index;