exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".pilgrimTours_cateHead__1KB9P {\n    font-weight: 700;\n    color: #f27413;\n    font-size: larger;\n}\n\n.pilgrimTours_subCateBox__V62mx {\n    width: 30%;\n    \n    @media screen and (max-width: 750px) {\n        width: 100%;\n    }\n}\n\n.pilgrimTours_widthFit__m6xNc{\n    width: -moz-fit-content;\n    width: fit-content;\n}\n\n.pilgrimTours_roundedFull__6y99m{\n   border-radius: 50%;\n   overflow: hidden;\n}\n\n", ""]);

// exports
exports.locals = {
	"cateHead": "pilgrimTours_cateHead__1KB9P",
	"subCateBox": "pilgrimTours_subCateBox__V62mx",
	"widthFit": "pilgrimTours_widthFit__m6xNc",
	"roundedFull": "pilgrimTours_roundedFull__6y99m"
};