import React, { useState, useEffect } from "react";
import { pilgrim_tour_details_By_tour_id } from "../../../../store/the-pilgrim-tours";
import Forms from "../../CommonComponents/Forms";
import { sattvik_editCity } from "../../../../store/sattvik-stays";
import { useFormik } from "formik";
import { Alert } from "reactstrap";
import { useNavigate, useLocation } from "react-router-dom";
const AddCityForm = () => {
  const heading = "Edit Tour Packages";
  const Navigate = useNavigate("");
  const Location = useLocation("");

  const [active, setActive] = useState(1);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [tour, setTour] = useState(
    Location.state
      ? Location.state
      : { packageId: Location.pathname.split("/")[2] }
  );

  const formik = useFormik({
    initialValues: {
      description: Location.state.description,
      destination: Location.state.destination,
      discountPrice: Location.state.discountPrice,
      duration: Location.state.duration,
      isActive: Location.state.isActive,
      packageId: Location.state.packageId,
      popular: Location.state.popular,
      price: Location.state.price,
      slug: Location.state.slug,
      title: Location.state.title,
      url: Location.state.url,
    },

    onSubmit: (values) => {
      console.log(values);
    },
  });
  async function getTourDetailsByTourId() {
    const response = await pilgrim_tour_details_By_tour_id(tour.packageId);
    if (response.status === 200) {
      setTour(response.data.data);
    } else {
    }
  }

  useEffect(() => {
    if (!tour.slug) getTourDetailsByTourId();
  }, [Location]);
  console.log("tour", tour, Location);
  return (
    <div>
      <div className="row pt-4 d-flex justify-content-center">
        {success && (
          <div className="col-lg-6">
            <Alert
              className="bg-bs-success-bg-subtle"
              style={{
                color: "black",
              }}
            >
              {success}
            </Alert>
          </div>
        )}
        {error && (
          <div className="col-lg-6">
            <Alert
              style={{
                background: "rgb(251, 99, 71)",
                color: "white",
              }}
            >
              {error}
            </Alert>
          </div>
        )}
      </div>
      <Forms
        active={active}
        formik={formik}
        heading={heading}
        formData={formik.values}
        handleChange={formik.handleChange}
        handleSubmit={formik.handleSubmit}
      />
    </div>
  );
};

export default AddCityForm;
