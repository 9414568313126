exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".layout_mainLayoutBox__3wTCd {\n    height: 100vh;\n    width: 100%;\n    display: grid;\n    grid-template-columns: 2fr 8fr;\n    grid-gap: 12px;\n    gap: 12px;\n}\n\n.layout_mainLayoutBox__3wTCd .layout_centerMainBox__2oqUH {\n    overflow: hidden;\n}\n\n.layout_mainLayoutBox__3wTCd .layout_centerMainBox__2oqUH .layout_headerBox__1seHo {\n    height: 80px;\n    width: 100%;\n    background-color: white;\n}\n\n.layout_mainLayoutBox__3wTCd .layout_centerMainBox__2oqUH .layout_childrenCommponentBox__2jQmv {\n    overflow: scroll;\n    overflow-x: hidden;\n    height: calc(100vh - 80px);\n\n}\n.layout_mainLayoutBox__3wTCd .layout_centerMainBox__2oqUH .layout_notFoundLayout__29Vcp{\n    height: 100vh;\n}\n\n@media screen and (max-width:480px) {\n    .layout_mainLayoutBox__3wTCd {\n        grid-template-columns: 1fr;\n    }\n\n    .layout_mainLayoutBox__3wTCd .layout_sidebar__3t3j8 {\n        display: none;\n    }\n}", ""]);

// exports
exports.locals = {
	"mainLayoutBox": "layout_mainLayoutBox__3wTCd",
	"centerMainBox": "layout_centerMainBox__2oqUH",
	"headerBox": "layout_headerBox__1seHo",
	"childrenCommponentBox": "layout_childrenCommponentBox__2jQmv",
	"notFoundLayout": "layout_notFoundLayout__29Vcp",
	"sidebar": "layout_sidebar__3t3j8"
};