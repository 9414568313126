import React, { useState, useEffect } from "react";
import { BarChart, Bar, Tooltip,XAxis, Placement } from "recharts";
import axios from "axios";
import { count } from "d3";
import {
  FormGroup,
  Label,
  Input,
  Format,
  Form,
  Row,
  Col,
  Alert,
  DropdownMenu,
} from "reactstrap";

const url = process.env.REACT_APP_BASE_URL;

const App = ({ data }) => {
  const [chart, setChart] = useState([]);
  const dataa = () => {
    axios
      .get(url + `/admin/dashboard`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "any",
        },
      })
      .then((response) => {
        setChart(response.data.userGraph);
      });
  };
  useEffect(() => {
      
    const aDSdfsvs = document.getElementsByClassName('recharts-tooltip-label')
  }, []);
  
  return (
    <BarChart width={500} height={135} data={data}>
      <Bar dataKey="Users" fill="#F27413" />
      <Tooltip fill="red" />
    </BarChart>
  );
};
export default App;
