import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import RichTextEditorForUpdater from "./RichTextEditorForUpdate";

import "reactjs-popup/dist/index.css";

import "../../../../App.css";

const Editor = ({
  festival,
  setformdata2,
  setContentEn,
  setContentHi,
  festtype,
}) => {
  const token = localStorage.getItem("token");
  const history = useLocation();

  const [data, setData] = useState();
  const [value, setValue] = useState("");
  const getValue = (value) => {
    String(value);
    if (value.includes("'")) {
      const textEditorBox = document.getElementById("textEditorBox");
      textEditorBox.style.color = "red";
      popUp();
    } else {
      const textEditorBox = document.getElementById("textEditorBox");
      setValue(value);
      textEditorBox.style.color = "black";
    }
  };

  function popUp() {
    alert(`Please remove ( ' )single Quote`);
  }
  if (token && history.state != null) {
    return (
      <div>
        <div style={{ display: "flex" }}>
          <textarea
            className="scroll"
            style={{ width: "50%", height: "100vh" }}
            value={value}
          ></textarea>
          <div id="textEditorBox" style={{ width: "50%", height: "90vh" }}>
            {festival ? (
              <RichTextEditorForUpdater
                setContentEn={setContentEn}
                setContentHi={setContentHi}
                festtype={festtype}
                festival={festival}
                setformdata2={setformdata2}
                getValue={getValue}
                data={history.state.content}
                setData={setData}
                language={history.state.language}
                type={history.state.type}
                state={history.state}
              />
            ) : (
              <RichTextEditorForUpdater
                getValue={getValue}
                data={history.state.content}
                setData={setData}
                language={history.state.language}
                type={history.state.type}
                state={history.state}
              />
            )}
          </div>
        </div>
      </div>
    );
  } else {
    window.location.href = "/";
  }
};

export default Editor;
