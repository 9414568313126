import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import Tabs from "../../Tabs/Tabs";
import Category from "./Tabs/Category";
import Navigation from "../../Navigation";
import Sidebar from "../../Side";

const URL = process.env.REACT_APP_BASE_URL;

const ReportList = () => {
  const token = localStorage.getItem("token");
  const name = localStorage.getItem("user");
  const pageTitle = "Edit SEO ";
  const active = "active";

  const [curcategory, setCurCategory] = useState(() => {
    // Load initial state from localStorage, if available
    const savedValue = localStorage.getItem("mycurrentwithseo");
    return savedValue !== null ? JSON.parse(savedValue) : 1;
  });

  // let className = `tab-list-item ${tab - list - active}`;
  useEffect(() => {
    // Save state to localStorage whenever `value` changes

    localStorage.setItem("mycurrentwithseo", JSON.stringify(curcategory));
  }, [curcategory]);
  if (token && token != "undefined") {
    const data = [
      { name: "Ritual Category ", type: 1 },
      { name: "Ritual Post", type: 2 },
      { name: "Temple", type: 3 },
      { name: "Varatkatha Category ", type: 4 },
      { name: "Varatkatha", type: 5 },
      { name: "English Blog", type: 6 },
      { name: "Hindi Blog", type: 7 },
      { name: "Festivals", type: 8 },
    ];
    return (
      <>
        <div className="main-parentcontainer">
          <div className="grid-parent">
            {" "}
            <Sidebar data={name} pgc={active} />
            <div className="grid-child">
              <Navigation data={pageTitle} />
              <section
                className="postCount"
                style={{ overflow: "scroll", marginTop: "5px" }}
              >
                {/* <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                        <div>
                            <span className='lableCategory' style={{ width: "100%", height: "100vh" }} > </span>
                        </div>
                    </div> */}
                <div className="seo-grid">
                  <h5
                    className="col-lg-12 p-2"
                    style={{
                      backgroundColor: "#D3D3D3",
                      color: "black",

                      textAlign: "center",
                      borderRadius: "10px",
                      fontSize: "30px",
                    }}
                  >
                    {" "}
                    SEO Catagory
                  </h5>{" "}
                  {/* <Tabs>
                    {data &&
                      data.length > 0 &&
                      data.map((data, index) => {
                        return (
                          <div key={index} label={data.name}>
                            <Category data={data.type} name={data.name} />
                          </div>
                        );
                      })}



                  </Tabs> */}
                  <div>
                    <ul className="tab-list-new">
                      <li
                        className={curcategory === 1 && "active"}
                        onClick={() => {
                          setCurCategory(1);
                        }}
                      >
                        Ritual Categor
                      </li>
                      <li
                        className={curcategory === 2 && "active"}
                        onClick={() => {
                          setCurCategory(2);
                        }}
                      >
                        Ritual Post
                      </li>
                      <li
                        className={curcategory === 3 && "active"}
                        onClick={() => {
                          setCurCategory(3);
                        }}
                      >
                        Temple
                      </li>
                      <li
                        className={curcategory === 4 && "active"}
                        onClick={() => {
                          setCurCategory(4);
                        }}
                      >
                        Varatkatha Category
                      </li>
                      <li
                        className={curcategory === 5 && "active"}
                        onClick={() => {
                          setCurCategory(5);
                        }}
                      >
                        Varatkatha
                      </li>
                      <li
                        className={curcategory === 6 && "active"}
                        onClick={() => {
                          setCurCategory(6);
                        }}
                      >
                        English Blog
                      </li>
                      <li
                        className={curcategory === 7 && "active"}
                        onClick={() => {
                          setCurCategory(7);
                        }}
                      >
                        Hindi Blog
                      </li>
                      <li
                        className={curcategory === 8 && "active"}
                        onClick={() => {
                          setCurCategory(8);
                        }}
                      >
                        Festivals
                      </li>
                    </ul>

                    <div className="tabs tabs-mobile">
                      <div className="tab-content">
                        {data &&
                          data.length > 0 &&
                          data.map((data, index) => {
                            if (data.type === curcategory) {
                              return (
                                <div key={index} label={data.name}>
                                  <Category data={data.type} name={data.name} />
                                </div>
                              );
                            } else {
                              return;
                            }
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    window.location.href = "/";
  }
};

export default ReportList;
