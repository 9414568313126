import React from "react";
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-solid-svg-icons";

import { useEffect } from "react";
const url = process.env.REACT_APP_BASE_URL;

const Index = (props) => {
  // useNavigate
  const history = useNavigate();
  
  // State
  const [blogCategory, setBlogCategory] = useState([]);
  const [englishBlog, setEnglishBlog] = useState([]);
  const [skip, setSkip] = useState(0);
  const [id, setId] = useState("calendar");
  const [enable, setEnable] = useState(true);
  const [current, setCurrent] = useState(1);
  const [categoryId, setCategoryId] = useState();
  const [curcategory, setCurCategory] = useState(() => {
    const savedValue = localStorage.getItem("mycurrentenglishblogcategory");
    return savedValue !== null ? JSON.parse(savedValue) : "calendar";
  });

  // Functions
  const getCategory = async (data, language, basic) => {
    data.categoryId = categoryId;

    if (basic) {
      history("/update-basic-details", {
        state: {
          data: data,
          language: language,
          pageTitle: "English Blog",
          type: 1,
        },
      });
    } else {
      history("/update-blog-content", {
        state: {
          data: data,
          language: language,
          content: data.content,
          type: 1,
          categoryId: categoryId,
          id: data.id,
        },
      });
    }
  };

  const BlogCategoryData = async () => {
    await axios
      .get(`${url}/pg/blog/list-of-en-blog-category`, {
        headers: {
          Accept: "application/json",
          client: 3,
          "Content-Type": "application/json",
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "any",
        },
      })
      .then((res) => {
        setBlogCategory(res.data.data);
        setCategoryId(res.data.data[0].id);
      })
      .catch((err) => console.log("Error::::::::", err));
  };

  const BlogsData = async (params1) => {
    await axios
      .get(`${url}/pg/blog/list-of-en-blog`, {
        params: {
          categorySlug: params1,
          skip: skip,
          limit: 200,
        },
        headers: {
          Accept: "application/json",
          client: 3,
          "Content-Type": "application/json",
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "any",
        },
      })
      .then((res) => {
        const data = res.data.blogList.filter((each) => {
          return each != null;
        });

        setEnglishBlog(data);
      })
      .catch((err) => console.log("Error::::::::", err));
  };
  const changeStyle = (id) => {
    setId(id);
  };

  // useEffects
  useEffect(() => {
    BlogCategoryData();
    BlogsData(curcategory);
  }, [curcategory]);
  useEffect(() => {
    localStorage.setItem(
      "mycurrentenglishblogcategory",
      JSON.stringify(curcategory)
    );
  }, [curcategory]);
  return (
    <div
      className="postCount"
      style={{
        cursor: "pointer",
        overflow: "scroll",
        marginTop: "12px",
      }}
    >
      <div className="blog-correct">
        <div style={{ display: "flex" }}>
          {blogCategory &&
            blogCategory.length > 0 &&
            blogCategory.map((element, index) => {
              return (
                <div
                  key={index}
                  className={[
                    element.slug == curcategory ? "BlogNavBar1" : "BlogNavBar",
                  ]}
                  onClick={() => {
                    changeStyle(element.slug);
                    setCurCategory(element.slug);
                    setCategoryId(element.id);
                  }}
                >
                  <strong> {element.category_name}</strong>
                </div>
              );
            })}{" "}
        </div>

        <div className="btn-add">
          <button
            style={{
              margin: "5px",
              marginLeft: "10px",
              top: "0px",

              width: "150px",
              height: "34px",
              background: "orange",
              border: "none",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              borderRadius: "14px",
              fontWeight: "bold",
              color: "black",
            }}
            onClick={() => {
              history("/add-new-blog", {
                state: {
                  data: blogCategory,
                  language: "en",
                  pageTitle: "English Blog",
                  type: 1,
                },
              });
            }}
          >
            Add New
          </button>
        </div>
      </div>

      <div className="postCountIII">
        <div className="status">
          {[
            { no: 1, name: "Active Blogs" },
            { no: 2, name: "Disabled Blogs" },
          ].map((e) => {
            return (
              <button
                onClick={() => {
                  setEnable(e.no === 1 ? true : false);
                  setCurrent(e.no);
                }}
                key={e.name}
                className={current === e.no && "enable"}
              >
                {e.name}
              </button>
            );
          })}
        </div>
        <div className="grid-container">
          {englishBlog.length &&
            englishBlog
              .filter((each) => {
                return enable ? each.is_disable == 0 : each.is_disable == 1;
              })
              .map((element, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      backgroundImage: `url(${element.image})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center center",
                      boxShadow: "inset 0px 0px 0px 200px rgba(0,0,0,0.25)",
                    }}
                  >
                    <div
                      style={{ position: "relative" }}
                      className="detail-outer-box"
                    >
                      <span className="imageAdd">
                        <FontAwesomeIcon
                          icon={faImage}
                          size="sm"
                          style={{ color: "white" }}
                          className="imageAddIcon"
                        />
                      </span>
                      <div>
                        <p className="spanP">
                          <strong>{element.title}</strong>
                        </p>
                        <div>
                          <button
                            className="boxbutton"
                            onClick={() => {
                              getCategory(element, "en");
                            }}
                          >
                            {" "}
                            Content{" "}
                          </button>
                          <button
                            className="boxbutton"
                            onClick={() => {
                              getCategory(element, "en", "basic");
                            }}
                          >
                            basic
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
        </div>
      </div>
    </div>
  );
};

export default Index;
