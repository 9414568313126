import React, { useState, useEffect } from "react";
import { add_new_category } from "../../../../store/the-pilgrim-tours";
import Forms from "../../CommonComponents/Forms";
import { useFormik } from "formik";
import { Alert } from "reactstrap";
import { useNavigate, useLocation } from "react-router-dom";
const AddCityForm = () => {
  const Location = useLocation("");
  const heading = Location.state.subCat
    ? "Add new sub Category"
    : "Add New Tour Category";
  const Navigate = useNavigate("");

  const [active, setActive] = useState(3);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleAddCategory = async (data) => {
    const formData = new FormData();
    for (let key in data) {
      formData.append(key, data[key]);
    }

    const AddCategories = await add_new_category(formData);
    console.log("addPackageResponse", AddCategories);

    if (AddCategories.response ? true : false) {
      setError(AddCategories.response.data.msg);
      setTimeout(() => {
        setError("");
      }, 2000);
      // Simplified check
    } else {
      setSuccess("Package Added Successfully");
      setTimeout(() => {
        setSuccess("");
        Navigate(`/pilgrim-tours/tours-categories/`);
      }, 2000);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "test",
      parentId:""
    },

    onSubmit: (values) => {
      const data = {
        name: values.name,
        image: values.url,
        parentId:values.parentId
      };

      console.log(data);
      handleAddCategory(data);
    },
  });

  return (
    <div>
      <div
        style={{ top: "100px" }}
        className="row pt-4 d-flex justify-content-center position-absolute  start-50 translate-middle w-100"
      >
        {success && (
          <div className="col-lg-6">
            <Alert
              className="bg-bs-success-bg-subtle"
              style={{
                color: "black",
              }}
            >
              {success}
            </Alert>
          </div>
        )}
        {error && (
          <div className="col-lg-6">
            <Alert
              style={{
                background: "rgb(251, 99, 71)",
                color: "white",
              }}
            >
              {error}
            </Alert>
          </div>
        )}
      </div>
      <Forms
        active={active}
        formik={formik}
        heading={heading}
        formData={formik.values}
        handleChange={formik.handleChange}
        handleSubmit={formik.handleSubmit}
      />
    </div>
  );
};

export default AddCityForm;
