import React, { useEffect, useState } from 'react'
import Forms from '../../CommonComponents/Forms'
import {
  sattvik_editCity,
  sattvik_get_all_state
} from '../../../../store/sattvik-stays'
import { useFormik } from 'formik'
import { Alert } from 'reactstrap'
import { useNavigate, useLocation } from 'react-router-dom'
const AddCityForm = () => {
  const heading = 'Edit City'
  const [active, setActive] = useState(3)
  const [state, setState] = useState([])
  const Navigate = useNavigate('')
  const Location = useLocation('')

  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)

  const handleEditCity = async data => {
    const formData = new FormData()
    for (let key in data) {
      formData.append(key, data[key])
    }
    const editApiResponse = await sattvik_editCity({}, formData)

    if (editApiResponse.data && editApiResponse.data.status == 'success') {
      setSuccess('City details edited successfully')
      setTimeout(() => {
        setSuccess('')
        Navigate('/sattvik-stays/cities')
      }, 2000)
    } else {
      setError(editApiResponse.response.data.msg)
      setTimeout(() => {
        setError('')
      }, 2000)
    }
  }
  const formik = useFormik({
    initialValues: {
      cityName: Location.state.data.city,
      cityId: Location.state.data.cityId,
      stateId: Location.state.data.stateId,
      image: Location.state.data.image,
      bannerImage: Location.state.data.banner,
      isActive: Location.state.data.isActive,
      popular: Location.state.data.popular
    },

    onSubmit: values => {
      const data = {
        cityName: values.cityName,
        image: values['city-image'] ? values['city-image'] : values.image,
        banner: values['banner-image']
          ? values['banner-image']
          : values.bannerImage,
        isActive: values.isActive,
        popular: values.popular,
        cityId: values.cityId,
        stateId: values.stateId
      }
      handleEditCity(data)
    }
  })

  const getAllState = async () => {
    const response = await sattvik_get_all_state()
    if (response.data.statusCode == 200) setState(response.data.data)
  }

  useEffect(() => {
    getAllState()
  }, [])

  return (
    <div>
      <div className='row pt-4 d-flex justify-content-center'>
        {success && (
          <div className='col-lg-6'>
            <Alert
              className='bg-bs-success-bg-subtle'
              style={{
                color: 'black'
              }}
            >
              {success}
            </Alert>
          </div>
        )}
        {error && (
          <div className='col-lg-6'>
            <Alert
              style={{
                background: 'rgb(251, 99, 71)',
                color: 'white'
              }}
            >
              {error}
            </Alert>
          </div>
        )}
      </div>
      <Forms
        state={state}
        active={active}
        formik={formik}
        heading={heading}
        formData={formik.values}
        handleChange={formik.handleChange}
        handleSubmit={formik.handleSubmit}
      />
    </div>
  )
}

export default AddCityForm
