import React from "react";
import Tabs from "../../Tabs/Tabs";
import Posts from "./Posts";
import Shorts from "./Shorts";

const Index = (props) => {
  let dataa = props.data;

  return (
    <section
      className="postCountIV"
      style={{
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        marginTop: "12px",
      }}
    >
      <Tabs>
        <div label={"Posts"}>
          <Posts data={dataa} />
        </div>
        <div label={"Shorts"}>
          <Shorts data={dataa} />
        </div>
      </Tabs>
    </section>
  );
};

export default Index;
