import React from "react";
import Astrology from "../../Divotion/VartkathaAllCat";
import { useState, useEffect } from "react";
const VratKathaCategory = () => {
  let Astrologys = [
    { id: 2, name: "Weekly Vrat Katha" },
    { id: 4, name: "Ekadashi Vrat katha" },
    { id: 1, name: "Monthly Vrat katha" },
    { id: 3, name: "Yearly Vrat Katha" },
  ];

  const [curcategory, setCurCategory] = useState(() => {
    const savedValue = localStorage.getItem("mycurrentvarthkatha");
    return savedValue !== null ? JSON.parse(savedValue) : 1;
  });

  useEffect(() => {
    localStorage.setItem("mycurrentvarthkatha", JSON.stringify(curcategory));
  }, [curcategory]);

  return (
    <section style={{ overflow: "hidden" }} className="postCountIV corrected-scroll">
      <div
        style={{
          height: "calc(100vh - 100px)",
          width: "100%",
          marginTop: "10px",
          overflow:"scroll",
        }}
      >
        <div>
          <ul className="tab-list-new" style={{
      
          }}>
            <li
              className={curcategory === 1 && "active"}
              onClick={() => {
                setCurCategory(1);
              }}
            >
              Monthly Vrat katha
            </li>
            <li
              className={curcategory === 2 && "active"}
              onClick={() => {
                setCurCategory(2);
              }}
            >
              Weekly Vrat Katha
            </li>
            <li
              className={curcategory === 3 && "active"}
              onClick={() => {
                setCurCategory(3);
              }}
            >
              Yearly Vrat Katha
            </li>
            <li
              className={curcategory === 4 && "active"}
              onClick={() => {
                setCurCategory(4);
              }}
            >
              Ekadashi Vrat katha
            </li>
          </ul>

          <div className="tabs" style={{
          
            height:"100%",
            overflow:"scroll"
          }}>
            <div style={{ overflow: "hidden" }} className="tab-content">
              {Astrologys &&
                Astrologys.length > 0 &&
                Astrologys.map((items, index) => {
                  if (items.id === curcategory) {
                    return (
                      <div key={index} label={items.name}>
                        <Astrology data={items.id} />
                      </div>
                    );
                  }
                })}
            </div>
          </div>
        </div>
      </div>
    </section>
  
  );
};

export default VratKathaCategory;
