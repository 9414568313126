import React from "react";
import FestivalSub from "./FestivalSub";
import { useLocation } from "react-router-dom";

const Index = () => {
  let location = useLocation();
  const data = location.state;
  const token = localStorage.getItem("token");
  if (token) {
    return (
      <>
        <FestivalSub data={data} />
      </>
    );
  } else {
    window.location.href = "/";
  }
};

export default Index;
