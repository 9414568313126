import React from "react";
import { useLocation } from "react-router-dom";
import Sidebar from "../../Side";
import Navigation from "../../Navigation";

const ReportAccountDetail = (props) => {
  let location = useLocation();
  const data = location.state;
  const token = localStorage.getItem("token");
  const name = localStorage.getItem("user");
  const pageTitle = "Report Account Details";
  const active = "active";
  if (token && token != "undefined") {
    return (
      <>
        <div className="main-parentcontainer">
          <div className="grid-parent">
            {" "}
            <Sidebar data={name} ugc={active} />
            <div className="grid-child">
              <Navigation data={pageTitle} />
              <div className="postCountVII remort-account-details-mob">
                <div className="accountContainer">
                  <div className="accountProContainer widthFlexBoxI"></div>
                  <div className="accountProContainer widthFlexBoxII"></div>
                </div>
              </div>
            </div>{" "}
          </div>
        </div>
      </>
    );
  } else {
    window.location.href = "/";
  }
};

export default ReportAccountDetail;

// import React from 'react'

// const index = () => {

//         return (
//             <>

//                 <ReportLists />
//             </>
//         )
//     }
//     else {
//         window.location.href = '/'
//     }
// }

// export default index
