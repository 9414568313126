import React from "react";
import AddCategory from "./AddCategory";
const Index = () => {
  const token = localStorage.getItem("token");

  if (token) {
    return (
      <>
        <AddCategory />
      </>
    );
  } else {
    window.location.href = "/";
  }
};

export default Index;
