import React from 'react';
import Sidebar from '../../../Side'
import Navigation from '../../../Navigation'
import FestivalSub from './FestivalSub';
import { useLocation } from "react-router-dom";

const Index = () => {
  let location = useLocation();
    const data = location.state;
    const token = localStorage.getItem("token");
    const name = localStorage.getItem("user")
    const pageTitle = 'Sub Festivals';
    const active = 'active';    
    
    if(token){
    return (
        <>
            
            <div className="main-parentcontainer">
          <div className="grid-parent">
            {" "}
            <Sidebar data={name} pgc={active} />
            <div className="grid-child">
            <Navigation data={pageTitle} />
            <FestivalSub data={data}/>
            </div>{" "}
          </div>
            </div>
            
            
            
            
        </>
    )}
    else{
        window.location.href = '/'
    }
}
    
export default Index
