import React, { useEffect, useState } from "react";
import { sattvik_dashboard_api } from "../../../store/sattvik-stays";
import style from "./sattvikStaysDashboard.module.css";
import DashboardCards from "./DashboardCards";
import {
  faClipboard,
  faBed,
  faUsers,
  faIndianRupeeSign,
  faCity,
  faEnvelopesBulk,
  faHouseUser,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFilter,
  faMagnifyingGlass,
  faCircleCheck,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const Dashboard = () => {
  const [days, setdays] = useState(1);
  const [totalData, setTotalData] = useState([]);
  const [daysByData, setDaysByData] = useState([]);
  const [filterBox, setOpenFIlterBox] = useState(false);

  const iconArray = {
    totalCityCount: faCity,
    totalUserEnquiryCount: faEnvelopesBulk,
    totalHotelCount: faBed,
    totalGuestHouseCount: faHouseUser,
    totalDharamshalaCount: faHouseUser,
    totalHomeStayCount: faHouseUser,
    totalUserCount: faUsers,
    totalHotelEnquiryCount: faEnvelopesBulk,
    totalActiveUserCount: faUsers,
    totalPendingUserCount: faUsers,
    totalSuspendedUserCount: faUsers,
    userEnquiryCountByDays: faEnvelopesBulk,
    userCountByDays: faUsers,
    hotelEnquiryCountByDays: faBed,
    userActiveCountByDays: faUsers,
    userPendingCountDays: faUsers,
  };
  const nameArray = {
    totalCityCount: "Total City",
    totalUserEnquiryCount: "Total User Enquiry",
    totalHotelCount: "Total Hotel",
    totalGuestHouseCount: "Total Guest House",
    totalDharamshalaCount: "Total Dharamshala",
    totalHomeStayCount: "Total Home Stays",
    totalUserCount: "Total Users",
    totalHotelEnquiryCount: "Total Hotel Enquiry",
    totalActiveUserCount: "Total Active Users",
    totalPendingUserCount: "Total Pending Users",
    totalSuspendedUserCount: "Total Suspended Users",
    userEnquiryCountByDays: `User Enquiry`,
    userCountByDays: `Users`,
    hotelEnquiryCountByDays: `Hotel Enquiry`,
    userActiveCountByDays: `Active users`,
    userPendingCountDays: `Pending users`,
  };
  const colorArray = {
    totalCityCount: "#16C47F",
    totalUserEnquiryCount: "#FFD65A",
    totalHotelCount: "#F93827",
    totalGuestHouseCount: "#A294F9",
    totalDharamshalaCount: "#C30E59",
    totalHomeStayCount: "#F2AE66",
    totalUserCount: "#001A6E",
    totalHotelEnquiryCount: "#E1FFBB",
    totalActiveUserCount: "#0A3981",
    totalPendingUserCount: "#E38E49",
    totalSuspendedUserCount: "#72BAA9",
    userEnquiryCountByDays: "#EC8305",
    userCountByDays: "#F3C623",
    hotelEnquiryCountByDays: "#006BFF",
    userActiveCountByDays: "#0B192C",
    userPendingCountDays: "#CB80AB",
  };
  const bydays = ["1 Days", "1 Week", "1 Month", "1 Year"];
  const daysParams = {
    "1 Days": 1,
    "1 Week": 7,
    "1 Month": 31,
    "1 Year": 365,
  };
  const [currentFilterType, setCurrentFilterType] = useState("1 Days");
  const getDashboardData = async () => {
    let params = daysParams[currentFilterType];
    const response = await sattvik_dashboard_api({ params });
    if (response.status === 200) {
      const wantedFormOfTotalCount = Object.keys(
        response.data.data.totalCount
      ).map((key) => ({ [key]: response.data.data.totalCount[key] }));
    
      const newArrayOfTotalCount = wantedFormOfTotalCount.map((each) => {
        
    
        const keyValue = Object.keys(each)[0];
        const value = Object.values(each)[0];

        return {
          icon: iconArray[keyValue],
          name: nameArray[keyValue],
          number: value,
          color: colorArray[keyValue],
        };
      });
      setTotalData(newArrayOfTotalCount);
      const wantedFormOfDaysCount = Object.keys(
        response.data.data.countByDays
      ).map((key) => ({ [key]: response.data.data.countByDays[key] }));
      const newArrayOfDaysCount = wantedFormOfDaysCount.map((each) => {
       
        const keyValue = Object.keys(each)[0];
        const value = Object.values(each)[0];

        return {
          icon: iconArray[keyValue],
          name: nameArray[keyValue],
          number: value,
          color: colorArray[keyValue],
        };
      });
      setDaysByData(newArrayOfDaysCount);
    }
  };

  useEffect(() => {
    getDashboardData();
  }, [currentFilterType]);

  return (
    <div className={style.Dashboard}>
      <div className={style.welcomeBox}>
        <div className={style.leftBox}>
          <p> Dashboard</p>
        </div>
      </div>
      <div>
        <div>
          <p className={style.tabsHeading}>Statics</p>
        </div>
        <div className={style.topCardSections}>
          {totalData.length > 0
            ? totalData.map((each) => {
                return (
                  <DashboardCards
                    icon={each.icon}
                    name={each.name}
                    number={each.number}
                    color={each.color}
                  />
                );
              })
            : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((each) => {
                return <div key={each} className={style.skleton}></div>;
              })}
        </div>
      </div>
      <div className={style.btmTabs}>
        <div className={style.filterBoxParent}>
          <p className={style.tabsHeading}>
            Total data by last {currentFilterType}
          </p>
          {filterBox && (
            <div className={style.filterbox}>
              <ul>
                {bydays.map((each) => {
                  return (
                    <li
                      onClick={() => {
                        setCurrentFilterType(each);
                        setOpenFIlterBox(false);
                      }}
                      className={
                        each === currentFilterType && style.activeAccountType
                      }
                    >
                      <FontAwesomeIcon
                        color={each === currentFilterType ? "green" : "grey"}
                        icon={faCircleCheck}
                      />
                      <span>{each}</span>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
          <FontAwesomeIcon
            icon={faFilter}
            style={{ cursor: "pointer", marginRight: "1rem" }}
            onClick={() => {
              setOpenFIlterBox(!filterBox);
            }}
          />
        </div>
        <div className={style.topCardSections}>
          {daysByData.length > 0
            ? daysByData.map((each) => {
                return (
                  <DashboardCards
                    icon={each.icon}
                    name={each.name}
                    number={each.number}
                    color={each.color}
                  />
                );
              })
            : [1, 2, 3, 4, 5].map((each) => {
                return <div key={each} className={style.skleton}></div>;
              })}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
