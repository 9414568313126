import React from "react";
import style from "../../../../SattvikStays/CommonComponents/Forms/SattvikStaysAddForm.module.css";
import { useState, useEffect } from "react";
import { sattvik_get_all_city_api } from "../../../../../store/sattvik-stays";
import { Input, FormGroup } from "reactstrap";
const CityForm = (props) => {
  const { heading, handleChange, handleSubmit, formData, formik } = props;

  const [cities, setCities] = useState([]);
  const Category = [
    {
      id: "hotel",
      name: "hotel",
      label: "Hotel",
    },
    {
      id: "guest house",
      name: "guest house",
      label: "Guest House",
    },
    {
      id: "dharamshala",
      name: "dharamshala",
      label: "Dharamshala",
    },
    {
      id: "home stay",
      name: "home  stay",
      label: "Home  Stay",
    },
  ];
  const getCities = async () => {
    const response = await sattvik_get_all_city_api();

    if (response.data.status == "success") {
      const options = response.data.data.map((each) => {
        const a = {
          name: each.cityId,
          id: each.cityId,
          label: each.city,
        };
        return a;
      });
      setCities(options);
    }
  };

  useEffect(() => {
    getCities();
  }, []);

  return (
    <form className={style.formBox} onSubmit={handleSubmit}>
      <h2>{heading}</h2>
      <div className={style.inputBoxParent}>
        <div className={style.inputBox}>
          <div>
            <p>Destination *</p>
            <input
              value={formik.values.destination}
              name="destination"
              placeholder="Enter Your Package Destination"
              required
              type="text"
              onChange={handleChange}
            ></input>
          </div>
          <div>
            <p>Title *</p>
            <input
              value={formik.values.title}
              name="title"
              placeholder="Enter Your Package Title"
              required
              type="text"
              onChange={handleChange}
            ></input>
          </div>
        </div>
        <div className={`${style.inputBox} align-items-start`}>
          <div>
            <p>Description *</p>
            <textarea
              style={{ height: "236px" }}
              className="w-100"
              value={formik.values.description}
              name="description"
              placeholder="Enter Your Package Description"
              required
              type="text"
              onChange={handleChange}
            ></textarea>
          </div>
          <div>
            <p>Image *</p>
            {formik.values.url && (
              <img
                style={{ width: "100%", height: "200px" }}
                src={URL.createObjectURL(formik.values.url)}
              />
            )}
            <input
              type={"file"}
              required
              name="url"
              value={formik.values.packageImage}
              onChange={(e) => {
                console.log(e.currentTarget.files[0],"imgg")
                formik.setFieldValue("url", e.currentTarget.files[0]);
              }}
            />
          </div>
        </div>
        <div className={`${style.inputBox} pt-3`}>
          <div>
            <p>Price *</p>
            <input
              value={formik.values.price}
              name="price"
              placeholder="Enter Package Price"
              required
              type="number"
              onChange={handleChange}
            ></input>
          </div>
          <div>
            <p>Discounted Price *</p>
            <input
              value={formik.values.discountPrice}
              name="discountPrice"
              placeholder="Enter Package Disscounted Price"
              required
              type="number"
              onChange={handleChange}
            ></input>
          </div>
        </div>
        <div className={`${style.inputBox} pt-3`}>
          <div>
            <p>Slug *</p>
            <input
              value={formik.values.slug}
              name="slug"
              placeholder="Enter Package Uique Slug"
              required
              type="text"
              onChange={handleChange}
            />
          </div>


          <div>
            <p>Duration *</p>
            <input
              value={formik.values.duration}
              name="duration"
              placeholder="Enter Your Package Duration"
              required
              type="number"
              onChange={handleChange}
            ></input>
          </div>
        </div>

        <div className={style.submitBtn}>
          <button type="submit">Add</button>
        </div>
      </div>
    </form>
  );
};

export default CityForm;
