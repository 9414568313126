import React from "react";
import UserLists from "./UserLists";
import UserTable from "./UserTable";
const index = () => {
  const token = localStorage.getItem("token");

  if (token) {
    return <UserLists />;
    // return <UserTable />;
  } else {
    window.location.href = "/";
  }
};

export default index;
