import React, { useState } from "react";
import Sidebar from "../../Side";
import { createElement } from "react";
import Navigation from "../../Navigation";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import SubUploadImage from "../../../Others/UploadImage/SubUploadImage";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Button,
  Form,
  Row,
  Col,
  Alert,
  ButtonToggle,
} from "reactstrap";
import { useFormik } from "formik";
import Select from "react-select";
const url = process.env.REACT_APP_BASE_URL;

const index = () => {
  // basic page info
  const token = localStorage.getItem("token");
  const name = localStorage.getItem("user");
  const pageTitle = "Festivals ";
  const active = "active";
  const [modal, setModal] = useState(false);

  // for data pass and get
  const history = useLocation();
  const navigate = useNavigate();
  const [selmonth, setselmonth] = useState(history.state);

  // state management and true false
  const [visible, setVisible] = useState(true);

  const formik = useFormik({
    initialValues: {
      nameEn: "",
      nameHi: "",
    },

    onSubmit: (values) => {
      const formData = new FormData();
      for (let key in formik.values) {
        formData.append(key, values[key]);
      }

      CreateCategory(formData);
    },
  });
  const [isSuccess, setIsSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState("");
  const [save, setSave] = useState("");
  // functions
  const onDismiss = () => setVisible(false);

  const toggle = () => setModal(!modal);

  const CreateCategory = async (values) => {
    await axios
      .post(`${URL}/admin/add-festival-category`, values, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "any",
        },
      })
      .then((res) => {
        console.log("-------res", res);
        // if (res.data.code === 200) {
        //   setIsSuccess(true);
        //   setMessage("Festival category added Successfully");
        //   setTimeout(() => {
        //     setIsSuccess(false);
        //     setMessage("");
        //     navigate(-2);
        //   }, 3000);
        // }
      })
      .catch((Err) => {
        console.log("Error::::::::", Err);
        // setIsError(true);
        // setError(Err.response.data.msg);
      });
  };

  // required data
  let months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const defaultvalue = {
    value: history.state,
    label: months[history.state - 1],
  };
  const selectOption = [
    { value: "1", label: "January" },
    { value: "2", label: "February" },
    { value: "3", label: "March" },
    { value: "4", label: "April" },
    { value: "5", label: "May" },
    { value: "6", label: "June" },
    { value: "7", label: "July" },
    { value: "8", label: "August" },
    { value: "9", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];

  const URL = process.env.REACT_APP_BASE_URL;

  if (token && selmonth) {
    return (
      <>
        <div className="main-parentcontainer">
          <div className="grid-parent">
            {" "}
            <Sidebar data={name} pgc={active} />
            <div className="grid-child">
              <Navigation data={pageTitle} />
              <div>
                <div className="alert-side">
                  {isSuccess && (
                    <Alert color="info" isOpen={visible} toggle={onDismiss}>
                      {message}
                    </Alert>
                  )}
                </div>
                <div className="alert-side">
                  {" "}
                  {isError && (
                    <Alert
                      style={{
                        background: "rgb(251, 99, 71)",
                        color: "white",
                      }}
                      isOpen={visible}
                      toggle={onDismiss}
                    >
                      {error}
                    </Alert>
                  )}
                </div>

                <p className="name-add">
                  Add new festival category in {months[selmonth - 1]}
                </p>
                <Form onSubmit={formik.handleSubmit}>
                  <div className="form">
                    <div className="each-input">
                      <label>Name En*</label>
                      <Input
                        required
                        type="text"
                        value={formik.values.nameEn}
                        name="nameEn"
                        onChange={formik.handleChange}
                        placeholder="add festival name in English"
                      />
                    </div>

                    <div className="each-input">
                      <label>Name Hi*</label>
                      <Input
                        required
                        value={formik.values.nameHi}
                        name="nameHi"
                        onChange={formik.handleChange}
                        type="text"
                        placeholder="add festival name in Hindi"
                      />
                    </div>

                    <div className="each-input">
                      <label>Slug*</label>
                      <Input
                        required
                        value={formik.values.slug}
                        name="slug"
                        onChange={formik.handleChange}
                        type="text"
                        placeholder="Cretae a unique slug"
                      />
                    </div>

                    <div className="each-input">
                      <label>Description En</label>
                      <Input
                        // required
                        value={formik.values.descriptionEn}
                        name="descriptionEn"
                        onChange={formik.handleChange}
                        placeholder="add description in English"
                        type="text"
                      />
                    </div>

                    <div className="each-input">
                      <label>Description Hi</label>
                      <Input
                        // required
                        value={formik.values.descriptionHi}
                        name="descriptionHi"
                        onChange={formik.handleChange}
                        placeholder="add description in Hindi"
                        type="text"
                      />
                    </div>

                    {/*    <div className="each-input">
                      <label>Month*</label>
                      <Select
                        defaultValue={defaultvalue}
                        onChange={(e) => {
                          setselmonth(e.value);
                        }}
                        options={selectOption}
                      />
                    </div> */}

                    <div className="each-input">
                      <label>Festival Date*</label>
                      <Input
                        required
                        value={formik.values.festivalDate}
                        name="festivalDate"
                        onChange={formik.handleChange}
                        type="date"
                      />
                    </div>

                    <div className="each-input">
                      <label>Image url*</label>
                      {/* <input
                        id={save && "slect-month"}
                        required
                        name="file"
                        placeholder="add your image url here"
                        type="file"
                        value={formik.values.file}
                        onChange={(e) => {
                          formik.setFieldValue(
                            "file",
                            e.currentTarget.files[0]
                          );
                        }}
                      ></input> */}
                      <Input
                        type={"file"}
                        name="Image"
                        value={formik.values.images}
                        onChange={(e) => {
                          formik.setFieldValue(
                            "image",
                            e.currentTarget.files[0]
                          );
                        }}
                      />
                      {/* <input
                        id={save && "slect-month"}
                        required
                        disabled={save ? true : false}
                        value={save ? save : formdata.imageUrl}
                        name="imageUrl"
                        onChange={handleupdate}
                        placeholder="add your image url here"
                        type="text"
                      ></input> */}
                    </div>
                  </div>
                  <div className="add-f-parent2">
                    <button type="submit" className="add-festivel">
                      Add Now
                    </button>

                    {/* <button
                      type="button"
                      onClick={() => {
                        setModal(true);
                      }}
                      className="add-festivel"
                    >
                      {save ? "Change Image" : "Upload Image"}
                    </button> */}

                    {/* <button  className="add-festivel">
                      Upload Image
                    </button> */}
                  </div>
                </Form>
              </div>
            </div>{" "}
          </div>
        </div>
        <Modal isOpen={modal} toggle={toggle} id="upload-image-width">
          <ModalHeader toggle={toggle}>Upload Image On Aws</ModalHeader>
          <ModalBody>
            <div className="upload-div">
              <SubUploadImage
                setSave={setSave}
                toggle={toggle}
                parentid={"1"}
                section={true}
                sectonvalue={"festival"}
              />{" "}
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  } else {
    window.location.href = "/";
  }
};

export default index;
