import React, { useState, useEffect } from "react";
import { add_new_package } from "../../../../store/the-pilgrim-tours";
import Forms from "../../CommonComponents/Forms";
import { useFormik } from "formik";
import { Alert } from "reactstrap";
import { useNavigate, useLocation } from "react-router-dom";
const AddCityForm = () => {
  const heading = "Add New Tour Package";
  const Navigate = useNavigate("");
  const Location = useLocation("");
  const [active, setActive] = useState(2);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleAddPackage = async (data) => {
    if (data.discountPrice > data.price) {
      setError("Discounted price can't be more than price");
      setTimeout(() => {
        setError("");
      }, 2000);
    } else {
      const formData = new FormData();
      for (let key in data) {
        formData.append(key, data[key]);
      }

      const addPackageResponse = await add_new_package(formData);
      console.log("addPackageResponse", addPackageResponse);

      if (addPackageResponse.response ? true : false) {
        setError(addPackageResponse.response.data.msg);
        setTimeout(() => {
          setError("");
        }, 2000);
        // Simplified check
      } else {
        setSuccess("Package Added Successfully");
        setTimeout(() => {
          setSuccess("");
          Navigate(`/pilgrim-tours/tours-categories/${Location.state}`);
        }, 2000);
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      description: "test",
      destination: "test",
      discountPrice: 1000,
      duration: 1,
      price: 2000,
      slug: "test-test",
      title: "Test Title",
      categoryId: Location.state,
    },

    onSubmit: (values) => {
      const data = {
        description: values.description,
        destination: values.destination,
        discountPrice: values.discountPrice,
        duration: values.duration,
        price: values.price,
        slug: values.slug,
        title: values.title,
        categoryId: Location.state,
        packageImage: values.url,
      };

      handleAddPackage(data);
    },
  });

  return (
    <div>
      <div style={{top:"100px"}} className="row pt-4 d-flex justify-content-center position-absolute  start-50 translate-middle w-100">
        {success && (
          <div className="col-lg-6">
            <Alert
              className="bg-bs-success-bg-subtle"
              style={{
                color: "black",
              }}
            >
              {success}
            </Alert>
          </div>
        )}
        {error && (
          <div className="col-lg-6">
            <Alert
              style={{
                background: "rgb(251, 99, 71)",
                color: "white",
              }}
            >
              {error}
            </Alert>
          </div>
        )}
      </div>
      <Forms
        active={active}
        formik={formik}
        heading={heading}
        formData={formik.values}
        handleChange={formik.handleChange}
        handleSubmit={formik.handleSubmit}
      />
    </div>
  );
};

export default AddCityForm;
