import React, { useState } from 'react';
import { Field, useFormik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom'
import { Alert } from 'reactstrap';
import Navigation from '../../Navigation';
import Sidebar from '../../Side';
// import axios from 'axios';

const url = process.env.REACT_APP_BASE_URL

function InputField({ name, disabled, onChange, type, placeholder, value, label, ...props }) {
    return (
        <div className="col-lg-6">
            <div className="form-group">
                <div className="row">
                    {label ? (
                        <label className="col-lg-4 col-form-label">
                            {label}
                        </label>
                    ) : (
                        ''
                    )}
                    <div className="col-lg-8 mb-2">
                        <input
                            type={type}
                            name={name}
                            placeholder={placeholder ? placeholder : label}
                            className="form-control"
                            {...props}
                            value={value}
                            onChange={onChange}
                            disabled={disabled}
                        />
                        {/* {formik.errors.name && formik.touched.name && (
                            <div
                                style={{
                                    color: 'rgb(235, 54,54)',
                                    fontSize: '14px',
                                }}
                            >
                                {formik.errors.name}
                            </div>
                        )} */}
                    </div>
                </div>
            </div>
        </div>
    );
}

function Form() {
    const history = useLocation()
    const router = useNavigate()
    const [visible, setVisible] = useState(true);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const onDismiss = () => setVisible(false);
    const [data, setData] = useState(history.state)
    const EditChantLife = (values) => {
        fetch(`${url}/admin/update-chant-life-post`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                Accept: "application/json",
                "Content-Type": "application/json",
                "ngrok-skip-browser-warning": "any"
            },
            body: JSON.stringify(values),
        })
            .then(
                (result) => {
                    if (result.status == 'success') {
                        setIsSuccess(true);
                        setMessage(result.msg);
                        setData(result.data[0])
                        setTimeout(() => {
                            setIsSuccess(false);
                            setMessage('');
                        }, 3000)
                    }
                },
            ).catch((err) => {
                console.log("Error::::::::", err);
                setIsError(true);
                setError('Something went wrong. Try again');
            }
            );
    };

    const formik = useFormik({
        initialValues: {
            id: data.id,
            title_en: data.title_en,
            title_hi: data.title_hi,
            category_id: data.category_id,
            mantra_en: data.mantra_en,
            mantra_hi: data.mantra_hi,
            slug: data.slug,
            image: data.image,
            content_en: data.content_en,
            content_hi: data.content_hi,
            audio_url: data.audio_url,
            created_at: data.created_at,
            status: data.status,
            isPopular: data.isPopular

        },
        onSubmit: (values) => {
            EditChantLife(values)
        },

    });
    const token = localStorage.getItem('token');
    const name = localStorage.getItem("user")
    const pageTitle = 'ChantLife';
    const active = 'active';
    if (token && token != 'undefined' ) {

        return (
            <>
                 <div className="main-parentcontainer">
                    <div className="grid-parent">
                    <Sidebar data={name} pgc={active} />
                        <div className='grid-child'>   <Navigation data={pageTitle} />
                <section className='postCount' style={{ overflow: 'scroll' }}>
                    <form
                        onSubmit={formik.handleSubmit}
                        style={{ width: '90%', margin: 'auto' }}
                    >
                        <div className="row">
                            <div className="col-lg-6">
                                {isSuccess && (
                                    <Alert color="info" isOpen={visible} toggle={onDismiss}>
                                        {message}
                                    </Alert>
                                )}
                            </div>
                        </div>
                        <div className="row row-bg-2" style={{ padding: '7px' }}>
                            <div
                                className="col-lg-12 p-2"
                                style={{
                                    backgroundColor: '#6c757d',
                                    color: 'white',
                                    marginBottom: '30px',
                                    textAlign: 'center',
                                }}
                            ><h5>Edit ChantLife</h5>

                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <div className="row">
                                        <label className="col-md-4 col-form-label">
                                            {'ID: '}
                                        </label>
                                        <div className="col-md-8 mb-2">
                                            <input
                                                type="text"
                                                name="id"
                                                onChange={formik.handleChange}
                                                value={formik.values.id}
                                                disabled={true}
                                                style={{ width: '100%' }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <div className="row">
                                        <label className="col-md-4 col-form-label">
                                            {'Category ID: '}
                                        </label>
                                        <div className="col-md-8 mb-2">
                                            <input
                                                type="text"
                                                name="category_id"
                                                onChange={formik.handleChange}

                                                value={formik.values.category_id}
                                                disabled={true}
                                                style={{ width: '100%' }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <div className="row">
                                        <label className="col-md-4 col-form-label">
                                            {' Title English : '}
                                        </label>
                                        <div className="col-md-8 mb-2">
                                            <input
                                                type="text"
                                                name="title_en"
                                                onClick={formik.values.title_en}
                                                onChange={formik.handleChange}
                                                value={formik.values.title_en}
                                                disabled={true}
                                                style={{ width: '100%' }}
                                            //    <valueonClick={() => { HandleRedirectChatMissed(chatMissedDetails) }}>View Chat Missed>


                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <div className="row">
                                        <label className="col-md-4 col-form-label">
                                            {'Title Hindi: '}
                                        </label>
                                        <div className="col-md-8 mb-2">
                                            <input
                                                type="text"
                                                name="title_hi"
                                                onChange={formik.handleChange}
                                                value={formik.values.title_hi}
                                                style={{ width: '100%' }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <div className="row">
                                        <label className="col-md-4 col-form-label">
                                            {'Mantra English: '}
                                        </label>
                                        <div className="col-md-8 mb-2">
                                            <input
                                                type="text"
                                                name="mantra_en"
                                                onChange={formik.handleChange}
                                                value={formik.values.mantra_en}
                                                style={{ width: '100%' }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <div className="row">
                                        <label className="col-md-4 col-form-label">
                                            {'Mantra Hindi: '}
                                        </label>
                                        <div className="col-md-8 mb-2">
                                            <input
                                                type="text"
                                                name="mantra_hi"
                                                onChange={formik.handleChange}
                                                value={formik.values.mantra_hi}
                                                style={{ width: '100%' }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <div className="row">
                                        <label className="col-md-4 col-form-label">
                                            {'Content English: '}
                                        </label>
                                        <div className="col-md-8 mb-2">
                                            <input
                                                type="text"
                                                name="content_en"
                                                onChange={formik.handleChange}
                                                value={formik.values.content_en}
                                                style={{ width: '100%' }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <div className="row">
                                        <label className="col-md-4 col-form-label">
                                            {'Content Hindi: '}
                                        </label>
                                        <div className="col-md-8 mb-2">
                                            <input
                                                type="text"
                                                name="content_hi"
                                                onChange={formik.handleChange}
                                                value={formik.values.content_hi}
                                                style={{ width: '100%' }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <div className="row">
                                        <label className="col-md-4 col-form-label">
                                            {'Image: '}
                                        </label>
                                        <div className="col-md-8 mb-2">
                                            <input
                                                type="text"
                                                name="image"
                                                onChange={formik.handleChange}
                                                value={formik.values.image}
                                                style={{ width: '100%' }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <div className="row">
                                        <label className="col-md-4 col-form-label">
                                            {'Audio URl: '}
                                        </label>
                                        <div className="col-md-8 mb-2">
                                            <input
                                                type="text"
                                                name="audio_url"
                                                onChange={formik.handleChange}
                                                value={formik.values.audio_url}
                                                style={{ width: '100%' }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <div className="row">
                                        <label className="col-md-4 col-form-label">
                                            {'Status: '}
                                            {/* {'Status: '} <span style={formik.values.is_disable ? { backgroundColor: 'red' } : { backgroundColor: 'green' }} className='select-option'></span> */}
                                        </label>
                                        <div className="col-md-8 mb-2">
                                            <select name='status' onChange={formik.handleChange} style={{ width: '100%' }}>
                                                <option value={'Select'}>Select</option>
                                                <option value={'false'}>False</option>
                                                <option value={'true'}>True</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <div className="row">
                                        <label className="col-md-4 col-form-label">
                                            {'Is Popular:'}
                                            {/* {'Is Popular: '} <span style={formik.values.is_special ? { backgroundColor: 'green' } : { backgroundColor: 'red' }} className='select-option'></span> */}
                                        </label>
                                        <div className="col-md-8 mb-2">
                                            <select name='isPopular' onChange={formik.handleChange} style={{ width: '100%' }}>
                                                <option value={'Select'}>Select</option>
                                                <option value={'true'}>True</option>
                                                <option value={'false'}>False</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="submit-btn" style={{ textAlign: 'center' }}>
                            <button type="submit" className="btn mb-2" style={{ background: 'linear-gradient(180deg, #DD2A12 0%, #FFA214 100%)', color: 'white', padding: '10px', width: '100px' }}>
                                Submit
                            </button>
                        </div>
                    </form>
                </section></div>
                    </div></div>
                
               
             
            </>
        );
    }
    else {
        window.location.href = '/'
    }
}

export default Form;

