import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroller";
import qs from "qs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faThumbsUp, faShare } from "@fortawesome/free-solid-svg-icons";
const url = process.env.REACT_APP_BASE_URL;

const Shorts = (props) => {
  const [userShort, setUserShorts] = useState([]);
  const [userPost, setUserPosts] = useState([]);
  const [success, setSuccess] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState("");
  const [hasPosts, setHasPosts] = useState(true);
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);
  let data = props.data;
  const [message, setMessage] = useState("");
  const [messageView, setMessageView] = useState(false);

  useEffect(() => {
    response();
  }, []);

  const response = async () => {
    await axios
      .get(`${url}/admin/get-user-content`, {
        params: {
          skip: skip,
          limit: limit,
          userId: data.id,
          type: "shorts",
        },
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "any",
        },
      })
      .then((res) => {
        if (res.data.data.length == 0) {
          setMessageView(true);
          setMessage("Shorts Not Found");
        }
        setUserShorts([...userShort, ...res.data.data]);
        setLimit(limit + 10);
        setSkip(skip + 10);
        if (res.data.data.length < 10) {
          setHasPosts(false);
        }
      })
      .catch((err) => console.log("Error::::::::", err));
  };

  async function handleDisable(id, type) {
    let dataaa = {
      postId: id,
      status: type,
      type: "post",
    };
    const res = await axios.put(
      `${process.env.REACT_APP_BASE_URL}/admin/change-post-visibility`,
      dataaa,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "any",
        },
      }
    );
    if (res.data.status === "success") {
      setIsSuccess(true);
      setIsError(false);
      setSuccess("Short has been successfully updated");
      setTimeout(() => {
        setIsSuccess(false);
        setIsError(false);
        setSuccess("");
        window.location.reload();
      }, 3000);
    } else {
      setIsSuccess(false);
      setIsError(true);
      setError("Something went wrong");
    }
  }

  return (
    <div
      className=""
      style={{ width: "100%", height: "79vh", overflow: "scroll" }}
    >
      {isError && <div className={"alert alert-danger"}>{error}</div>}
      {isSuccess && <div className={"alert alert-success"}>{success}</div>}
      {messageView && <div className={"alert alert-failed"}>{message}</div>}
      <InfiniteScroll
        pageStart={0}
        loadMore={() => {
          response();
        }}
        hasMore={hasPosts}
        loader={<div key={0}>Loading</div>}
        useWindow={false}
      >
        {userShort &&
          userShort.length > 0 &&
          userShort.map((data, index) => {
            return (
              <div key={index} style={{ backgroundColor: "#D9D9D9" }}>
                <Card style={{ width: "100%", borderRadius: "0px" }}>
                  <Card.Body>
                    <div
                      className="single-user-post"
                      style={{
                        display: "flex",
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                        borderRadius: "5px",
                      }}
                    >
                      <div style={{ height: "180px" }}>
                        <video
                          controls="controls"
                          style={{
                            width: "250px",
                            borderRadius: "5px 0px 0px 5px",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        >
                          <source src={data.url} type="video/mp4" />
                        </video>
                      </div>
                      <div
                        style={{
                          padding: "15px",
                          borderRadius: "0px 10px 10px 0px",
                        }}
                      >
                        <Card.Title>
                          ShortId {data.id}{" "}
                          {data.short_categories[0].category.name ? (
                            <span
                              style={{
                                backgroundColor: `${data.short_categories[0].category.color}`,
                                borderRadius: "20px",
                                padding: "5px 15px",
                                fontWeight: "500",
                                fontSize: "15px",
                              }}
                            >
                              {data.short_categories[0].category.name}
                            </span>
                          ) : (
                            <span>No Category</span>
                          )}
                        </Card.Title>
                        <Card.Text
                          style={{ height: "60px", overflow: "scroll" }}
                        >
                          {data.content === ""
                            ? "No Content"
                            : data.post_content}
                        </Card.Text>
                        <div
                          style={{
                            marginBottom: "0px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center ",
                          }}
                        >
                          <div>
                            <span className="" style={{ marginRight: "10px" }}>
                              <FontAwesomeIcon icon={faEye} className="mr-2" />
                              {" " + data.view_counts}
                            </span>
                            {/* <span className="" style={{ marginRight: '10px' }}><FontAwesomeIcon icon={faCommentAlt} className="mr-2" />{' ' + data.commentCount}</span> */}
                            <span className="" style={{ marginRight: "10px" }}>
                              <FontAwesomeIcon
                                icon={faThumbsUp}
                                className="mr-2"
                              />
                              {" " + data.likeCount}
                            </span>
                            <span className="" style={{ marginRight: "10px" }}>
                              <FontAwesomeIcon
                                icon={faShare}
                                className="mr-2"
                              />
                              {" " + data.shareCount}
                            </span>{" "}
                          </div>
                          <div>
                            {data.is_short ? (
                              <Button
                                variant="danger"
                                className="postButton"
                                style={{ float: "right" }}
                                onClick={() => {
                                  handleDisable(data.id, false);
                                }}
                              >
                                Disable
                              </Button>
                            ) : (
                              <Button
                                variant="success"
                                className="postButton"
                                style={{ float: "right" }}
                                onClick={() => {
                                  handleDisable(data.id, true);
                                }}
                              >
                                Enable
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            );
          })}
      </InfiniteScroll>
    </div>
  );
};

export default Shorts;
