import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Sidebar from "../Side";
import { faBars } from "@fortawesome/free-solid-svg-icons";


const Index = (props) => {
  // states
  const [image, setImage] = React.useState(props.img);
  const [userId, setUserId] = React.useState(props.userId);
  const [mobile, setMobile] = useState(false);


  return (
    <div
      className="navigationDashboard"
      style={{ textTransform: "capitalize" }}
    >
      <div
        style={{
          zIndex: "1",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {image ? (
          <div>
            {" "}
            <img
              src={image}
              alt={"userImage"}
              style={{ width: "50px", height: "50px", borderRadius: "100%" }}
            />
          </div>
        ) : (
          ""
        )}{" "}
        {props.data}
        <p
          style={{
            fontSize: "14px",
            fontWeight: "400",
            margin: "0",
            marginLeft: "3px",
          }}
        >
          {userId ? `(${userId})` : ""}
        </p>
      </div>
      <div
        style={{
          right: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {mobile && (
          <div>
            <Sidebar setMobile={setMobile} mob={true} />
          </div>
        )}
        <FontAwesomeIcon
          onClick={() => {
            setMobile(!mobile);
          }}
          icon={faBars}
          size="sm"
          style={{ color: "white" }}
          className="imageAddBar"
        />
      </div>
    </div>
  );
};

export default Index;
