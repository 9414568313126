import axios from 'axios'
const url = process.env.REACT_APP_BASE_URL_SATTVIK

const AxiosFunctionSattvik = async (
  method,
  link,
  body,
  params,
  formData = {}
) => {
  try {
    const res = await axios({
      method: method,
      baseURL: url,
      url: link,
      params: params,
      data: body,
      headers: {
        authorization: 'Bearer ' + localStorage.getItem('token'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'ngrok-skip-browser-warning': 'any'
      }
    })

    return res
  } catch (err) {
    const res = err
    console.log('err', err)
    return res
    // if (err?.status >= 400) {
    //   console.log('errr:::', err.response.data)
    //   const errorData = err.response.data
    //   return errorData
    // }
    // if (err?.message == 'Network Error') {
    //   const errorData = {
    //     statusCode: 555
    //   }
    //   return errorData
    // }
  }
}

export default AxiosFunctionSattvik
