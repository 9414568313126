import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroller";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import qs from "qs";
const url = process.env.REACT_APP_BASE_URL;
const img2 = require("../../../assets/img/defaultimage.jpg");
const imageDomain =
  "https://rgyan-flutter200503-dev.s3.ap-south-1.amazonaws.com/public";

const UserLists = () => {
  const [userAccounts, setUserAccounts] = useState([]);
  const [hasPosts, setHasPosts] = useState(true);
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);
  const history = useNavigate();
  const [data, setData] = React.useState("");
  const [item, setItem] = React.useState([]);
  const [userAccount, setUserAccount] = useState(true);
  const [items, setItems] = React.useState(false);

  const handlechange = (e) => {
    e.preventDefault();
    setData(e.target.value);
    searchbar(e.target.value);
  };

  const token = `Bearer ${localStorage.getItem("token")}`;
  const searchbar = async (value) => {
    await axios
      .get(url + "/user/user-search", {
        params: {
          name: value,
          limit: 10,
          skip: 0,
        },
        headers: {
          authorization: token,
          Accept: "application/json",
          "ngrok-skip-browser-warning": "any",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setItem(res.data.data);
        setUserAccount(false);
        setItems(true);
      })
      .catch((err) => {
        console.log("Error::::::::", err);
        // setItem([])
        setUserAccount(true);
        setItems(false);
        response();
      });
  };

  // const data1 = data.dataa;
  function userDetails(data) {
    history("/single-user-details", { state: data });
  }
  React.useEffect(() => {
    response();
  }, []);

  const response = async () => {
    await axios
      .get(`${url}/admin/list-of-accounts`, {
        params: {
          skip: skip,
          limit: limit,
        },
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
          Accept: "application/json",
          "ngrok-skip-browser-warning": "any",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setUserAccounts([...userAccounts, ...res.data.data]);
        setLimit(limit + 10);
        setSkip(skip + 10);
        if (res.data.data.length < 10) {
          setHasPosts(false);
        }
      })
      .catch((err) => console.log("Error::::::::", err));
  };

  return (
    <>
      <div
        className="search-user"
        style={{
          position: "absolute",
          top: "5px",
          padding: "10px",
          borderRadius: "30px",
          height: "60px",
          overflow: "hidden",
          display: "flex",
          right: "30px",
        }}
      >
        {/* <div> */}
        <form
          action="#"
          onChange={handlechange}
          style={{ height: "100%", borderRadius: "10px", overflow: "hidden" }}
        >
          <input
            type="text"
            placeholder="Search"
            name="search"
            value={data}
            onChange={(e) => e.target.value}
            style={{ height: "100%", border: "none", padding: "10px" }}
          />
          <button
            type="submit"
            style={{ height: "100%", border: "none", backgroundColor: "none" }}
          >
            <FontAwesomeIcon icon={faSearch} />
          </button>
        </form>
        {/* </div> */}
      </div>
      <section className="postCountI">
        <InfiniteScroll
          pageStart={0}
          loadMore={() => {
            response();
          }}
          hasMore={hasPosts}
          loader={<div key={0}>Loading</div>}
          useWindow={false}
        >
          <div className="grid-container-userAccount">
            {userAccount &&
              userAccounts &&
              userAccounts.length > 0 &&
              userAccounts.map((data, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => {
                      userDetails(data);
                    }}
                  >
                    <img
                      src={
                        data.avatar == null
                          ? img2
                          : data.avatar.includes(imageDomain)
                          ? data.avatar
                          : data.avatar.includes(
                              "https://lh3.googleusercontent.com"
                            )
                          ? data.avatar
                          : img2
                      }
                      className="circle"
                      alt="avatar"
                    ></img>
                    <p style={{ marginBottom: "0px !important" }}>
                      <strong>{data.name}</strong>
                    </p>
                    <p style={{ marginBottom: "0px !important" }}>
                      {data.affiliate_id}
                    </p>
                  </div>
                );
              })}
          </div>
          <div className="grid-container-userAccount">
            {items &&
              item &&
              item.length > 0 &&
              item.map((data, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => {
                      userDetails(data);
                    }}
                  >
                    <img
                      src={
                        data.avatar.includes(imageDomain)
                          ? data.avatar
                          : data.avatar.includes(
                              "https://lh3.googleusercontent.com"
                            )
                          ? data.avatar
                          : img2
                      }
                      className="circle"
                      alt="avatar"
                    ></img>
                    <p style={{ marginBottom: "0px !important" }}>
                      <strong>{data.name}</strong>
                    </p>
                    <p style={{ marginBottom: "0px !important" }}>
                      {data.affiliate_id}
                    </p>
                  </div>
                );
              })}
          </div>

          {/* <div className="grid-container-userAccount">
                    {items && item.length == 0 && item.map((data, index) => {
                        return <div key={index} onClick={() => { userDetails(data) }}>
                            <img src={data.avatar.includes(imageDomain) ? data.avatar : data.avatar.includes('https://lh3.googleusercontent.com') ? data.avatar : img2} className='circle' alt='avatar'></img>
                            <p style={{ marginBottom: '0px !important' }}><strong>{data.name}</strong></p>
                            <p style={{ marginBottom: '0px !important' }}>{data.affiliate_id}</p>
                        </div>
                    })}
                </div> */}
        </InfiniteScroll>
      </section>
    </>
  );
};

export default UserLists;
