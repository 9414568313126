import React, { useState } from "react";
import { useFormik } from "formik";
import { useLocation } from "react-router-dom";
import { Alert } from "reactstrap";

const url = process.env.REACT_APP_BASE_URL;

function Form() {
  // useLocation
  const history = useLocation();
  
  // token
  const token = localStorage.getItem("token");
  
  // states
  const [visible, setVisible] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const onDismiss = () => setVisible(false);
  const [data, setData] = useState(history.state);
  
  // functions
  const EditChantLife = (values) => {
    fetch(`${url}/admin/update-chant-life-post`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        Accept: "application/json",
        "Content-Type": "application/json",
        "ngrok-skip-browser-warning": "any",
      },
      body: JSON.stringify(values),
    })
      .then((result) => {
        if (result.status == "success") {
          setIsSuccess(true);
          setMessage(result.msg);
          setData(result.data[0]);
          setTimeout(() => {
            setIsSuccess(false);
            setMessage("");
          }, 3000);
        }
      })
      .catch((err) => {
        console.log("Error::::::::", err);
        setIsError(true);
        setError("Something went wrong. Try again");
      });
  };
  const formik = useFormik({
    initialValues: {
      id: data.id,
      title_en: data.title_en,
      title_hi: data.title_hi,
      category_id: data.category_id,
      mantra_en: data.mantra_en,
      mantra_hi: data.mantra_hi,
      slug: data.slug,
      image: data.image,
      content_en: data.content_en,
      content_hi: data.content_hi,
      audio_url: data.audio_url,
      created_at: data.created_at,
      status: data.status,
      isPopular: data.isPopular,
    },
    onSubmit: (values) => {
      EditChantLife(values);
    },
  });


  if (token && token != "undefined") {
    return (
      <>
        <section className="postCount" style={{ overflow: "scroll" }}>
          <form
            onSubmit={formik.handleSubmit}
            style={{ width: "90%", margin: "auto" }}
          >
            <div className="row">
              <div className="col-lg-6">
                {isSuccess && (
                  <Alert color="info" isOpen={visible} toggle={onDismiss}>
                    {message}
                  </Alert>
                )}
              </div>
            </div>
            <div className="row row-bg-2" style={{ padding: "7px" }}>
              <div
                className="col-lg-12 p-2"
                style={{
                  backgroundColor: "#6c757d",
                  color: "white",
                  marginBottom: "30px",
                  textAlign: "center",
                }}
              >
                <h5>Edit ChantLife</h5>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <div className="row">
                    <label className="col-md-4 col-form-label">{"ID: "}</label>
                    <div className="col-md-8 mb-2">
                      <input
                        type="text"
                        name="id"
                        onChange={formik.handleChange}
                        value={formik.values.id}
                        disabled={true}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <div className="row">
                    <label className="col-md-4 col-form-label">
                      {"Category ID: "}
                    </label>
                    <div className="col-md-8 mb-2">
                      <input
                        type="text"
                        name="category_id"
                        onChange={formik.handleChange}
                        value={formik.values.category_id}
                        disabled={true}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <div className="row">
                    <label className="col-md-4 col-form-label">
                      {" Title English : "}
                    </label>
                    <div className="col-md-8 mb-2">
                      <input
                        type="text"
                        name="title_en"
                        onClick={formik.values.title_en}
                        onChange={formik.handleChange}
                        value={formik.values.title_en}
                        disabled={true}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <div className="row">
                    <label className="col-md-4 col-form-label">
                      {"Title Hindi: "}
                    </label>
                    <div className="col-md-8 mb-2">
                      <input
                        type="text"
                        name="title_hi"
                        onChange={formik.handleChange}
                        value={formik.values.title_hi}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <div className="row">
                    <label className="col-md-4 col-form-label">
                      {"Mantra English: "}
                    </label>
                    <div className="col-md-8 mb-2">
                      <input
                        type="text"
                        name="mantra_en"
                        onChange={formik.handleChange}
                        value={formik.values.mantra_en}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <div className="row">
                    <label className="col-md-4 col-form-label">
                      {"Mantra Hindi: "}
                    </label>
                    <div className="col-md-8 mb-2">
                      <input
                        type="text"
                        name="mantra_hi"
                        onChange={formik.handleChange}
                        value={formik.values.mantra_hi}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <div className="row">
                    <label className="col-md-4 col-form-label">
                      {"Content English: "}
                    </label>
                    <div className="col-md-8 mb-2">
                      <input
                        type="text"
                        name="content_en"
                        onChange={formik.handleChange}
                        value={formik.values.content_en}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <div className="row">
                    <label className="col-md-4 col-form-label">
                      {"Content Hindi: "}
                    </label>
                    <div className="col-md-8 mb-2">
                      <input
                        type="text"
                        name="content_hi"
                        onChange={formik.handleChange}
                        value={formik.values.content_hi}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <div className="row">
                    <label className="col-md-4 col-form-label">
                      {"Image: "}
                    </label>
                    <div className="col-md-8 mb-2">
                      <input
                        type="text"
                        name="image"
                        onChange={formik.handleChange}
                        value={formik.values.image}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <div className="row">
                    <label className="col-md-4 col-form-label">
                      {"Audio URl: "}
                    </label>
                    <div className="col-md-8 mb-2">
                      <input
                        type="text"
                        name="audio_url"
                        onChange={formik.handleChange}
                        value={formik.values.audio_url}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <div className="row">
                    <label className="col-md-4 col-form-label">
                      {"Status: "}
                    </label>
                    <div className="col-md-8 mb-2">
                      <select
                        name="status"
                        onChange={formik.handleChange}
                        style={{ width: "100%" }}
                      >
                        <option value={"Select"}>Select</option>
                        <option value={"false"}>False</option>
                        <option value={"true"}>True</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <div className="row">
                    <label className="col-md-4 col-form-label">
                      {"Is Popular:"}
                    </label>
                    <div className="col-md-8 mb-2">
                      <select
                        name="isPopular"
                        onChange={formik.handleChange}
                        style={{ width: "100%" }}
                      >
                        <option value={"Select"}>Select</option>
                        <option value={"true"}>True</option>
                        <option value={"false"}>False</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="submit-btn" style={{ textAlign: "center" }}>
              <button
                type="submit"
                className="btn mb-2"
                style={{
                  background:
                    "linear-gradient(180deg, #DD2A12 0%, #FFA214 100%)",
                  color: "white",
                  padding: "10px",
                  width: "100px",
                }}
              >
                Submit
              </button>
            </div>
          </form>
        </section>
      </>
    );
  } else {
    window.location.href = "/";
  }
}

export default Form;
