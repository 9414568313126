exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".notfound_notfoundbox__1Qu7M {\n\n    height: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n}\n\n.notfound_notfoundbox__1Qu7M .notfound_linkbox__2TNiw {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n}\n\n.notfound_notfoundbox__1Qu7M .notfound_linkbox__2TNiw a {\n    color: orange;\n\n}", ""]);

// exports
exports.locals = {
	"notfoundbox": "notfound_notfoundbox__1Qu7M",
	"linkbox": "notfound_linkbox__2TNiw"
};