import React from "react";
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
const index = ({ name, link, subCat, subCatoptions }) => {
  const router = useNavigate();
  return (
    <div
      onClick={() => {
        subCat
          ? router(link, {
              state: {
                option: subCatoptions,
                subCat: true,
              },
            })
          : router(link);
      }}
      style={{
        backgroundColor: "#63636363",
        border: "1px solid #636363",
        borderRadius: "20px",
        padding: "5px 20px",
        display: "flex",
        gap: "10px",
        alignItems: "center",
        cursor: "pointer",
        color: "black",
        width: "fit-content",
      }}
    >
      {name}
      <FontAwesomeIcon icon={faAdd} />
    </div>
  );
};

export default index;
