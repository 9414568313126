import React, { useEffect, useState } from "react";
import axios from "axios";

const Index = () => {
  // Token
  const token = localStorage.getItem("token");

  // States
  const [trendingData, setTrendingData] = useState([]);
  const [eid, setId] = useState("1");
  const [language, setLanguage] = useState("en");
  const [isSuccess, setIsSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState("");
  const URL = process.env.REACT_APP_BASE_URL;
  const changeStyle = (id) => {
    setId(id);
  };

  // functions
  const TrendingPost = async (language) => {
    await axios
      .get(`${URL}/admin/get-trending-blogs?language=${language}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "any",
        },
      })
      .then((result) => {
        setTrendingData(result.data.data);
        setLanguage(language);
      });
  };
  const EditEnglishBlogDetails = (values, id) => {
    fetch(`${URL}/admin/update-en-blog-post?blogId=${id}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        Accept: "application/json",
        "Content-Type": "application/json",
        "ngrok-skip-browser-warning": "any",
      },
      body: JSON.stringify(values),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.status == "success") {
          setIsSuccess(true);
          setMessage("Content Updated Successfully");
          setTimeout(() => {
            setIsSuccess(false);
            setMessage("");
            window.location.reload();
          }, 3000);
        }
      })
      .catch((err) => {
        console.log("Error::::::::", err);
        setIsError(true);
        setError("Something went wrong. Try again");
      });
  };
  const EditHindiBlogDetails = (values, id) => {
    fetch(`${URL}/admin/update-hi-blog-post?blogId=${id}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        Accept: "application/json",
        "Content-Type": "application/json",
        "ngrok-skip-browser-warning": "any",
      },
      body: JSON.stringify(values),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.status == "success") {
          setIsSuccess(true);
          setMessage("Content Updated Successfully");
          setTimeout(() => {
            setIsSuccess(false);
            setMessage("");
            window.location.reload();
          }, 3000);
        }
      })
      .catch((err) => {
        console.log("Error::::::::", err);
        setIsError(true);
        setError("Something went wrong. Try again");
      });
  };

  // useEffects
  useEffect(() => {
    TrendingPost("en");
  }, []);

  if (token && token != "undefined") {
    return (
      <div>
        <div className="postCount">
          <div style={{ display: "flex", marginTop: "12px" }}>
            <p
              className={["1" == eid ? "BlogNavBar1" : "BlogNavBar"]}
              onClick={() => {
                changeStyle("1");
                TrendingPost("en");
              }}
            >
              English
            </p>
            <p
              className={["2" == eid ? "BlogNavBar1" : "BlogNavBar"]}
              onClick={() => {
                changeStyle("2");
                TrendingPost("hi");
              }}
            >
              Hindi
            </p>
          </div>
          <div className="postCountIII" style={{border:"1px solid red",overflow:"scroll"}}>
            <div  
              className="grid-container"
              style={{ border: "1px solid red", overflow: "scroll" ,height:"calc(100vh - 140px)"}}
            >
              {trendingData &&
                trendingData.length > 0 &&
                trendingData.map((element, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "flex-end",
                        backgroundImage: `url(${element.image})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center center",
                        boxShadow: "inset 0px 0px 0px 200px rgba(0,0,0,0.25)",
                      }}
                    >
                      <div style={{ position: "relative", width: "100%" }}>
                        <p className="spanP">
                          <strong>{element.title}</strong>
                        </p>

                        <div>
                          <button
                            className="boxbutton"
                            onClick={() => {
                              if (language == "en") {
                                EditEnglishBlogDetails(
                                  { isTrending: false },
                                  element.id
                                );
                              } else {
                                EditHindiBlogDetails(
                                  { isTrending: false },
                                  element.id
                                );
                              }
                            }}
                          >
                            Disable
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    window.location.href = "/";
  }
};

export default Index;
