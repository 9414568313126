import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Navbar,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import img from "../../../assets/img/logo.png";
import { useLocation } from "react-router-dom";
import deviceStorage from "../../../config/deviceStorage";

const index = (props) => {
  // useLocations
  const navigate = useLocation();
  return (
    <section className={`sidebar ${props.mob && "mobsidebar"}`}>
      <div className="mobile-sidenav-close">
        {props.mob && (
          <FontAwesomeIcon
            onClick={() => {
              props.setMobile(false);
            }}
            icon={faXmark}
            size="lg"
            style={{ color: "white" }}
            className="imageAddBar"
          />
        )}
      </div>
      <div>
        <img src={img} alt="profilePic" />
        <h4>Hello</h4>
        <p>Welcome back</p>
        <h4>Rgyan Admin</h4>

        <div className="dropdownBox">
          <Link
            to="/dashboard"
            className="anchor"
            id={navigate.pathname === "/dashboard" ? "active" : " "}
          >
            Dashboard
          </Link>
          <Navbar dark expand="lg" className="nav">
            <Nav className="m-auto" navbar>
              <UncontrolledDropdown setActiveFromChild>
                <DropdownToggle tag="b" className="nav-link" caret>
                  <strong
                    id={
                      navigate.pathname.includes("user-accounts-list") ||
                      navigate.pathname.includes("single-user-details") ||
                      navigate.pathname.includes("report-accounts") ||
                      navigate.pathname.includes("report-hash-tags") ||
                      navigate.pathname.includes("report-posts") ||
                      navigate.pathname.includes("report-shorts")
                        ? "active"
                        : " "
                    }
                  >
                    UGC
                  </strong>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem tag="a" href="/user-accounts-list">
                    Accounts
                  </DropdownItem>
                  <DropdownItem tag="a" href="/categories">
                    Category
                  </DropdownItem>
                  <Navbar light expand="lg">
                    <Nav className="ml-auto" navbar>
                      <UncontrolledDropdown setActiveFromChild>
                        <DropdownToggle
                          tag="a"
                          className="reportsSection"
                          caret
                        >
                          Report
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem tag="a" href="/report-accounts">
                            Accounts
                          </DropdownItem>
                          <DropdownItem tag="a" href="/report-hash-tags">
                            Hashtags
                          </DropdownItem>
                          <DropdownItem tag="a" href="/report-posts">
                            Posts
                          </DropdownItem>
                          <DropdownItem tag="a" href="/report-shorts">
                            Shorts
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </Nav>
                  </Navbar>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Navbar>

          <Navbar dark expand="lg" className="nav">
            <Nav className="m-auto" navbar>
              <UncontrolledDropdown setActiveFromChild>
                <DropdownToggle tag="b" className="nav-link" caret>
                  <strong
                    id={
                      !navigate.pathname.includes("user-accounts-list") &&
                      !navigate.pathname.includes("single-user-details") &&
                      !navigate.pathname.includes("sattvik-stays/") &&
                      !navigate.pathname.includes("pilgrim-tours/") &&
                      !navigate.pathname.includes("report-accounts") &&
                      !navigate.pathname.includes("report-hash-tags") &&
                      !navigate.pathname.includes("report-posts") &&
                      !navigate.pathname.includes("report-shorts") &&
                      !navigate.pathname.includes("dashboard")
                        ? "active"
                        : " "
                    }
                  >
                    PGC
                  </strong>
                </DropdownToggle>
                <DropdownMenu>
                  <Navbar light expand="lg">
                    <Nav className="ml-auto" navbar>
                      <UncontrolledDropdown setActiveFromChild>
                        <DropdownToggle
                          tag="a"
                          className="reportsSection"
                          caret
                        >
                          Blogs
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem tag="a" href="/blog-en">
                            {" "}
                            English Blog{" "}
                          </DropdownItem>
                          <DropdownItem tag="a" href="/blog-hi">
                            {" "}
                            Hindi Blog
                          </DropdownItem>
                          <DropdownItem tag="a" href="/trending-blogs">
                            {" "}
                            Trending Blog
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </Nav>
                  </Navbar>
                  <DropdownItem tag="a" href="/chant-life">
                    Chant Life
                  </DropdownItem>
                  <Navbar light expand="lg">
                    <Nav className="ml-auto" navbar>
                      <UncontrolledDropdown setActiveFromChild>
                        <DropdownToggle
                          tag="a"
                          className="reportsSection"
                          caret
                        >
                          Devotion
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem tag="a" href="/rituals">
                            Rituals
                          </DropdownItem>
                          <DropdownItem tag="a" href="/vratKatha">
                            VratKatha
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </Nav>
                  </Navbar>
                  <DropdownItem tag="a" href="/festivals">
                    Festivals
                  </DropdownItem>
                  <DropdownItem tag="a" href="/live-darshan">
                    Live Darshan
                  </DropdownItem>
                  <Navbar light expand="lg">
                    <Nav className="ml-auto" navbar>
                      <UncontrolledDropdown setActiveFromChild>
                        <DropdownToggle
                          tag="a"
                          className="reportsSection"
                          caret
                        >
                          SEO
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem tag="a" href="/catagory-seo">
                            With SEO
                          </DropdownItem>
                          <DropdownItem tag="a" href="/without-seo">
                            {" "}
                            Without SEO
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </Nav>
                  </Navbar>
                  <DropdownItem tag="a" href="/videos">
                    Videos
                  </DropdownItem>
                  <DropdownItem tag="a" href="/wellness">
                    {" "}
                    Wellness
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Navbar>

          {/* <Navbar dark expand="lg" className="nav">
            <Nav className="m-auto" navbar>
              <UncontrolledDropdown setActiveFromChild>
                <DropdownToggle tag="b" className="nav-link" caret>
                  <strong
                    id={
                      navigate.pathname.includes("/pilgrim-tours")
                        ? "active"
                        : " "
                    }
                  >
                    Pilgrim Tours
                  </strong>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem tag="a" href="/pilgrim-tours/dashboard">
                    Dashboard
                  </DropdownItem>
                  <DropdownItem tag="a" href="/pilgrim-tours/user-accounts">
                    User Accounts
                  </DropdownItem>
                  <DropdownItem tag="a" href="/pilgrim-tours/tours-categories">
                    Tour Categories
                  </DropdownItem>
                  <DropdownItem tag="a" href="/pilgrim-tours/user-enquiries">
                    User Enquiries
                  </DropdownItem>
                  <DropdownItem tag="a" href="/pilgrim-tours/packages">
                    Packages
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Navbar> */}
          {/* <Navbar dark expand="lg" className="nav">
            <Nav className="m-auto" navbar>
              <UncontrolledDropdown setActiveFromChild>
                <DropdownToggle tag="b" className="nav-link" caret>
                  <strong
                    id={
                      navigate.pathname.includes("/pilgrim-tours")
                        ? "active"
                        : " "
                    }
                  >
                    Jyotirvid
                  </strong>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem tag="a" href="/pilgrim-tours/dashboard">
                    Astrologers
                  </DropdownItem>
                  <DropdownItem tag="a" href="/pilgrim-tours/user-accounts">
                    Users
                  </DropdownItem>
                  <DropdownItem tag="a" href="/pilgrim-tours/tours-categories">
                    Free kundali users
                  </DropdownItem>
                  <DropdownItem tag="a" href="/pilgrim-tours/user-enquiries">
                    Free Match Making Users
                  </DropdownItem>
                  <DropdownItem tag="a" href="/pilgrim-tours/packages">
                    Packages
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Navbar> */}

          <Navbar dark expand="lg" className="nav">
            <Nav className="m-auto" navbar>
              <UncontrolledDropdown setActiveFromChild>
                <DropdownToggle tag="b" className="nav-link" caret>
                  <strong
                    id={
                      navigate.pathname.includes("/sattvik-stays")
                        ? "active"
                        : " "
                    }
                  >
                    Sattvik Stays
                  </strong>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem tag="a" href="/sattvik-stays/dashboard">
                    Dashboard
                  </DropdownItem>
                  <DropdownItem tag="a" href="/sattvik-stays/user-accounts">
                    User Account
                  </DropdownItem>
                  <DropdownItem tag="a" href="/sattvik-stays/cities">
                    Cities
                  </DropdownItem>
                  <DropdownItem tag="a" href="/sattvik-stays/hotels">
                    Hotels
                  </DropdownItem>
                  <DropdownItem tag="a" href="/sattvik-stays/enquiry">
                    Enquiries
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Navbar>

          {/* <Navbar dark expand="lg" className="nav">
            <Nav className="m-auto" navbar>
              <UncontrolledDropdown setActiveFromChild>
                <DropdownToggle tag="b" className="nav-link" caret>
                  <strong id={props.other ? props.other : " "}>Other</strong>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem tag="a" href="/upload-image">
                    Upload Image
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Navbar> */}
        </div>
      </div>
      <div>
        <button className="logOutButton" onClick={deviceStorage.logOut}>
          LOG OUT
        </button>

        <div className="btm-rgyan">
          <p className="rgyan">Rgyan</p>
          <p className="para">Spiritual Social Network</p>
        </div>
      </div>
    </section>
  );
};

export default index;
