import React from "react";
import style from "../SattvikStaysAddForm.module.css";
import { useState, useEffect } from "react";
import { sattvik_get_all_city_api } from "../../../../../store/sattvik-stays";
import { Input, FormGroup } from "reactstrap";
const CityForm = (props) => {
  const { heading, handleChange, handleSubmit, formData, formik } = props;

  const [cities, setCities] = useState([]);
  const Category = [
    {
      id: "hotel",
      name: "hotel",
      label: "Hotel",
    },
    {
      id: "guest house",
      name: "guest house",
      label: "Guest House",
    },
    {
      id: "dharamshala",
      name: "dharamshala",
      label: "Dharamshala",
    },
    {
      id: "home stay",
      name: "home  stay",
      label: "Home  Stay",
    },
  ];
  const getCities = async () => {
    const response = await sattvik_get_all_city_api();

    if (response.data.status == "success") {
      const options = response.data.data.map((each) => {
        const a = {
          name: each.cityId,
          id: each.cityId,
          label: each.city,
        };
        return a;
      });
      setCities(options);
    }
  };
  useEffect(() => {
    getCities();
  }, []);
  return (
    <form className={style.formBox} onSubmit={handleSubmit}>
      <h2>{heading}</h2>
      <div className={style.inputBoxParent}>
        <div className={style.inputBox}>
          <div>
            <p>Hotel Name</p>
            <input
              value={formik.values.hotelName}
              name="hotelName"
              placeholder="Enter your hotel name"
              required
              type="text"
              onChange={handleChange}
            ></input>
          </div>
          <div>
            <div
              className={`d-flex w-100 gap-4 pt-3 align-items-center justify-content-between`}
            >
              <div>
                <p className="mb-0">Active</p>
                <FormGroup switch>
                  <Input
                    name="isActive"
                    checked={formik.values.isActive}
                    className={`${
                      formik.values.isActive ? "bg-success" : "bg-danger"
                    }`}
                    onClick={(e) => {
                      formik.setFieldValue("isActive", !formik.values.isActive);
                    }}
                    type="switch"
                  />
                </FormGroup>
              </div>

              <div>
                <p className="mb-0">Popular</p>

                <FormGroup switch>
                  <Input
                    name="popular"
                    checked={formik.values.popular}
                    className={`${
                      formik.values.popular ? "bg-success" : "bg-danger"
                    }`}
                    onClick={(e) => {
                      formik.setFieldValue("popular", !formik.values.popular);
                    }}
                    type="switch"
                  />
                </FormGroup>
              </div>
            </div>
          </div>
        </div>
        <div className={style.inputBox}>
          <div>
            <p>Address</p>
            <input
              value={formik.values.address}
              name="address"
              placeholder="Enter your hotel address"
              required
              type="text"
              onChange={handleChange}
            ></input>
          </div>
          <div>
            <p>Rating</p>
            <input
              value={formik.values.userRating}
              name="userRating"
              placeholder="Enter hotel rating"
              required
              type="text"
              onChange={handleChange}
            ></input>
          </div>
        </div>
        <div className={`${style.inputBox} pt-3`}>
          <div>
            <p>Price</p>
            <input
              value={formik.values.price}
              name="price"
              placeholder="Enter hotel price"
              required
              type="text"
              onChange={handleChange}
            ></input>
          </div>
          <div>
            <p>Disscounted Price</p>
            <input
              value={formik.values.discountPrice}
              name="discountPrice"
              placeholder="Enter hotel disscounted price"
              required
              type="text"
              onChange={handleChange}
            ></input>
          </div>
        </div>
        <div className={`${style.inputBox} pt-3 align-items-start`}>
          <div>
            <p>Image</p>
            {formik.values["city-image"] ? (
              <img
                style={{ width: "100%", height: "200px" }}
                src={URL.createObjectURL(formik.values["city-image"])}
              />
            ) : (
              <img
                style={{ width: "100%", height: "200px" }}
                src={formik.values.hotelImage}
              />
            )}
            <input
              type={"file"}
              name="image"
              value={formik.values.image}
              onChange={(e) => {
                formik.setFieldValue("city-image", e.currentTarget.files[0]);
              }}
            />
          </div>
        </div>

        <div className={style.submitBtn}>
          <button type="submit">Edit</button>
        </div>
      </div>
    </form>
  );
};

export default CityForm;
