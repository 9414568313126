import React from "react";
import style from "../../../../SattvikStays/CommonComponents/Forms/SattvikStaysAddForm.module.css";
import { useLocation } from "react-router-dom";
const CityForm = (props) => {
  const Location = useLocation();
  console.log(Location, "sub-cat");
  const { heading, handleChange, handleSubmit, formData, formik } = props;

  return (
    <form className={style.formBox} onSubmit={handleSubmit}>
      <h2>{heading}</h2>
      <div className={style.inputBoxParent}>
        <div className={style.inputBox}>
          <div>
            <p>Category Name *</p>
            <input
              value={formik.values.name}
              name="name"
              placeholder="Enter Your Tour Category Name"
              required
              type="text"
              onChange={handleChange}
            ></input>
          </div>
          <div>
            <p>Category Image *</p>
            <input
              type={"file"}
              name="url"
              value={formik.values.packageImage}
              onChange={(e) => {
                console.log(e.currentTarget.files[0], "imgg");
                formik.setFieldValue("url", e.currentTarget.files[0]);
              }}
            />
          </div>
        </div>
        {Location.state.option && (
          <div className="w-100 mt-4">
            <select
              name="parentId"
              onChange={handleChange}
              style={{
                width: "100%",
                paddingTop: "6px",
                paddingBottom: "6px",
                marginTop: "2px",
                border: "1px solid gray",
                borderRadius: "3px",
                color: "gray",
              }}
            >
              <option value={""}>Select Category</option>
              {Location.state.option.length > 0 &&
                Location.state.option.map((e) => (
                  <option value={e.value}>{e.name}</option>
                ))}
            </select>
          </div>
        )}

        <div className={style.submitBtn}>
          <button type="submit">Add</button>
        </div>
      </div>
    </form>
  );
};

export default CityForm;
