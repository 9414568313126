import { faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import React, { useState } from 'react'

const BookingCard = ({ booking }) => {
  const [openDialog, setOpenDialog] = useState(false)

  const cardStyle = {
    backgroundColor: '#f9f9f9',
    border: '1px solid #ddd',
    borderRadius: '8px',
    padding: '16px',
    width: '49.5%',
    maxWidth: '49.5%',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    fontFamily: "'Roboto', sans-serif",
    position: 'relative'
  }

  const titleStyle = {
    fontSize: '18px',
    fontWeight: '600',
    color: '#333',
    marginBottom: '12px'
  }

  const detailRowStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '14px',
    color: '#555',
    marginBottom: '8px'
  }

  const descriptionStyle = {
    fontSize: '14px',
    color: '#666',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    cursor:
      booking.description && booking.description.length > 100
        ? 'pointer'
        : 'default'
  }

  const dialogStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#fff',
    borderRadius: '8px',
    padding: '24px',
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
    zIndex: 1000,
    maxWidth: '500px',
    width: '90%'
  }

  const overlayStyle = {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 999
  }

  return (
    <>
      <div style={cardStyle}>
        <div>
          <FontAwesomeIcon
            onClick={() => setOpenDialog(true)}
            icon={faEye}
            size='sm'
            style={{
              color: 'black',
              position: 'absolute',
              right: '16px',
              top: '16px',
              cursor: 'pointer'
            }}
          />
        </div>
        <div style={titleStyle}>{booking.name ? booking.name : 'User'}</div>
        <div style={detailRowStyle}>
          <span>Check-in:</span>
          <span>{moment(booking.startDate).format('ddd, DD MMM YY')}</span>
        </div>
        <div style={detailRowStyle}>
          <span>Check-out:</span>
          <span>{moment(booking.endDate).format('ddd, DD MMM YY')}</span>
        </div>
        <div style={detailRowStyle}>
          <span>Number of days:</span>
          <span>{booking.noOfDays ? booking.noOfDays : '0'}</span>
        </div>
        <div style={detailRowStyle}>
          <span>Guests:</span>
          <span>{`${booking.noOfRoom} room, ${booking.noOfPerson} guests`}</span>
        </div>
        <div
          style={descriptionStyle}
          onClick={() =>
            booking.description.length > 100 && setOpenDialog(true)
          }
        >
          <strong>Description:</strong> {booking.description ? booking.description : '---'}
        </div>
      </div>

      {openDialog && (
        <>
          <div style={overlayStyle} onClick={() => setOpenDialog(false)}></div>
          <div style={dialogStyle}>
            <h3 style={{ marginBottom: '16px' }}>
              {booking.name ? booking.name : '---'}
            </h3>
            <p>
              <strong>Check-in:</strong>{' '}
              {moment(booking.startDate).format('ddd, DD MMM YY')}
            </p>
            <p>
              <strong>Check-out:</strong>{' '}
              {moment(booking.endDate).format('ddd, DD MMM YY')}
            </p>
            <p>
              <strong>Number of days:</strong> {booking.noOfDays}
            </p>
            <p>
              <strong>Guests:</strong>{' '}
              {`${booking.noOfRoom} room, ${booking.noOfPerson} guests`}
            </p>
            <p style={{ overflowWrap: 'break-word' }}>
              <strong>Description:</strong> {booking.description}
            </p>
            <button
              style={{
                marginTop: '16px',
                padding: '8px 16px',
                background: 'linear-gradient(180deg, #dd2a12 0%, #ffa214 100%)',
                color: '#fff',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer'
              }}
              onClick={() => setOpenDialog(false)}
            >
              Close
            </button>
          </div>
        </>
      )}
    </>
  )
}

const UserQuery = ({ data }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        padding: '0px 5px 15px',
        justifyContent: 'space-between',
        rowGap: '10px'
      }}
    >
      {data.map((booking, index) => (
        <BookingCard key={index} booking={booking} />
      ))}
    </div>
  )
}

export default UserQuery
