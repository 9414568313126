import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Navigation from "../../Navigation/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleRight,
  faAngleDown,
  faAngleUp,
  faArrowDown,
  faTurnDown,
} from "@fortawesome/free-solid-svg-icons";
import { UncontrolledCollapse, Button, CardBody, Card } from "reactstrap";
import styles from "../../../../assets/css/styles.css";
import Sidebar from "../../Side";

const url = process.env.REACT_APP_BASE_URL;

const Index = () => {
  const [video, setVideo] = useState([]);
  const [data, setdata] = useState();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(true);
  const [up, setUp] = useState(false);
  const token = localStorage.getItem("token");
  const name = localStorage.getItem("user");
  const pageTitle = "Video";
  const active = "active";
  if (token && token != "undefined") {
    const HandleRedirect = (value, name) => {
      value.categoryname = name;
      navigate("/edit-video", {
        state: value,
      });
    };

    const Live = async () => {
      await axios
        .get(url + "/admin/rgyan-tv-data", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            Accept: "application/json",
            "Content-Type": "application/json",
               "ngrok-skip-browser-warning": "any",
            client: 3,
          },
        })
        .then((res) => {
          setVideo(res.data.data);
        })
        .catch((err) => {
          console.log("Error::::::::", err);
        });
    };
    const Down = (index) => {
      const down = document.getElementById(`${index}down`);
      if (down.style.transform == "rotate(180deg)") {
        down.style.transition = "all 0.3s ease-out";
        down.style.transform = "rotate(0deg)";
      } else if (down !== null) {
        down.style.transition = "all 0.3s ease-out";
        down.style.transform = "rotate(180deg)";
      }
    };

    useEffect(() => {
      Live();
    }, []);
    return (
      <>
        <section
          className="postCount"
          style={{ overflow: "scroll", padding: "0 8px", height: "99%" }}
        >
          {/* <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}> */}
          {/* <div style={{display:"flex",width:"100%",justifyContent:"space-between"}}> */}
          {/* <span className='lableCategory'>Videos</span> */}
          {/* <button style={{width:"fit-content",border:"2px solid gray"}}></button> */}
          {/* <Link to='/add-new-live-darshan-category'>
                                <button className='addCategoryButton'>ADD New Catagory <FontAwesomeIcon icon={faAngleDoubleRight} /></button> */}
          {/* <button className='addCategoryButton'>ADD New Live Darshan <FontAwesomeIcon icon={faAngleDoubleRight} /></button> */}

          {/* </Link>  */}
          {/* </div> */}
          {/* <div> */}
          {/* </div> */}
          {/* </div> */}
          <table
            className="table table-striped table-bordered"
            style={{
              border: "2 px solid black",
            }}
          >
            <thead>
              <tr colsapan="2">
                <th colspan="2" className="special-head">
                  <th> Category Name</th>
                </th>
              </tr>
            </thead>
            <tbody>
              {video &&
                video.length > 0 &&
                video.map((data, index) => {
                  return (
                    <>
                      <tr
                        className={styles.upButton}
                        key={index + 1}
                        style={{ borderWidth: "0px" }}
                        onClick={() => {
                          Down(index);
                        }}
                      >
                        <Button
                          id={data.categoryname.split(" ")[0]}
                          style={{
                            display: "inline-block",
                            border: "none",
                            borderWidth: "0px",
                            color: "black",
                            background: "none",
                            width: "100%",
                            textalign: "center",
                            padding: "0px",
                          }}
                        >
                          <td
                            onClick={() => {
                              setUp(!up);
                            }}
                            style={{
                              padding: "10px",
                              display: "inline-block",
                              width: "100%",
                              textAlign: "left",
                            }}
                          >
                            {data.categoryname}
                            <FontAwesomeIcon
                              id={index + "down"}
                              icon={faAngleDown}
                              style={{ float: "right", fontSize: "15px" }}
                            />
                          </td>
                        </Button>
                      </tr>
                      <UncontrolledCollapse
                        toggler={`#${data.categoryname.split(" ")[0]}`}
                      >
                        <Card>
                          <CardBody>
                            <table
                              className="table table-striped table-bordered"
                              l
                              style={{
                                borderRadius: "3px",
                                padding: "5px",
                                backgroundColor: "white",
                              }}
                            >
                              <thead>
                                <tr colspan="6">
                                  <th colspan="2">S.N</th>
                                  <th colspan="2">Video Name</th>
                                  <th colspan="2">Edit</th>
                                </tr>
                              </thead>
                              <tbody>
                                <option selected> {data.title_en}</option>
                                {data.video.map((ele, i) => {
                                  return (
                                    <tr key={i + 1}>
                                      <td colspan="2">{i + 1}</td>
                                      <td colspan="2">{ele.title_en}</td>
                                      <td
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          HandleRedirect(
                                            ele,
                                            data.categoryname
                                          );
                                        }}
                                      >
                                        {" "}
                                        Edit{" "}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </CardBody>
                        </Card>
                      </UncontrolledCollapse>
                    </>
                  );
                })}
            </tbody>
          </table>
        </section>
      </>
    );
  } else {
    window.location.href = "/";
  }
};

export default Index;
